import React, {useEffect} from 'react';
import NewsAlert from '../Widgets/NewsAlert';
import {TickerDataPoint} from '../../Utils/ticker-utils';
import NewsAlertDialog from '../../Dialogs/NewsAlertDialog';
import DIALOGS_IDS from '../../../../config/dialogsIds';
import separateRender from '../../../../util/separate-render';
import {IChartProps} from '../../../../types/UseChartTypes';

interface EntireStockChartNewsAlertsProps {
  chart: IChartProps | undefined;
  isMinimap?: boolean;
}

const EntireStockChartNewsAlerts: React.FC<EntireStockChartNewsAlertsProps> = ({chart, isMinimap}) => {
  const handlePressNewsAlert = (tickerDataPoint: TickerDataPoint) => {
    if (separateRender.isMounted(DIALOGS_IDS.ENTIRE_STOCK_CHART_NEWS_ALERT)) {
      separateRender.unmount(DIALOGS_IDS.ENTIRE_STOCK_CHART_NEWS_ALERT);
    }

    separateRender.render(() => <NewsAlertDialog isRunEnded={true} news={tickerDataPoint.news!} />, {
      customId: DIALOGS_IDS.ENTIRE_STOCK_CHART_NEWS_ALERT,
      containerStyle: {
        pointerEvents: 'none',
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => separateRender.unmount(DIALOGS_IDS.ENTIRE_STOCK_CHART_NEWS_ALERT), []);

  if (!chart) return null;

  return (
    <g>
      {chart.history.map((item) => {
        const {index, price, news} = item;
        if (!news) return null;
        const y = chart.yScale(price) || 0;
        const x = chart.xScale(index) || 0;
        return <NewsAlert key={index} y={y} x={x} onClick={() => handlePressNewsAlert(item)} isMinimap={isMinimap} />;
      })}
    </g>
  );
};

export default EntireStockChartNewsAlerts;
