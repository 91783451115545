import React, {CSSProperties} from 'react';
import s from './IconButton.module.css';
import makeSound from '../../util/makeSound';
import {WebViewMobileSounds} from '../board/boardTypes';
import appScale from '../../util/appScale';

interface IconButtonProps {
  size: number;
  src: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  disabled?: boolean;
}

const PADDING = appScale(1.8);
const IconButton: React.FC<IconButtonProps> = ({size, src, onClick, style, disabled}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    makeSound(WebViewMobileSounds.TAP);
    onClick(e);
  };

  return (
    <button
      disabled={disabled}
      className={s.button}
      onClick={handleClick}
      style={{width: size, height: size, padding: PADDING, ...style}}
    >
      <img src={src} alt="" style={{objectFit: 'contain'}} />
    </button>
  );
};

export default IconButton;
