import React, {CSSProperties} from 'react';

import COLORS from '../../../config/colors';
import {RobotoRegular} from '../../../config/fonts';
import makeSound from '../../../util/makeSound';
import {WebViewMobileSounds} from '../boardTypes';
import appScale from '../../../util/appScale';
import {TelemetryEvents, sendEvent} from '../../../util/telemetry';
import ButtonsNames from '../../../config/buttonsNames';

interface NavigationHeaderOpenCloseEndRunDialogProps {
  onClick: () => void;
}

const NavigationHeaderOpenCloseEndRunDialog: React.FC<NavigationHeaderOpenCloseEndRunDialogProps> = (props) => {
  const {onClick} = props;

  const handlePress = () => {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.BOARD_SCREEN_VIEW_RUN_SCORES,
    });
    makeSound(WebViewMobileSounds.TAP);
    onClick();
  };

  return (
    <div style={containerStyles}>
      <div onClick={handlePress} style={buttonStyles} className="animation-fade-in">
        View run scores
      </div>
    </div>
  );
};

export default NavigationHeaderOpenCloseEndRunDialog;

const containerStyles: CSSProperties = {
  position: 'absolute',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: appScale(-15.25),
  right: 0,
  left: 0,
  zIndex: 10,
  transition: 'opacity 0.5s ease-in-out',
};

const buttonStyles: CSSProperties = {
  padding: `${appScale(2)}px ${appScale(4)}px`,
  backgroundColor: COLORS.CAMARONE.default,
  color: COLORS.WHITE.default,
  fontFamily: RobotoRegular,
  fontSize: appScale(6),
};
