export const PLATFORMS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  WINDOWS: 'Windows Phone',
  UNKNOWN: 'unknown',
};

export const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || navigator.platform;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return PLATFORMS.WINDOWS;
  }

  if (/android/i.test(userAgent)) {
    return PLATFORMS.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORMS.IOS;
  }

  return PLATFORMS.UNKNOWN;
};
