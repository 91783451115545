import React from 'react';
import {colors} from '../../StockChart/constants';
import BoughtStock from './BoughtStock';
import TradeStock from './TradeStock';
import UpcomingNewsAlert from './UpcomingNewsAlert';
import {Trades} from '../../../trades';
import {TickerDataPoint} from '../../../Utils/ticker-utils';
import {BOTTOM_OFFSET} from '../XAxis/XAxisStyles';
import {Y_AXIS_CONTAINER_WIDTH} from '../../../constants';
import appScale from '../../../../../util/appScale';
import {IChartProps} from '../../../../../types/UseChartTypes';

interface IGutter {
  width: number;
  latestTick: TickerDataPoint;
  latestTrade: Trades;
  chart: IChartProps;
  shouldShowUpcomingNewsAlert: boolean;
}

const GUTTER_SIZE = appScale(1.2);
const GUTTER_MIN_TRANSLATE_Y = GUTTER_SIZE * 2;

export const Gutter: React.FC<IGutter> = ({width, latestTick, latestTrade, chart, shouldShowUpcomingNewsAlert}) => {
  if (!latestTick?.date) return <div />;

  const bought = latestTrade && latestTrade.isKept;
  const boughtTick = latestTrade.trade[0];
  const gutterTranslateY = latestTick ? chart.yScale(latestTick.price) ?? 0 : 0;
  const boughtTranslateY = chart.yScale(boughtTick?.price ?? 0) ?? 0;
  const translateX = width;

  const adjustTranslateY = (translateY: number) => {
    if (translateY < GUTTER_MIN_TRANSLATE_Y) return GUTTER_MIN_TRANSLATE_Y;
    if (translateY > chart.chartHeight - BOTTOM_OFFSET) return chart.chartHeight - BOTTOM_OFFSET - GUTTER_SIZE;
    return translateY;
  };

  return (
    <g width={Y_AXIS_CONTAINER_WIDTH}>
      {!!(bought && boughtTick?.price) && (
        <g
          transform={`translate(${translateX}, ${adjustTranslateY(boughtTranslateY)})`}
          style={{transition: '150ms linear'}}
        >
          <BoughtStock trade={latestTrade} />
        </g>
      )}

      <g
        transform={`translate(${translateX}, ${adjustTranslateY(gutterTranslateY)})`}
        style={{transition: '150ms linear'}}
      >
        <TradeStock latest={latestTick} bought={bought} boughtTick={boughtTick} />
        <circle r={GUTTER_SIZE} fill={colors.CHART_LATEST_PRICE} />
        {shouldShowUpcomingNewsAlert && <UpcomingNewsAlert />}
      </g>
    </g>
  );
};
