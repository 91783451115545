import React from 'react';
import {NEWS_PAST_ALERT_ICON} from '../../../../assets/icons';
import {MINIMAP_SCALE} from '../../../../config/constants';
import appScale from '../../../../util/appScale';

const ICON_SIZE = appScale(14.5);
const ICON_SMALL_SIZE = ICON_SIZE / MINIMAP_SCALE;

export interface NewsAlertProps {
  onClick?: () => void;
  y: number;
  x: number;
  isMinimap?: boolean;
}

const NewsAlert: React.FC<NewsAlertProps> = ({onClick, y, x, isMinimap}) => {
  const size = isMinimap ? ICON_SMALL_SIZE : ICON_SIZE;

  const handleOnClick = (e: React.MouseEvent<SVGImageElement>) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const imageY = y - size;
  const imageX = x - size / 2;

  // Prevents throwing height/width is invalid because it's negative
  if (imageY < 0 || imageX < 0) return null;
  return (
    <image
      href={NEWS_PAST_ALERT_ICON}
      style={{pointerEvents: 'fill'}}
      width={size}
      height={size}
      y={y - size}
      x={x - size / 2}
      onClick={handleOnClick}
    />
  );
};

export default NewsAlert;
