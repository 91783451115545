import React from 'react';
import type {Trades} from '../../trades';
import {TimelineChart} from '../Widgets/TimelineChart';
import {TradeLines} from '../Widgets/TradeLines';
import EntireStockChartNewsAlerts from '../EntireStockChart/EntireStockChartNewsAlerts';
import COLORS from '../../../../config/colors';
import {IChartProps} from '../../../../types/UseChartTypes';

export interface MinimapChartProps {
  trades: Trades[];
  chart: IChartProps;
}
const MinimapChart: React.FC<MinimapChartProps> = ({trades, chart}) => {
  const svgWidth = chart.chartWidth;
  const svgHeight = chart.chartHeight;

  return (
    <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`} style={styles.graphContainer}>
      <TimelineChart chart={chart} fillColor={COLORS.MINIMAP_CHART.default} lineColor={COLORS.SHERWOOD_GREEN.default} />
      <TradeLines trades={trades} chart={chart} />
      <EntireStockChartNewsAlerts chart={chart} isMinimap />
      <rect
        x="0"
        y="0"
        width={svgWidth - 1}
        height={svgHeight}
        strokeWidth="1"
        fill="transparent"
        stroke="rgba(255,255,255,0.5)"
      />
    </svg>
  );
};

export default React.memo(MinimapChart);

const styles = {
  graphContainer: {
    backgroundColor: COLORS.BLACK_BEAN.default,
  },
};
