// https://www.htmlcsscolor.com/ - there you can find colors names

const COLORS = Object.freeze({
  MYRTLE: {
    default: '#0A2908',
    alt1: '#0e260e',
  },
  WHITE: {
    default: '#ffffff',
  },
  GRAY: {
    default: '#828282',
  },
  CAMARONE: {
    default: '#22783B',
  },
  LIME_GREEN: {
    default: '#33FF33',
  },
  FERN: {
    default: '#64C864',
  },
  INDIAN_RED: {
    default: '#C86464',
  },
  LASER_LEMON: {
    default: '#FFFF66',
  },
  TANGERINE: {
    default: '#f48f05',
  },
  BLACK_PEARL: {
    default: '#00202A',
  },
  BLACK: {
    default: '#000000',
  },
  ROYAL_BLUE: {
    default: '#2d76da',
  },
  FESTIVAL: {
    default: '#ECC54B',
  },
  AMETHYST: {
    default: '#914CD1',
  },
  BLACK_BEAN: {
    default: '#091509',
  },
  BLACK_MAGIC: {
    default: '#2a2211',
  },
  SHERWOOD_GREEN: {
    default: '#1d4237',
  },
  MINIMAP_CHART: {
    default: '#184726',
  },
} as const);

export default COLORS;
