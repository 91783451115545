import {TickerDataPoint} from '../components/board/Utils/ticker-utils';
import * as d3 from 'd3';

export interface IXTicks {
  date: Date;
  i: number;
}

export type TickerDataPointSMA = Omit<TickerDataPoint, 'news' | 'date'>;

export interface SMAState {
  list: TickerDataPointSMA[];
  window: number;
}

export interface IChartProps {
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  lineScale: d3.Line<TickerDataPoint>;
  stepArea: d3.Area<TickerDataPoint>;
  stepLine: d3.Line<TickerDataPoint>;
  stepSMA: d3.Line<TickerDataPointSMA>;
  xTicks: IXTicks[];
  yTicks: number[];
  chartPath: string | null | undefined;
  chartUnderPath: string | null | undefined;
  chartSMAPath: string | null | undefined;
  colorScale: number[] & d3.ScaleLinear<number, number>;
  chartWidth: number;
  chartHeight: number;
  history: TickerDataPoint[];
  speed: number;
  gameDurationMs: number;
  SMA: SMAState;
}

export interface IUseChart {
  chart: IChartProps;
  setup: (history: TickerDataPoint[], speed: number, duration: number) => void;
  viewForDistance: (distance: number, history?: TickerDataPoint[]) => void;
  resize: (width: number, height: number) => void;
  reset: () => void;
  updateSMAWindow: (newWindow: number) => void;
  chartHistory: TickerDataPoint[];
}

export interface UseChartState {
  history: TickerDataPoint[];
  speed: number;
  gameDurationMs: number;
}

export interface UpdateConfig {
  SMA: SMAState;
}

export interface SimpleTickerPoint extends TickerDataPoint {
  x: number;
  y: number;
}

export enum UseChartType {
  SIMPLE,
  ENTIRE,
  MINIMAP,
}
