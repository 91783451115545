import React, {CSSProperties} from 'react';
import appScale from '../../../../util/appScale';
import COLORS from '../../../../config/colors';
import {RobotoMedium, RobotoRegular} from '../../../../config/fonts';

interface DialogButtonProps {
  label: string;
  background?: string;
  onClick: () => void;
  customStyles?: React.CSSProperties;
}

const DialogButton: React.FC<DialogButtonProps> = ({label, onClick, background, customStyles = {}}) => {
  const buttonStyle = {
    background: background || 'transparent',
    ...customStyles,
  };
  return (
    <button disabled={false} onClick={onClick} style={{...styles.buttonStyles, ...buttonStyle}}>
      {label}
    </button>
  );
};

export default DialogButton;

const styles = {
  buttonStyles: {
    outline: 'none',
    border: '1px solid #3d8535',
    borderRadius: appScale(15),
    margin: appScale(2),
    fontSize: appScale(6),
    padding: `${appScale(2.5)}px ${appScale(20)}px`,
    color: COLORS.WHITE.default,
    fontFamily: RobotoMedium,
    fontWeight: 'bold',
  },
} as const;
