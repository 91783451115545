import React, {CSSProperties} from 'react';

import {RobotoMedium} from '../../../../../config/fonts';
import appScale from '../../../../../util/appScale';

const EndRunDialogHeader: React.FC = () => <div style={containerStyle}>Run Complete</div>;

export default EndRunDialogHeader;

const containerStyle: CSSProperties = {
  width: '100%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',

  fontFamily: RobotoMedium,
  color: 'white',
  fontSize: appScale(7.25),
  fontWeight: 'bold',
  letterSpacing: 1,
};
