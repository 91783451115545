import {ITicksWithDate} from '../../../Utils/utils';
import React from 'react';
import styles, {BOTTOM_OFFSET} from './XAxisStyles';
import {colors} from '../../StockChart/constants';
import appScale from '../../../../../util/appScale';
import {IChartProps} from '../../../../../types/UseChartTypes';

interface XAxisTickProps {
  tickWithDate: ITicksWithDate;
  height: number;
  chart: IChartProps;
  withoutPaddingLeft?: boolean;
}

const TEXT_OFFSET = appScale(2);

const XAxisTick: React.FC<XAxisTickProps> = ({tickWithDate, height, chart}) => {
  const isMonth = tickWithDate.period === 'month';
  const isYear = tickWithDate.period === 'year';
  const textStyle = {...styles.text, ...styles.dayText, ...(isMonth || isYear ? styles.monthText : undefined)};

  return (
    <g key={`x-time-axis-${tickWithDate.i}`}>
      <line
        x1={chart.xScale(tickWithDate.i)}
        x2={chart.xScale(tickWithDate.i)}
        y1={0}
        y2={height - BOTTOM_OFFSET}
        stroke={colors.GRID}
        strokeWidth={2}
      />
      <text
        x={chart.xScale(tickWithDate.i) - TEXT_OFFSET}
        y={height - BOTTOM_OFFSET / 2}
        fill={isMonth || isYear ? colors.WHITE : colors.AXIS_TEXT}
        style={textStyle}
      >
        {tickWithDate.date}
      </text>
    </g>
  );
};

export default XAxisTick;
