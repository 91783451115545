import * as d3 from 'd3';

import {NEWS_FLASHES_DEFAULT_VALUE, NEWS_FLASHES_STORAGE_KEY} from '../../../config/constants';

export type TickerDataPointNews = {
  headline: string;
  description: string;
  url?: string;
};

export type TickerDataPoint = {
  price: number;
  date: Date;
  news?: TickerDataPointNews;
  index: number;
};

export type TickerDataStats = any &
  Partial<{
    return: number;
    benchmarkReturn: number;
  }>;

export type NormalizedData = {
  stock: TickerDataStats;
  history: TickerDataPoint[];
};

export const normalizeData = (ticker: any): NormalizedData => {
  const stock: TickerDataStats = {...ticker};
  const history: any = ticker.history;

  const parseDate = d3.timeParse('%Y-%m-%d');
  const parseDateTime = d3.timeParse('%Y-%m-%d|%H:%M:%S');

  const historyNormalized: NormalizedData['history'] = history.map((item: any, index: number) => {
    const {news, date, time, price} = item;
    return {
      date: time ? parseDateTime(date + '|' + time)! : parseDate(date)!,
      price: +price,
      index,
      news,
    };
  });

  const histFirst = historyNormalized[0];
  const histLast = historyNormalized[historyNormalized.length - 1];

  if (checkPriceValid(histFirst.price, ticker.ticker)) {
    stock.return = (histLast.price - histFirst.price) / histFirst.price;
  }

  if (checkPriceValid(ticker.benchmark.priceOnStart, 'benchmark.priceOnStart')) {
    stock.benchmarkReturn =
      (ticker.benchmark.priceOnEnd - ticker.benchmark.priceOnStart) / ticker.benchmark.priceOnStart;
  }

  return {stock, history: historyNormalized};
};

const checkPriceValid = (price: number, ticker: string): boolean => {
  if (!price && price !== 0) {
    const msg = `${ticker}'s price cannot be invalid`;
    return false;
  }
  return true;
};
