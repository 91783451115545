import React from 'react';
import {NEWS_POINT_ICON} from '../../../../../assets/icons';
import appScale from '../../../../../util/appScale';

const ICON_SIZE = appScale(6);
const GENERAL_OFFSET = ICON_SIZE / -2;

const UpcomingNewsAlert = () => (
  <image
    className="animation-fade-in-out"
    y={GENERAL_OFFSET}
    x={GENERAL_OFFSET}
    width={ICON_SIZE}
    height={ICON_SIZE}
    href={NEWS_POINT_ICON}
  />
);

export default UpcomingNewsAlert;
