import React, {CSSProperties} from 'react';

import COLORS from '../../config/colors';
import DialogCloseIconButton from './DialogCloseIconButton';
import appScale from '../../util/appScale';

export interface DialogContainerProps {
  withCloseIconButton?: boolean;
  onCloseIconButtonPress?: () => void;
  style?: CSSProperties;
}

const DialogContainer: React.FC<DialogContainerProps> = (props) => {
  const {children, onCloseIconButtonPress, withCloseIconButton = true, style} = props;

  return (
    <div style={{...containerStyles, ...style}}>
      {withCloseIconButton && <DialogCloseIconButton onPress={onCloseIconButtonPress} />}
      {children}
    </div>
  );
};

export default DialogContainer;

const containerStyles: CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'column',
  borderColor: COLORS.LIME_GREEN.default,
  borderWidth: 1,
  backgroundColor: COLORS.MYRTLE.default,
  padding: `${appScale(7.5)}px ${appScale(9.5)}`,
  borderRadius: 10,
  minWidth: appScale(120),
};
