import {colors} from '../StockChart/constants';
import {SFProDisplay} from '../../../../config/fonts';
import React from 'react';
import * as d3 from 'd3';
import COLORS from '../../../../config/colors';
import {Y_AXIS_CONTAINER_WIDTH} from '../../constants';
import appScale from '../../../../util/appScale';
import {BOTTOM_OFFSET} from './XAxis/XAxisStyles';
import {IChartProps} from '../../../../types/UseChartTypes';

const AXIS_TEXT_FONT = appScale(4.4);
const AXIS_STROKE_WIDTH = appScale(0.25);

interface IYAxis {
  chart: IChartProps;
}

export const YAxis: React.FC<IYAxis> = ({chart}) => {
  const width = chart.chartWidth;
  const height = chart.chartHeight;
  const left = width;

  return (
    <g>
      <line x1={left} x2={left} y1={0} y2={height} stroke={colors.AXIS} strokeWidth={AXIS_STROKE_WIDTH} />
      <rect x={width} y={0} height={height} width={Y_AXIS_CONTAINER_WIDTH} stroke={colors.AXIS} />

      {chart.yTicks.map((d, i) => (
        <g key={`y-price-axis-${d}${i}`}>
          <text
            x={left + 5}
            y={chart.yScale(d)}
            fontSize={AXIS_TEXT_FONT}
            textAnchor={'start'}
            fontFamily={SFProDisplay}
            fill={colors.AXIS_TEXT}
            style={{transition: '50ms linear'}}
          >
            {d3.format(' $')(d)}
          </text>

          <line
            x1={0}
            x2={left}
            y1={chart.yScale(d)}
            y2={chart.yScale(d)}
            stroke={colors.GRID}
            strokeWidth={AXIS_STROKE_WIDTH}
            style={{transition: '50ms linear'}}
          />
        </g>
      ))}
      <rect
        x={width - AXIS_STROKE_WIDTH}
        y={height - BOTTOM_OFFSET}
        height={height - BOTTOM_OFFSET}
        width={Y_AXIS_CONTAINER_WIDTH}
        fill={COLORS.BLACK.default}
      />
    </g>
  );
};
