import * as d3 from 'd3';
import {getHistoryIndexOffsetBySessionTimeMs, VISIBLE_HISTORY_WINDOW} from '../components/board/chartConfig';
import {useCallback, useRef, useState} from 'react';
import {TickerDataPoint} from '../components/board/Utils/ticker-utils';

export interface IView {
  xRange: [number, number];
  yRange: [number, number];
}

const INITIAL_SPEED = 2;

export interface ViewByDistanceOptions {
  isWholeDistance?: boolean;
  isMinimap?: boolean;
}

export interface IUseViewport {
  chartHistory: TickerDataPoint[];
  view: IView;
  setRange: (gameDurationMs: number) => void;
  setDomain: (xStartEnd: [number, number]) => void;
  calculateViewByDistance: (distance: number, history: TickerDataPoint[], options?: ViewByDistanceOptions) => void;
  setChartHistory: (history: TickerDataPoint[]) => void;
}

const useViewport = (): IUseViewport => {
  const [chartHistory, setChartHistory] = useState<TickerDataPoint[]>([]);
  const [view, setView] = useState<IView>({xRange: [0, 0], yRange: [0, 0]});
  const tScale = useRef<d3.ScaleLinear<number, number>>(d3.scaleLinear<number, number>()).current;

  const getXRange = (
    distance: number,
    history: TickerDataPoint[],
    options?: ViewByDistanceOptions,
  ): IView['xRange'] => {
    const isWholeDistance = !!options?.isWholeDistance;
    const isMinimap = !!options?.isMinimap;
    const visibleHistoryNumber = isWholeDistance ? history.length : VISIBLE_HISTORY_WINDOW.NUMBER_ITEMS;
    const lastItem = getHistoryIndexOffsetBySessionTimeMs(distance, INITIAL_SPEED);
    const firstItem = isMinimap ? 0 : lastItem - visibleHistoryNumber;

    return [firstItem, lastItem];
  };

  const getYRange = (visibleHistory: TickerDataPoint[]): IView['yRange'] => {
    const verticalScale = 0.1;
    const [bottomItem, topItem] = d3.extent(visibleHistory, (d) => d?.price ?? 0);
    const diff = (topItem! - bottomItem!) * verticalScale;

    return [bottomItem! - diff || 0, topItem! + diff || 0];
  };

  const calculateViewByDistance = (distance: number, history: TickerDataPoint[], options?: ViewByDistanceOptions) => {
    if (!history.length) return;

    const xRange = getXRange(distance, history, options);
    const visibleHistory = options?.isWholeDistance
      ? history
      : history.slice(Math.max(xRange[0], 0), Math.min(xRange[1], history.length - 1));
    const yRange = getYRange(visibleHistory);

    setChartHistory(visibleHistory);
    setView({xRange, yRange});
  };

  const setRange = useCallback(
    (gameDurationMs: number) => {
      tScale.range([0, gameDurationMs]);
    },
    [tScale],
  );

  const setDomain = useCallback(
    (xStartEnd: [number, number]) => {
      tScale.domain(xStartEnd);
    },
    [tScale],
  );

  return {
    chartHistory: chartHistory,
    view,
    setRange,
    setDomain,
    calculateViewByDistance,
    setChartHistory,
  };
};

export default useViewport;
