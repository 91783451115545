import STORAGE_KEYS from '../config/storageKeys';
import {useLocalStorage} from 'beautiful-react-hooks';

interface PressedSMAButtons {
  0: boolean;
  10: boolean;
  50: boolean;
  200: boolean;
}

const useSMAPressedButtons = () => {
  const [pressedSMAButtons, setPressedSMAButtons] = useLocalStorage<PressedSMAButtons>(
    STORAGE_KEYS.PRESSED_SMA_BUTTONS,
    {
      10: false,
      50: false,
      200: false,
    },
  );

  // TODO: fix/remove as keyof PressedSMAButtons
  const isPressed = (buttonSMAValue: number) => pressedSMAButtons[buttonSMAValue as keyof PressedSMAButtons];
  const logPressed = (buttonSMAValue: number) =>
    setPressedSMAButtons((prevState) => ({...prevState, [buttonSMAValue]: true}));

  return {
    isPressed,
    logPressed,
  };
};

export default useSMAPressedButtons;
