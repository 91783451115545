import React from 'react';

import COLORS from '../../../../../config/colors';
import {TickerDataPoint} from '../../../Utils/ticker-utils';
import Price from './Price';

interface TradeStockProps {
  bought: boolean;
  boughtTick: TickerDataPoint;
  latest: TickerDataPoint;
}

const TradeStock: React.FC<TradeStockProps> = ({bought, boughtTick, latest}) => {
  let backgroundColor: string = COLORS.GRAY.default;

  if (bought) {
    if (boughtTick.price < latest.price) backgroundColor = COLORS.CAMARONE.default;
    else backgroundColor = COLORS.INDIAN_RED.default;
  }

  return <Price backgroundColor={backgroundColor} color={COLORS.WHITE.default} price={latest.price} />;
};

export default TradeStock;
