import React, {CSSProperties, useEffect} from 'react';
import makeSound from '../../../../../util/makeSound';
import {WebViewMobileSounds} from '../../../boardTypes';
import appScale from '../../../../../util/appScale';
import {BoardStateRef} from '../../../../../hooks/useBoardStateRef';

interface EndRunDialogMedalProps {
  medalSrc: string | null;
  boardStateRef: BoardStateRef;
}

const ICON_SIZE = appScale(11.65);

const EndRunDialogMedal: React.FC<EndRunDialogMedalProps> = ({medalSrc, boardStateRef}) => {
  useEffect(() => {
    if (!boardStateRef.shouldRunAnimationInEndRunDialog) return;

    if (medalSrc) {
      makeSound(WebViewMobileSounds.MEDAL_RECEIVED);
      boardStateRef.shouldRunAnimationInEndRunDialog = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!medalSrc) return null;

  return (
    <div style={containerStyles}>
      <img style={medalIconStyles} src={medalSrc} alt="medal" />
    </div>
  );
};

export default EndRunDialogMedal;

const containerStyles: CSSProperties = {
  height: appScale(13.2),
  marginTop: appScale(1.25),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 3,
};

const medalIconStyles: CSSProperties = {
  width: ICON_SIZE,
  height: ICON_SIZE,
  objectFit: 'contain',
};
