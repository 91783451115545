import {colors} from '../../StockChart/constants';
import {RobotoRegular, SfUIMedium} from '../../../../../config/fonts';
import COLORS from '../../../../../config/colors';
import React from 'react';
import appScale from '../../../../../util/appScale';

export const AXIS_TEXT_FONT = appScale(4);
export const HORIZONTAL_TEXT_OFFSET = appScale(6.6);
export const BOTTOM_OFFSET = appScale(10);

const styles = {
  wrapper: {
    position: 'absolute',
    justifyContent: 'flex-end',
    zIndex: 11,
  } as React.CSSProperties,
  wrapperPaddingLeft: {
    paddingLeft: HORIZONTAL_TEXT_OFFSET,
  } as React.CSSProperties,
  text: {
    color: colors.AXIS_TEXT,
    fontSize: AXIS_TEXT_FONT,
    fontFamily: SfUIMedium,
    marginBottom: appScale(4.4),
    left: '-50%',
  } as React.CSSProperties,
  verticalLine: {
    top: 0,
    left: 0,
    width: 1,
    backgroundColor: colors.GRID,
  } as React.CSSProperties,
  yearContainer: {
    position: 'absolute',
    backgroundColor: colors.YEAR_BG,
    left: 0,
    paddingTop: appScale(0.85),
    paddingRight: appScale(0.75),
    width: appScale(14.5),
    height: BOTTOM_OFFSET,
    zIndex: 12,
  } as React.CSSProperties,
  yearText: {
    fontSize: AXIS_TEXT_FONT,
    fontWeight: 'bold',
    fontFamily: RobotoRegular,
    color: colors.WHITE,
    textAlign: 'right',
    textAlignVertical: 'center',
  } as React.CSSProperties,
  dayText: {},
  monthText: {
    fontFamily: SfUIMedium,
    fontWeight: 'bold',
    color: colors.WHITE,
  } as React.CSSProperties,
  background: {
    borderTopWidth: 1,
    borderTopColor: colors.AXIS,
    backgroundColor: COLORS.BLACK.default,
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    height: BOTTOM_OFFSET * 2,
  } as React.CSSProperties,
};

export default styles;
