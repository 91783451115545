import {getTicksWithDates, getYear, ITicksWithDate} from '../../../Utils/utils';
import React, {useEffect, useState} from 'react';
import XAxisTick from './XAxisTick';
import styles, {BOTTOM_OFFSET} from './XAxisStyles';
import COLORS from '../../../../../config/colors';
import {colors} from '../../StockChart/constants';
import appScale from '../../../../../util/appScale';
import {IChartProps} from '../../../../../types/UseChartTypes';

interface IXAxis {
  chart: IChartProps;
  hideCurrentYear?: boolean;
}

const AXIS_STROKE_WIDTH = appScale(0.25);
const YEAR_CONTAINER_WIDTH = appScale(16);
const WIDTH_TO_PRICE_LINE = appScale(0.5);
const TEXT_X = appScale(2.5);
const TEXT_Y = BOTTOM_OFFSET / 2;
const TEXT_FONT_SIZE = appScale(4.6);

const XAxis: React.FC<IXAxis> = ({chart, hideCurrentYear = false}) => {
  const {chartWidth: width, chartHeight: height} = chart;
  const xTicks: ITicksWithDate[] = getTicksWithDates(chart.xTicks);
  const [year, setYear] = useState(0);
  const [tempYear, setTempYear] = useState(0);

  useEffect(() => {
    if (xTicks.length) {
      const {period, date} = xTicks[0];

      // save year to show year only when we don't see it on the screen
      if (period === 'year') {
        const newYear = parseInt(date.replace(/[^0-9]/g, ''), 10);
        if (newYear > year) setTempYear(newYear);
      }

      // set actual year and remove temporary saved year value
      if (tempYear && period !== 'year') {
        setYear(tempYear);
        setTempYear(0);
      }
    }
    // set actual year first time if it's empty
    if (!year && chart.xTicks.length) {
      const {date} = chart.xTicks[chart.xTicks.length - 1];

      setYear(+getYear(date));
    }
  }, [xTicks, tempYear, year, chart.xTicks]);

  return (
    <>
      <rect
        y={height - BOTTOM_OFFSET}
        height={BOTTOM_OFFSET}
        width={width - WIDTH_TO_PRICE_LINE}
        style={{...styles.background}}
      />
      {xTicks.map((tickWithDate) => (
        <XAxisTick
          key={`x-time-axis-${tickWithDate.i}`}
          tickWithDate={tickWithDate}
          height={height}
          chart={chart}
          withoutPaddingLeft={hideCurrentYear}
        />
      ))}
      {hideCurrentYear || (
        <g transform={`translate(${0}, ${height - BOTTOM_OFFSET})`}>
          <rect width={YEAR_CONTAINER_WIDTH} x="0" y="0" height={BOTTOM_OFFSET} fill={colors.YEAR_BG} />
          <text
            width={YEAR_CONTAINER_WIDTH}
            x={TEXT_X}
            y={TEXT_Y}
            fontSize={TEXT_FONT_SIZE}
            fill={COLORS.WHITE.default}
          >
            {year ? year : ''}
          </text>
        </g>
      )}
      <line
        x1={0}
        x2={chart.chartWidth}
        y1={height - BOTTOM_OFFSET}
        y2={height - BOTTOM_OFFSET}
        stroke={colors.AXIS}
        strokeWidth={AXIS_STROKE_WIDTH}
      />
    </>
  );
};

export default XAxis;
