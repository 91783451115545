import React, {useRef, useState} from 'react';

import type {Trades} from '../../trades';
import {TimelineChart} from '../Widgets/TimelineChart';
import XAxis from '../Widgets/XAxis';
import {TradeLines} from '../Widgets/TradeLines';
import EntireStockChartNewsAlerts from './EntireStockChartNewsAlerts';
import {TickerDataPoint} from '../../Utils/ticker-utils';
import {calcTimeToScrollMs} from '../../chartConfig';
import {Canvas} from '../../Canvas';

import SMASwitcher from '../../components/SMASwitcher';
import BoardSwitchersContainer from '../../components/BoardSwitchersContainer';
import useEntireStockChartTutorial from './useEntireStockChartTutorial';
import {useDrag, usePinch} from '@use-gesture/react';
import {useTutorial} from '../../../../contexts/TutorialContext';
import {IUseChart} from '../../../../types/UseChartTypes';
import {YAxis} from '../Widgets/YAxis';

export interface EntireStockChartProps {
  trades: Trades[];
  canvas: Canvas;
  chart: IUseChart;
  ticks: TickerDataPoint[];
  updateSMAWindow: (newWindow: number) => void;
}

// TODO: Figure out how to name it
// The last distance from useAnimation is divided by the last tick index;
// Needed for viewForWholeDistance to set chart correctly in SVG
const MAGIC_NUMBER = 895;
const MIN_RENDERED_ITEMS_ON_SVG = 50;
const HALF_OF_MIN_RENDERED_ITEMS_ON_SVG = MIN_RENDERED_ITEMS_ON_SVG / 2;

const SCROLL_RATION = 0.01; // the bigger the faster and vise versa
const ZOOM_RATION = 0.005; // the bigger the faster and vise versa
const OFFSET_MIDDLE = 50;
const MIN_ZOOM_OUT_COUNTER = 2;
const ZOOM_IN_OFFSET_SCALE = 10;

interface StateRef {
  latestTicksIndex: number;
  renderedItemsOnChart: number;
  chartDistance: number;
}

const EntireStockChart: React.FC<EntireStockChartProps> = ({trades, ticks, canvas, chart: _chart, updateSMAWindow}) => {
  const tutorial = useTutorial();
  const {setup, viewForDistance, chart} = _chart;
  const [chartDistance, setChartDistance] = useState(0);
  const svgWidth = canvas.getWidth();
  const svgHeight = canvas.getHeight();

  const stateRef = useRef<StateRef>({
    latestTicksIndex: ticks[ticks.length - 1].index,
    renderedItemsOnChart: chart.history.length - 1,
    chartDistance: 0,
  }).current;

  const bindDrag = useDrag(
    (event) => {
      if (tutorial.isInProgress) return;

      if (!stateRef.renderedItemsOnChart) return;

      if (stateRef.renderedItemsOnChart >= ticks.length) return;

      // * -1 switching scrolling to left
      const velocityX = event.movement[0] * -1;
      if (velocityX === 0) return;

      const scrollSpeed = Math.abs(
        Math.ceil(((stateRef.renderedItemsOnChart * velocityX) / (velocityX * 0.9)) * SCROLL_RATION),
      );

      if (velocityX > 0) {
        // perform scrolling right
        const previousEndIndex = chart.history[chart.history.length - 1].index;

        // prevent scrolling right if it's the last
        if (previousEndIndex === ticks.length - 1) return;
        const newEndIndex = Math.min(previousEndIndex + scrollSpeed, ticks.length - 1);
        const newTicks = ticks.slice(newEndIndex - stateRef.renderedItemsOnChart, newEndIndex);

        handleUpdateSVG(newTicks);
      }

      if (velocityX < 0) {
        // perform scrolling left
        const previousStartIndex = chart.history[0].index;

        // prevent scrolling left if it's the last
        if (previousStartIndex === 0) return;

        const newStartIndex = Math.max(previousStartIndex - scrollSpeed, 0);
        const newTicks = ticks.slice(newStartIndex, newStartIndex + stateRef.renderedItemsOnChart);

        handleUpdateSVG(newTicks);
      }
    },
    {pointer: {touch: true}},
  );

  const bindPitch = usePinch(
    (event) => {
      if (tutorial.isInProgress) return;
      const velocity = event.distance[0];
      if (velocity === 0) return;
      const scale = event.movement[0];
      let zoom = Math.ceil(velocity * ZOOM_RATION * stateRef.renderedItemsOnChart);

      if (scale > 0) {
        // zooming in
        if (chart.history.length === MIN_RENDERED_ITEMS_ON_SVG) return;

        const previousStartIndex = chart.history[0].index;
        const previousEndIndex = chart.history[chart.history.length - 1].index;
        const svgWidth = window.innerWidth;
        let offset = Math.ceil((100 / svgWidth) * event.origin[0]);
        offset = offset < OFFSET_MIDDLE ? -(OFFSET_MIDDLE - offset) : offset - OFFSET_MIDDLE;
        const scaledOffset = offset / ZOOM_IN_OFFSET_SCALE;
        zoom = (zoom / 100) * (previousEndIndex - previousStartIndex);

        let newStartIndex = previousStartIndex + zoom + scaledOffset;
        let newEndIndex = previousEndIndex - zoom + scaledOffset;

        if (newEndIndex - newStartIndex < MIN_RENDERED_ITEMS_ON_SVG) {
          const middleIndex = newStartIndex + (newEndIndex - newStartIndex) / 2;

          newStartIndex = Math.floor(middleIndex - HALF_OF_MIN_RENDERED_ITEMS_ON_SVG);
          newEndIndex = Math.floor(middleIndex + HALF_OF_MIN_RENDERED_ITEMS_ON_SVG);
        }

        // prevent out zooming to left/right
        newStartIndex = Math.max(newStartIndex, 0);
        newEndIndex = Math.min(newEndIndex, ticks.length - 1);

        const newTicks = ticks.slice(newStartIndex, newEndIndex);

        handleUpdateSVG(newTicks);
      } else if (scale < 0) {
        // zooming out
        const previousStartIndex = chart.history[0].index;
        const previousEndIndex = chart.history[chart.history.length - 1].index;

        zoom = (zoom / 100) * (previousEndIndex - previousStartIndex);
        zoom = zoom > MIN_ZOOM_OUT_COUNTER ? zoom : MIN_ZOOM_OUT_COUNTER;

        const newStartIndex = Math.max(previousStartIndex - zoom, 0);
        const newEndIndex = Math.min(previousEndIndex + zoom, ticks.length - 1);
        const newTicks = ticks.slice(newStartIndex, newEndIndex);

        handleUpdateSVG(newTicks);
      }
      return;
    },
    {
      pointer: {touch: true},
    },
  );

  const handleUpdateSVG = (newTicks: TickerDataPoint[]) => {
    const lastNewTicksIndex = newTicks[newTicks.length - 1].index;

    const duration = calcTimeToScrollMs(newTicks.length, 1);
    setup(newTicks, 1, duration);
    stateRef.renderedItemsOnChart = newTicks.length;
    const newChartDistance = lastNewTicksIndex * MAGIC_NUMBER;

    setChartDistance(newChartDistance);
    viewForDistance(chartDistance, ticks);
  };

  useEntireStockChartTutorial();

  return (
    <div {...bindDrag()} style={styles.container}>
      <div {...bindPitch()} style={styles.container}>
        <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`} style={styles.graphContainer}>
          <TimelineChart chart={chart} />
          <TradeLines trades={trades} chart={chart} />
          <XAxis hideCurrentYear chart={chart} />
          <YAxis chart={chart} />
          <EntireStockChartNewsAlerts chart={chart} />
        </svg>
        <BoardSwitchersContainer>
          <SMASwitcher canShowSMATutorialDialogs selected={chart.SMA.window} onSelect={updateSMAWindow} />
        </BoardSwitchersContainer>
      </div>
    </div>
  );
};

export default EntireStockChart;

const styles = {
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  graphContainer: {
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.65)',
  },
};
