import React, {useEffect, useState} from 'react';
import NewsAlert from '../Widgets/NewsAlert';
import {TickerDataPoint, TickerDataPointNews} from '../../Utils/ticker-utils';
import NewsAlertDialog from '../../Dialogs/NewsAlertDialog';
import {BoardStateRef} from '../../../../hooks/useBoardStateRef';
import separateRender from '../../../../util/separate-render';
import {useTutorial} from '../../../../contexts/TutorialContext';
import {IChartProps} from '../../../../types/UseChartTypes';

interface StockChartNewsAlertsProps {
  chart: IChartProps | undefined;
  latestIndex: number;

  handleBuyOrSellTrade: (tradeItem?: TickerDataPoint) => void;
  play: () => void;
  pause: () => void;

  boardStateRef: BoardStateRef;
}

const StockChartNewsAlerts: React.FC<StockChartNewsAlertsProps> = ({
  boardStateRef,
  pause,
  chart,
  latestIndex,
  play,
  handleBuyOrSellTrade,
}) => {
  if (!chart) return null;

  return (
    <g>
      {chart.history.map((item) => {
        const {index, price, news} = item;
        if (!news) return null;
        if (latestIndex <= index && latestIndex !== chart?.history.length - 1) return null;
        const y = chart.yScale(price) || 0;
        const x = chart.xScale(index) || 0;
        return (
          <StockChartNewsAlert
            boardStateRef={boardStateRef}
            pause={pause}
            key={index}
            y={y}
            x={x}
            news={news}
            index={index}
            play={play}
            handleBuyOrSellTrade={() => handleBuyOrSellTrade(item)}
          />
        );
      })}
    </g>
  );
};

export default StockChartNewsAlerts;

interface StockChartNewsAlertProps {
  y: number;
  x: number;
  play: () => void;
  handleBuyOrSellTrade: () => void;
  news: TickerDataPointNews;
  pause: () => void;

  boardStateRef: BoardStateRef;
  index: number;
}
const StockChartNewsAlert: React.FC<StockChartNewsAlertProps> = ({
  boardStateRef,
  news,
  y,
  x,
  play,
  handleBuyOrSellTrade,
  pause,
  index,
}) => {
  const [internalNewsAlertDialogId, setInternalNewsAlertDialog] = useState<null | string>(null);

  const tutorial = useTutorial();
  useEffect(() => {
    if (internalNewsAlertDialogId === null && boardStateRef.newsAlertDialogIdRef === null) {
      pause();
      const newsId = 'news-alert-dialog-' + index;
      const unmount = separateRender.render(
        () => (
          <NewsAlertDialog
            tutorial={tutorial}
            isRunEnded={false}
            news={news}
            onBuySell={() => {
              unmount();
              boardStateRef.newsAlertDialogIdRef = null;
              handleBuyOrSellTrade();
              play();
            }}
            onContinuePlay={() => {
              unmount();
              boardStateRef.newsAlertDialogIdRef = null;
              play();
            }}
          />
        ),
        {customId: newsId, containerStyle: {pointerEvents: 'none'}},
      );
      setInternalNewsAlertDialog(newsId);
      boardStateRef.newsAlertDialogIdRef = newsId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    boardStateRef.newsAlertDialogIdRef,
    internalNewsAlertDialogId,
    setInternalNewsAlertDialog,
    play,
    pause,
    handleBuyOrSellTrade,
    news,
  ]);

  useEffect(
    () => () => {
      if (typeof internalNewsAlertDialogId === 'string') separateRender.unmount(internalNewsAlertDialogId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return typeof internalNewsAlertDialogId === 'string' &&
    internalNewsAlertDialogId !== boardStateRef.newsAlertDialogIdRef ? (
    <NewsAlert y={y} x={x} />
  ) : null;
};
