import React, {CSSProperties} from 'react';
import {TickerDataPoint} from '../../Utils/ticker-utils';
import {BOARD_PROGRESS_HEIGHT} from '../../constants';
import {TutorialSteps, useTutorial} from '../../../../contexts/TutorialContext';
import appScale from '../../../../util/appScale';
import TutorialText from '../../../Tutorial/TutorialText';
import TutorialContent from '../../../Tutorial/TutorialContent';
import TutorialTriangle from '../../../Tutorial/TutorialTriangle';

export interface IChartProgressBar {
  width: number;
  latestIndex: number;
  history: TickerDataPoint[];
}

const ChartProgressBar: React.FC<IChartProgressBar> = ({width, latestIndex, history}) => {
  const lineWidth = (width / history.length) * latestIndex;

  const tutorial = useTutorial();
  return (
    <>
      {tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_PROGRESS) && (
        <TutorialContent style={tutorialContentStyles}>
          <TutorialText>Run progress bar to see how much time's left</TutorialText>
          <TutorialTriangle style={tutorialTriangleStyles} />
        </TutorialContent>
      )}
      <div style={styles.graphContainer}>
        <div style={{...styles.graphLine, ...{width: lineWidth}}} />
      </div>
    </>
  );
};

const styles = {
  graphContainer: {
    backgroundColor: '#102300',
    overflow: 'hidden',
    boxShadow: 'inset 0px 0px 0px 1px #004e23',
  },
  graphLine: {
    backgroundColor: '#00ff00',
    height: BOARD_PROGRESS_HEIGHT,
    width: 2,
  },
};

export default ChartProgressBar;

const tutorialContentStyles: CSSProperties = {
  position: 'absolute',
  top: appScale(16),
  left: appScale(40),
  width: appScale(66.5),
  zIndex: 1,
};

const tutorialTriangleStyles: CSSProperties = {
  position: 'absolute',
  top: appScale(-7),
  right: appScale(13.5),
};
