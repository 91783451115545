export const COLOR_CRIMSON = '#DC143C';
export const COLOR_PEACH_PUFF = '#FFDAB9';

export const colors = {
  AXIS: 'rgba(255, 255, 255, 0.4)',
  AXIS_TEXT: 'rgba(255, 255, 255, 0.6)',
  AXIS_TEXT_BOLD: 'rgba(255, 255, 255, 0.8)',
  CHART: 'rgba(0, 191, 165, 0.65)',
  CHART_LATEST_PRICE: 'rgb(0, 230, 80)',
  CHART_PROGRESS_LINE: 'rgba(21, 34, 4, 1)',
  CHART_PROGRESS_LINE_FILL: 'rgba(109, 175, 51, 1)',
  CHART_FILL: 'rgba(100, 54, 30, 0.23)',
  GRID: 'rgba(255, 218, 185, 0.1)',
  FPS: 'rgba(161, 59, 192, 0.75)',
  LATEST_TICK: 'rgba(255, 218, 185, 0.8)',
  YEAR_BG: '#666666',
  WHITE: '#FFF',
};
