import React, {CSSProperties} from 'react';
import s from './Button.module.css';
import appScale from '../../util/appScale';

interface ButtonProps {
  label: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({label, onClick}) => {
  return (
    <button onClick={onClick} className={s.button} style={buttonStyles}>
      {label}
    </button>
  );
};

export default Button;

const buttonStyles: CSSProperties = {
  borderRadius: appScale(1.5),
  fontSize: appScale(5),
  padding: `${appScale(3)}px ${appScale(5)}px`,
};
