import React, {useEffect} from 'react';

import {
  TutorialSteps,
  useFinishTutorial,
  useTutorial,
  useTutorialNextStepDisabled,
} from '../../../../contexts/TutorialContext';
import TutorialTapToContinueDialog from '../../../../components/Tutorial/TutorialTapToContinueDialog';
import DIALOGS_IDS from '../../../../config/dialogsIds';
import separateRender from '../../../../util/separate-render';

interface DialogProps {
  onTapToContinue: () => void;
}

const useEntireStockChartTutorial = () => {
  const tutorial = useTutorial();
  const finishTutorial = useFinishTutorial();

  useEffect(() => {
    if (tutorial.isInProgress && tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_ENTIRE_STOCK_CHART_OVERVIEW)) {
      const showTutorialAboutEntireStockChart = () => {
        separateRender.unmount(DIALOGS_IDS.TUTORIAL);
        separateRender.render(() => <ThisEndRunChart onTapToContinue={showTutorialAboutScrolling} />, {
          customId: DIALOGS_IDS.TUTORIAL,
        });
      };

      const showTutorialAboutScrolling = () => {
        separateRender.unmount(DIALOGS_IDS.TUTORIAL);
        separateRender.render(() => <Scrolling onTapToContinue={showTutorialAboutZooming} />, {
          customId: DIALOGS_IDS.TUTORIAL,
        });
      };

      const showTutorialAboutZooming = () => {
        separateRender.unmount(DIALOGS_IDS.TUTORIAL);
        separateRender.render(() => <Zooming onTapToContinue={showTutorialFinish} />, {customId: DIALOGS_IDS.TUTORIAL});
      };

      const showTutorialFinish = () => {
        separateRender.unmount(DIALOGS_IDS.TUTORIAL);
        separateRender.render(
          () => {
            tutorial.setStep(TutorialSteps.BOARD_SCREEN_ENTIRE_STOCK_CHART_BUTTONS_OVERVIEW);
            return <GoodLuck onTapToContinue={finishTutorial} />;
          },
          {customId: DIALOGS_IDS.TUTORIAL},
        );
      };

      showTutorialAboutEntireStockChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorial.step]);
};

export default useEntireStockChartTutorial;

const ThisEndRunChart: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();
  return (
    <TutorialTapToContinueDialog
      disabled={tutorialNextStepDisabled.disabled}
      onTapToContinue={onTapToContinue}
      text={
        <div>
          This is the end run chart.{'\n'}
          Here you can analyze your decisions and the history of the played ticker.{'\n'}
          You can also scroll and zoom the chart.
        </div>
      }
    />
  );
};

const Scrolling: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();
  return (
    <TutorialTapToContinueDialog
      disabled={tutorialNextStepDisabled.disabled}
      onTapToContinue={onTapToContinue}
      text={
        <div>
          Scrolling{'\n'}
          Swipe left to scroll left{'\n'}
          Swipe right to scroll right
        </div>
      }
    />
  );
};

const Zooming: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();
  return (
    <TutorialTapToContinueDialog
      disabled={tutorialNextStepDisabled.disabled}
      onTapToContinue={onTapToContinue}
      text={
        <div>
          Zooming{'\n'}
          Pinch your fingers outward and move them to each other to zoom in.{'\n'}
          Pinch your fingers together and move them outward to zoom out.
        </div>
      }
    />
  );
};

const GoodLuck: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();
  return (
    <TutorialTapToContinueDialog
      disabled={tutorialNextStepDisabled.disabled}
      onTapToContinue={onTapToContinue}
      text={
        <div>
          We hope we help you to understand how everything works!{'\n'}
          Good luck!
        </div>
      }
    />
  );
};
