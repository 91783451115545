import React, {useEffect} from 'react';
import {format} from '../Utils/utils';
import {RobotoRegular} from '../../../config/fonts';
import {DURING_OF_TRADE_RESULT_DIALOG_IN_MS} from '../../../config/constants';
import {BOARD_NAV_HEIGHT} from '../constants';
import COLORS from '../../../config/colors';
import appScale from '../../../util/appScale';
import Color from 'color';

const formatCurrency = (amount: number) => {
  return format(amount, '+$,.0f'); // -$100 or +$100
};

export interface TradeResultDialogProps {
  unmount: () => void;
  profit: number;
}

const TradeResultDialog: React.FC<TradeResultDialogProps> = ({unmount, profit}) => {
  const isProfitPositive = profit > 0;

  useEffect(() => {
    const timeout = setTimeout(() => {
      unmount();
    }, DURING_OF_TRADE_RESULT_DIALOG_IN_MS);
    return () => clearTimeout(timeout);
  }, [unmount]);

  const containerStyle = {
    ...styles.container,
    ...(isProfitPositive ? styles.containerPositive : styles.containerNegative),
  };

  return (
    <div style={styles.wrapper}>
      <div style={containerStyle} className={isProfitPositive ? 'trade-result-win' : 'trade-result-loss'}>
        <span style={styles.text}>{formatCurrency(profit)}</span>
      </div>
    </div>
  );
};

export default TradeResultDialog;

const styles = {
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  } as React.CSSProperties,
  container: {
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: BOARD_NAV_HEIGHT + appScale(6.2),
    borderRadius: 5,
    padding: `${appScale(2)}px ${appScale(3.2)}px`,
    minWidth: appScale(34),
  } as React.CSSProperties,
  containerPositive: {
    backgroundColor: Color(COLORS.FERN.default).toString(),
  } as React.CSSProperties,
  containerNegative: {
    backgroundColor: Color(COLORS.INDIAN_RED.default).toString(),
  } as React.CSSProperties,
  text: {
    color: COLORS.WHITE.default,
    fontFamily: RobotoRegular,
    fontSize: appScale(6.2),
    fontWeight: 'bold',
    textAlign: 'center',
  } as React.CSSProperties,
};
