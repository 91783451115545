import React, {CSSProperties} from 'react';
import ReactDOM from 'react-dom';

interface RendererOptions {
  customId?: string;
  containerStyle?: Partial<CSSProperties>;
}

const createDIVContainer = (): HTMLDivElement => {
  const newContainer = document.createElement('DIV') as HTMLDivElement;
  newContainer.style.position = 'fixed';
  newContainer.style.height = '100%';
  newContainer.style.width = '100%';
  newContainer.style.top = '0';
  newContainer.style.left = '0';
  newContainer.style.zIndex = '2';
  return newContainer;
};

const createComponentContainer = (() => {
  let secondaryRootContainer: HTMLElement | null = null;

  return () => {
    if (secondaryRootContainer === null) {
      secondaryRootContainer = createDIVContainer();
      secondaryRootContainer.style.pointerEvents = 'none';
      document.body.appendChild(secondaryRootContainer);
    }

    const componentContainer = createDIVContainer();
    secondaryRootContainer.appendChild(componentContainer);

    return componentContainer;
  };
})();

export const render = (
  Component: ({unmount}: {unmount: () => void}) => React.ReactElement,
  options?: RendererOptions,
) => {
  const {customId, containerStyle} = (options || {}) as RendererOptions;

  const componentContainer = createComponentContainer();
  componentContainer.style.pointerEvents = 'auto';

  if (customId) componentContainer.id = customId;
  if (containerStyle) applyStyleToHTMLElement(componentContainer, containerStyle);

  const destroyRenderer = () => {
    ReactDOM.unmountComponentAtNode(componentContainer);
    componentContainer.remove();
  };

  ReactDOM.render(<Component unmount={destroyRenderer} />, componentContainer);

  return destroyRenderer;
};

export const unmount = (id: string) => {
  const componentContainer = document.getElementById(id);
  if (!componentContainer) return;

  ReactDOM.unmountComponentAtNode(componentContainer);
  componentContainer.remove();
};

export const isMounted = (id: string) => {
  const componentContainer = document.getElementById(id);
  return !!componentContainer;
};

const separateRender = {
  render,
  unmount,
  isMounted,
};

export default separateRender;

const applyStyleToHTMLElement = (element: HTMLElement, style: CSSProperties) => {
  Object.entries(style).forEach((entry) => {
    // TODO: Fix any
    element.style[entry[0] as any] = entry[1];
  });
};
