const IS_iOS = false;
export const AzoSansRegular = IS_iOS ? 'AzoSans-Regular' : 'AzoSans-Regular';

export const FontAzoSansLight = IS_iOS ? 'AzoSans-Light' : 'AzoSans-Light';

export const FontAzoSansBold = IS_iOS ? 'AzoSans-Bold' : 'AzoSans-Bold';

export const RobotoMedium = IS_iOS ? 'Roboto-Medium' : 'Roboto-Medium';

export const RobotoRegular = IS_iOS ? 'Roboto-Regular' : 'Roboto-Regular';

export const FontTrado = IS_iOS ? 'Traditional Arabic' : 'trado';

export const SfUIMedium = IS_iOS ? 'SF-UI-Display-Medium' : 'SF-UI-Display-Medium';

export const SFProDisplay = IS_iOS ? 'SFProDisplay-Regular' : 'SFProDisplay-Regular';
