import * as d3 from 'd3';

export const VISIBLE_HISTORY_WINDOW = {
  /**
   * Max amount of History items visible on the screen at any moment
   */
  NUMBER_ITEMS: 80,
  /**
   * Interval in Milliseconds between animation steps on min speed
   */
  SCROLLING_MIN_DELAY_MS: 1000,
  /**
   * Interval in Milliseconds between animation steps on max speed
   */
  SCROLLING_MAX_DELAY_MS: 50,
  SCROLLING_SPEED_RANGE: [1, 10],
};

export function calcTimeToScrollMs(historyLength: number, speed: number): number {
  const amountOfIncrements = historyLength;

  let calculateStepIntervalMsBasedOnSpeed = d3
    .scaleLinear()
    .range([VISIBLE_HISTORY_WINDOW.SCROLLING_MIN_DELAY_MS, VISIBLE_HISTORY_WINDOW.SCROLLING_MAX_DELAY_MS])
    .domain(VISIBLE_HISTORY_WINDOW.SCROLLING_SPEED_RANGE);

  const stepIntervalMs = calculateStepIntervalMsBasedOnSpeed(speed) || 0;
  return amountOfIncrements * stepIntervalMs;
}

export function getHistoryIndexOffsetBySessionTimeMs(sessionTimeMs: number, speed: number): number {
  const calculateStepIntervalMsBasedOnSpeed = d3
    .scaleLinear()
    .range([VISIBLE_HISTORY_WINDOW.SCROLLING_MIN_DELAY_MS, VISIBLE_HISTORY_WINDOW.SCROLLING_MAX_DELAY_MS])
    .domain(VISIBLE_HISTORY_WINDOW.SCROLLING_SPEED_RANGE);

  const stepIntervalMs = calculateStepIntervalMsBasedOnSpeed(speed) || 0;

  return sessionTimeMs / stepIntervalMs;
}
