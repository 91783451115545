import React, {useState} from 'react';
import type {TickerDataPoint} from '../../Utils/ticker-utils';
import type {Trades} from '../../trades';
import {Gutter} from '../Widgets/Gutter/Gutter';
import {TimelineChart} from '../Widgets/TimelineChart';
import XAxis from '../Widgets/XAxis';
import {YAxis} from '../Widgets/YAxis';
import {TradeLines} from '../Widgets/TradeLines';
import {Canvas} from '../../Canvas';
import StockChartNewsAlerts from './StockChartNewsAlerts';
import {BoardStateRef} from '../../../../hooks/useBoardStateRef';
import SMASwitcher from '../../components/SMASwitcher';
import BoardSwitchersContainer from '../../components/BoardSwitchersContainer';
import SpeedSwitcher from '../../components/SpeedSwitcher';
import MinimapSwitcher from '../../components/MinimapSwitcher';
import MinimapChart from '../MinimapChart/MinimapChart';
import appScale from '../../../../util/appScale';
import {IChartProps} from '../../../../types/UseChartTypes';

const MINIMAP_MARGIN = appScale(6);

export interface StockChartProps {
  trades: Trades[];
  latestTrade: Trades;
  latest: TickerDataPoint;
  canvas: Canvas;
  chart: IChartProps;
  chartMinimap?: IChartProps;
  isRunEnded: boolean;
  play: () => void;
  pause: () => void;
  handleBuyOrSellTrade: (tradeItem?: TickerDataPoint) => void;
  boardStateRef: BoardStateRef;
  updateSMAWindow: (newWindow: number) => void;
  setSpeedRate: React.Dispatch<React.SetStateAction<number>>;
  speedRate: number;
  isRunStarted: boolean;
}
const StockChart: React.FC<StockChartProps> = (props) => {
  const {
    trades,
    latestTrade,
    latest,
    canvas,
    isRunEnded,
    chart,
    pause,
    play,
    handleBuyOrSellTrade,
    boardStateRef,
    updateSMAWindow,
    setSpeedRate,
    speedRate,
    chartMinimap,
    isRunStarted,
  } = props;

  const svgWidth = canvas.getWidth();
  const svgHeight = canvas.getHeight();
  const chartWidth = chart?.chartWidth || svgWidth;

  const [isMinimapShown, setIsMinimapShowed] = useState(true);
  const onMinimapButtonPress = () => setIsMinimapShowed((isShowed) => !isShowed);

  return (
    <>
      <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`} style={styles.graphContainer}>
        <svg x="1%">
          <TimelineChart chart={chart} />
          <TradeLines trades={trades} chart={chart} latest={latest} />
        </svg>
        <XAxis chart={chart} />
        <YAxis chart={chart} />
        {!isRunEnded && (
          <Gutter
            shouldShowUpcomingNewsAlert={boardStateRef.newsAlertDialogIdRef !== null}
            width={chartWidth}
            latestTrade={latestTrade}
            latestTick={latest}
            chart={chart}
          />
        )}
        <svg x="1%">
          <StockChartNewsAlerts
            boardStateRef={boardStateRef}
            pause={pause}
            chart={chart}
            latestIndex={latest.index}
            handleBuyOrSellTrade={handleBuyOrSellTrade}
            play={play}
          />
        </svg>
      </svg>
      <BoardSwitchersContainer>
        <MinimapSwitcher onClick={onMinimapButtonPress} isMinimapShown={isMinimapShown} />
        <SpeedSwitcher setSpeedRate={setSpeedRate} speedRate={speedRate} />
        <SMASwitcher
          boardStateRef={boardStateRef}
          canShowSMATutorialDialogs={isRunStarted}
          selected={chart.SMA.window}
          onSelect={updateSMAWindow}
          play={play}
          pause={pause}
        />
      </BoardSwitchersContainer>
      {chartMinimap && isMinimapShown && (
        <div style={styles.minimap}>
          <MinimapChart chart={chartMinimap} trades={trades} />
        </div>
      )}
    </>
  );
};

export default React.memo(StockChart);

const styles = {
  graphContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  minimap: {
    position: 'absolute',
    top: MINIMAP_MARGIN,
    left: MINIMAP_MARGIN,
  } as React.CSSProperties,
};
