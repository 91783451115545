import React from 'react';

import IconButton from '../../../../../components/common/IconButton';
import {REPLAY_ICON, PLAY_NEXT_ICON, PLAYLIST_ICON} from '../../../../../assets/icons';
import appScale from '../../../../../util/appScale';

export interface EndRunDialogActionsProps {
  onPlaylist: () => void;
  onPlay: () => void;
  onReplay: () => void;
}

const ICON_SIZE = appScale(16.5);
const PLAY_ICON_SIZE = ICON_SIZE * 1.2;

const EndRunDialogActions: React.FC<EndRunDialogActionsProps> = ({onPlaylist, onPlay, onReplay}) => {
  return (
    <div style={styles.container}>
      <IconButton size={ICON_SIZE} src={REPLAY_ICON} onClick={onReplay} />
      <div style={styles.buttonSpace} />
      <IconButton size={PLAY_ICON_SIZE} src={PLAY_NEXT_ICON} onClick={onPlay} />
      <div style={styles.buttonSpace} />
      <IconButton size={ICON_SIZE} src={PLAYLIST_ICON} onClick={onPlaylist} />
    </div>
  );
};
export default EndRunDialogActions;

const styles = {
  container: {
    marginTop: appScale(1.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: appScale(4.5),
    zIndex: 1301,
  },
  buttonSpace: {
    width: appScale(12.5),
  },
} as const;
