import React, {CSSProperties} from 'react';

import {format} from '../../Utils/utils';
import {RobotoMedium} from '../../../../config/fonts';
import {TutorialSteps, useTutorial} from '../../../../contexts/TutorialContext';
import TutorialContent from '../../../../components/Tutorial/TutorialContent';
import TutorialText from '../../../../components/Tutorial/TutorialText';
import TutorialTriangle from '../../../../components/Tutorial/TutorialTriangle';
import {STARTUP_BALANCE} from '../../constants';
import appScale from '../../../../util/appScale';

export interface NavigationHeaderAccountCashProps {
  cash: number;
}

const formatCurrency = (amount: number) => {
  return format(amount, ',.0f');
};

const NavigationHeaderAccountCash: React.FC<NavigationHeaderAccountCashProps> = ({cash}) => {
  const tutorial = useTutorial();
  const profit = cash - STARTUP_BALANCE;
  return (
    <div style={styles.container}>
      {tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_BEFORE_RUN_BOARD_TUTORIAL) && (
        <TutorialContent style={styles.tutorialContent}>
          <TutorialTriangle style={styles.tutorialTriangle} />
          <TutorialText style={styles.tutorialText}>Your $ run balance</TutorialText>
        </TutorialContent>
      )}
      {tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_PROFIT) && (
        <TutorialContent style={{...styles.tutorialContent, ...styles.profitTutorialContent}}>
          <TutorialTriangle style={{...styles.tutorialTriangle, ...styles.profitTutorialTriangle}} />
          <TutorialText style={styles.tutorialText}>
            <div>
              <span style={profit > 0 ? styles.textGreen : styles.textRed}>${formatCurrency(profit)}&nbsp;</span>
              Added to your available balance
            </div>
          </TutorialText>
        </TutorialContent>
      )}
      <div style={styles.text}>
        {'Balance '}: ${formatCurrency(cash)} (USD)
      </div>
    </div>
  );
};

export default React.memo(NavigationHeaderAccountCash);

const styles: Record<any, CSSProperties> = {
  container: {
    flex: 1,
  },
  tutorialContent: {
    position: 'absolute',
    top: appScale(14.2),
    width: appScale(80),
  },
  profitTutorialContent: {
    width: appScale(120),
    padding: `${appScale(1.25)}px ${appScale(2.6)}px`,
  },
  tutorialTriangle: {
    position: 'absolute',
    top: appScale(-7),
    left: appScale(20),
  },
  profitTutorialTriangle: {
    left: appScale(30),
  },
  tutorialText: {
    fontSize: appScale(4),
  },
  textGreen: {
    color: '#01EA00',
  },
  textRed: {
    color: '#f54748',
  },
  text: {
    color: '#fff',
    fontSize: appScale(4),
    fontFamily: RobotoMedium,
  },
};
