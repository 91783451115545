import React from 'react';

import * as d3 from 'd3';
import {PRICE_CONTAINER_HEIGHT, PRICE_CONTAINER_WIDTH} from '../../../constants';
import appScale from '../../../../../util/appScale';

interface Price {
  backgroundColor: string;
  color: string;
  price: number;
}

const TEXT_X = appScale(1.6);
const TEXT_Y = appScale(6.6);
const TEXT_FONT_SIZE = appScale(4);
const HALF_CONTAINER_SIZE = PRICE_CONTAINER_HEIGHT / 2;

const formatPriceValue = (amount: number) => {
  let result = d3.format('.2f')(amount);

  // check if penny stock
  if (amount < 1) {
    result = d3.format('.6f')(amount);
  }

  return result;
};

const Price: React.FC<Price> = ({backgroundColor, color, price}) => {
  return (
    <g>
      <rect
        x="0"
        y={-PRICE_CONTAINER_HEIGHT / 2}
        width={PRICE_CONTAINER_WIDTH}
        height={PRICE_CONTAINER_HEIGHT}
        fill={backgroundColor}
      />
      <text x={TEXT_X} y={TEXT_Y - HALF_CONTAINER_SIZE - TEXT_FONT_SIZE / 3} fontSize={TEXT_FONT_SIZE} fill={color}>
        {' '}
        {formatPriceValue(price)}
      </text>
    </g>
  );
};

export default Price;
