import React from 'react';
import {useTutorialNextStepDisabled} from '../../../contexts/TutorialContext';
import TutorialTapToContinueDialog from '../../Tutorial/TutorialTapToContinueDialog';
import {TUTORIAL_COMMON_STYLES} from '../../Tutorial/TUTORIAL_CONFIG';

export interface BoardTutorialBeforeRunDialogProps {
  onTapToContinue: () => void;
}

const BoardTutorialBeforeRunDialog: React.FC<BoardTutorialBeforeRunDialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();

  return (
    <TutorialTapToContinueDialog
      containerStyle={TUTORIAL_COMMON_STYLES.boardDialogContainer}
      contentStyle={TUTORIAL_COMMON_STYLES.boardDialogContent}
      onTapToContinue={onTapToContinue}
      disabled={tutorialNextStepDisabled.disabled}
      text={
        <>
          <div>Are you ready to begin your first Run?</div>
        </>
      }
    />
  );
};

export default BoardTutorialBeforeRunDialog;
