import React, {useEffect, useRef} from 'react';
import makeSound from '../../../util/makeSound';
import {WebViewMobileSounds} from '../boardTypes';

export interface EndRunSoundEffectsProps {
  currentTickIndex: number;
  maxTickIndex: number;
  tickerName: string;
  speedRate: number;
}

const MAX_TICK_SOUND_COUNTER = 5;
const DEFAULT_TICK_SOUND_COUNTER = 0;
const HUNDRED_PERCENT = 100;

const EndRunSoundEffects: React.FC<EndRunSoundEffectsProps> = ({
  currentTickIndex,
  maxTickIndex,
  tickerName,
  speedRate,
}) => {
  const tickSoundCounterRef = useRef(DEFAULT_TICK_SOUND_COUNTER);
  const lastTickSoundIndexRef = useRef(maxTickIndex);
  const isStartedRef = useRef(false);

  const getFirstTickSoundIndex = () => {
    const onePercentInIndexes = maxTickIndex / HUNDRED_PERCENT;
    const firstTickSoundInPercent = HUNDRED_PERCENT - MAX_TICK_SOUND_COUNTER * speedRate;
    return onePercentInIndexes * firstTickSoundInPercent;
  };

  useEffect(() => {
    if (tickSoundCounterRef.current >= MAX_TICK_SOUND_COUNTER) return;
    if (lastTickSoundIndexRef.current >= maxTickIndex) return;
    if (currentTickIndex < lastTickSoundIndexRef.current) return;

    tickSoundCounterRef.current++;
    lastTickSoundIndexRef.current += (maxTickIndex - getFirstTickSoundIndex()) / MAX_TICK_SOUND_COUNTER;
    makeSound(WebViewMobileSounds.COUNTDOWN);
  });

  useEffect(() => {
    // for resetting lastTickSoundex if the speed rate has been changed.
    // Only works if the countdown is not started yet
    if (isStartedRef.current) return;
    lastTickSoundIndexRef.current = getFirstTickSoundIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speedRate]);

  useEffect(() => {
    // for resetting for the new run
    lastTickSoundIndexRef.current = getFirstTickSoundIndex();
    tickSoundCounterRef.current = DEFAULT_TICK_SOUND_COUNTER;
    isStartedRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickerName]);

  return null;
};

export default EndRunSoundEffects;
