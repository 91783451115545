import React from 'react';
import './App.css';
import Board from './components/board';
import AppContext from './contexts/AppContext';
import {initTelemetry} from './util/telemetry';

function App() {
  initTelemetry();

  return (
    <AppContext>
      <Board countdownMs={3000} defaultSpeed={10} />
    </AppContext>
  );
}

export default App;

// DISABLE WEB BROWSER NATIVE EVENTS
window.addEventListener('contextmenu', (e) => e.preventDefault());
