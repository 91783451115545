import {RobotoMedium} from '../../config/fonts';
import COLORS from '../../config/colors';
import {BOARD_NAV_HEIGHT} from '../board/constants';
import appScale from '../../util/appScale';

const TUTORIAL_CONFIG = {
  FONT_SIZE: appScale(5.6),
  FONT_FAMILY: RobotoMedium,
  ACTION_CONTAINER_BORDER_COLOR: COLORS.CAMARONE.default,
  ACTION_TEXT_COLOR: COLORS.FESTIVAL.default,
};

export default TUTORIAL_CONFIG;

export const TUTORIAL_COMMON_STYLES = {
  boardDialogContainer: {
    marginTop: BOARD_NAV_HEIGHT,
  },
  boardDialogContent: {
    top: -BOARD_NAV_HEIGHT,
  },
};
