import React, {useState} from 'react';

import COLORS from '../../../config/colors';
import ButtonsNames from '../../../config/buttonsNames';
import appScale from '../../../util/appScale';
import {TelemetryEvents, sendEvent} from '../../../util/telemetry';

export interface BoardSwitcherOption {
  value: number;
  defaultIcon: any;
  activeIcon: any;
  telemetryButtonName?: ButtonsNames;
}

export interface BoardSwitcherProps {
  selected: number;
  onSelect: (selected: number) => void;
  options: BoardSwitcherOption[];
  disabled?: boolean;
}

const SIZE = appScale(13);
const EXPANDED_INDICATOR_SIZE = SIZE / 8;
const SPACING = appScale(1.25);

const BoardSwitcher: React.FC<BoardSwitcherProps> = (props) => {
  const {selected, onSelect, options, disabled} = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandCollapseOptions = () => {
    setIsExpanded((v) => !v);
  };

  const handleSelectOption = (option: BoardSwitcherOption) => {
    if (option.telemetryButtonName) {
      sendEvent(TelemetryEvents.BUTTON_PRESSED, {
        button: option.telemetryButtonName,
      });
    }
    onSelect(option.value);
    setIsExpanded(false);
  };

  const selectedOption = options.find((option) => option.value === selected);

  return (
    <div style={styles.container}>
      {<Option disabled={disabled} src={selectedOption?.activeIcon} onClick={handleExpandCollapseOptions} />}
      {isExpanded &&
        options.map((option) =>
          option.value !== selectedOption?.value ? (
            <Option
              key={option.value}
              src={option.defaultIcon}
              onClick={() => handleSelectOption(option)}
              disabled={disabled}
            />
          ) : null,
        )}
      <ExpandedIndicator isExpanded={isExpanded} />
    </div>
  );
};

export default BoardSwitcher;

const styles = {
  container: {
    marginVertical: appScale(0.75),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    position: 'relative',
  } as React.CSSProperties,
  optionContainer: {
    border: 'none',
    margin: SPACING,
    width: SIZE,
    height: SIZE,
    background: 'none',
  } as React.CSSProperties,
  expandedIndicator: {
    position: 'absolute',
    width: 0,
    height: 0,
    right: appScale(-1.75),
    top: SIZE / 2,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: EXPANDED_INDICATOR_SIZE,
    borderRightWidth: EXPANDED_INDICATOR_SIZE,
    borderBottomWidth: EXPANDED_INDICATOR_SIZE,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: COLORS.LIME_GREEN.default,
    transform: 'rotate(90deg)',
  } as React.CSSProperties,
  expandedIndicatorTrue: {
    transform: 'rotate(-90deg)',
  } as React.CSSProperties,
};

interface OptionProps {
  disabled?: boolean;
  src: any;
  onClick: () => void;
}

export const Option: React.FC<OptionProps> = ({onClick, src, disabled}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (disabled) return;

    onClick();
  };

  return <img src={src} alt="alt" style={styles.optionContainer} onClick={handleClick} className="animation-fade-in" />;
};

const ExpandedIndicator: React.FC<{isExpanded: boolean}> = ({isExpanded}) => (
  <div style={{...styles.expandedIndicator, ...(isExpanded && styles.expandedIndicatorTrue)}} />
);
