import React, {CSSProperties} from 'react';

import ChartProgressBar, {IChartProgressBar} from '../Charts/Widgets/ChartProgressBar';
import {BoardStateRef} from '../../../hooks/useBoardStateRef';
import {IBoardState} from '../i-board-state';
import IconButton from '../../common/IconButton';
import {HOME_ICON, PAUSE_ICON} from '../../../assets/icons';
import sendWebViewMessage from '../Utils/sendWebViewMessage';
import {WebViewMobileMessages, WebViewMobileSounds} from '../boardTypes';
import {BOARD_NAV_HEIGHT} from '../constants';
import {Routes} from '../../../config/routes';
import NavigationHeaderOpenCloseEndRunDialog from './NavigationHeaderOpenCloseEndRunDialog';
import makeSound from '../../../util/makeSound';
import appScale from '../../../util/appScale';
import NavigationHeaderExitButton from './components/NavigationHeaderExitButton';
import NavigationHeaderAccountCash from './components/NavigationHeaderAccountCash';
import NavigationHeaderNextButton from './components/NavigationHeaderNextButton';
import {useFinishTutorial, useTutorial} from '../../../contexts/TutorialContext';
import {IS_DEV_MODE} from '../../../config/constants';
import {TelemetryEvents, sendEvent} from '../../../util/telemetry';
import ButtonsNames from '../../../config/buttonsNames';

const ICON_SIZE = appScale(8.6);

export interface NavigationHeaderProps {
  onNext: () => void;
  tickerName: string;
  progressLine: IChartProgressBar;
  play: () => void;
  pause: () => void;
  isRunStarted: boolean;
  isRunRunning: boolean;
  boardStateRef: BoardStateRef;
  state: IBoardState;
  ticker: any | null;
  mutateState: (newState: Partial<IBoardState>) => void;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = (props) => {
  const {tickerName, progressLine, pause, play, isRunRunning, boardStateRef, state, mutateState, isRunStarted} = props;
  const tutorial = useTutorial();
  const finishTutorial = useFinishTutorial();

  const openEndRunDialog = () => mutateState({isEndRunDialogVisible: true});

  const handlePause = () => {
    if (boardStateRef.newsAlertDialogIdRef) return;
    if (tutorial.isInProgress) finishTutorial();
    if (!isRunStarted) return exitRun();

    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.BOARD_SCREEN_NAVBAR_PAUSE,
    });
    if (isRunRunning) pause();
    else play();
  };

  const handleNextRun = () => {
    makeSound(WebViewMobileSounds.TAP);
    sendWebViewMessage({message: WebViewMobileMessages.NEXT_RUN});
  };
  const handleNavigateHome = () => sendWebViewMessage({message: WebViewMobileMessages.NAVIGATE_TO, route: Routes.HOME});
  const exitRun = () => sendWebViewMessage({message: WebViewMobileMessages.EXIT_RUN});

  const {isRunEnded, isEndRunDialogVisible} = state;
  const cansShowChartProgressBar = !!progressLine?.history?.length && !isRunEnded;
  const hasGameOverDialogHiddenInTheEnd = isRunEnded && !isEndRunDialogVisible;

  return (
    <div style={mainContainerStyles}>
      <div style={{...styles.navContainer, ...{paddingRight: isRunEnded ? 0 : appScale(4)}}}>
        {isRunEnded ? (
          <>
            <IconButton src={HOME_ICON} size={28} onClick={handleNavigateHome} />
            <div style={styles.divider} />
          </>
        ) : (
          IS_DEV_MODE && (
            <>
              <IconButton src={PAUSE_ICON} size={ICON_SIZE} onClick={handlePause} />
              <div style={styles.divider} />
            </>
          )
        )}
        <div style={styles.tickerName}>{tickerName}</div>
        <div style={styles.divider} />
        <NavigationHeaderAccountCash cash={state.cash} />
        {isRunEnded ? (
          hasGameOverDialogHiddenInTheEnd && <NavigationHeaderNextButton disabled={false} onClick={handleNextRun} />
        ) : (
          <NavigationHeaderExitButton onClick={handlePause} />
        )}
      </div>
      {cansShowChartProgressBar && <ChartProgressBar {...progressLine} />}
      {hasGameOverDialogHiddenInTheEnd && <NavigationHeaderOpenCloseEndRunDialog onClick={openEndRunDialog} />}
    </div>
  );
};

export default React.memo(NavigationHeader);

const mainContainerStyles: CSSProperties = {
  position: 'relative',
  color: 'white',
  maxWidth: '100%',
  zIndex: 10,
  display: 'flex',
  flexFlow: 'column nowrap',
};

const styles = {
  navContainer: {
    zIndex: 999,

    height: BOARD_NAV_HEIGHT,

    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',

    backgroundColor: 'rgba(33,41,31,1)',
    paddingLeft: appScale(4),
    paddingRight: appScale(4),
  },
  left: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  tickerName: {
    fontSize: appScale(4),
  },
  cash: {
    flex: 1,
  },
  divider: {
    height: '100%',
    width: 1,
    backgroundColor: 'rgba(0,94,58,0.7)',
    marginLeft: appScale(3.25),
    marginRight: appScale(3.25),
  },
};
