import React, {CSSProperties} from 'react';
import {RobotoMedium} from '../../../../../config/fonts';
import {PlaylistItemRating} from '../../../../../util/playlistRating';
import appScale from '../../../../../util/appScale';

export interface EndRunDialogNewBestProps {
  tickerName: string;
  runRating: PlaylistItemRating;
  tickerRating?: PlaylistItemRating;
}

const EndRunDialogNewBest: React.FC<EndRunDialogNewBestProps> = ({tickerName, runRating, tickerRating}) => {
  const shouldShowCongratulation = !tickerRating || runRating.tradeReturn > tickerRating.tradeReturn;
  if (!shouldShowCongratulation) return null;

  return <div style={containerStyles}>{`New best for ${tickerName}!`}</div>;
};

export default EndRunDialogNewBest;

const containerStyles: CSSProperties = {
  marginTop: appScale(1.25),
  paddingLeft: appScale(3.25),
  paddingRight: appScale(3.25),

  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: RobotoMedium,
  fontSize: appScale(6.35),
  color: 'white',
  letterSpacing: appScale(0.2),
  fontWeight: 'bold',
  paddingTop: appScale(0.3),
  paddingBottom: appScale(0.3),
};
