import {useThrottledFn, useWindowResize} from 'beautiful-react-hooks';
import {useEffect} from 'react';
import getWindowSize from '../util/getWindowSize';

type Handler = (windowSize: {height: number; width: number}) => void;

const THROTTLE = {
  OPTIONS: {},
  WAIT: 250,
};

const useAppWindowResize = <T>(handler: Handler, dependencies: any[]) => {
  // handle first/initial resize
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handler(getWindowSize()), []);

  // handle window resize
  useWindowResize(useThrottledFn(() => handler(getWindowSize()), THROTTLE.WAIT, THROTTLE.OPTIONS, dependencies));
};

export default useAppWindowResize;
