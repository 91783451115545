import React from 'react';
import {useTutorialNextStepDisabled} from '../../../contexts/TutorialContext';
import TutorialTapToContinueDialog from '../../Tutorial/TutorialTapToContinueDialog';
import {TUTORIAL_COMMON_STYLES} from '../../Tutorial/TUTORIAL_CONFIG';

export interface BoardTutorialAboutStockDialogProps {
  onTapToContinue: () => void;
}

const BoardTutorialAboutStockDialog: React.FC<BoardTutorialAboutStockDialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();

  return (
    <TutorialTapToContinueDialog
      containerStyle={TUTORIAL_COMMON_STYLES.boardDialogContainer}
      contentStyle={TUTORIAL_COMMON_STYLES.boardDialogContent}
      onTapToContinue={onTapToContinue}
      disabled={tutorialNextStepDisabled.disabled}
      text={
        <>
          <div>
            You are about to Buy and Sell shares from 4 {'\n'}
            years of Duck & Wheelbarrows Ltd. stock in {'\n'}
            less than a minute.
          </div>
        </>
      }
    />
  );
};

export default BoardTutorialAboutStockDialog;
