import {PLAYLIST_RATING_SCORE} from '../config/constants';

export interface RunResults {
  tradeReturn: number;
  stockReturn: number;
  indexReturn: number;
}

export interface PlaylistItemRating {
  tradeReturn: number;
  points: number;
}

export const getRatingIconSource = (ratingPoints: number): null | string =>
  Object.values(PLAYLIST_RATING_SCORE).find((RATING_SCORE) => RATING_SCORE.POINTS === ratingPoints)?.ICON ?? null;

export function calculateRunRating(runResults: RunResults): PlaylistItemRating {
  return {
    tradeReturn: runResults.tradeReturn,
    points: calculateRatingPoints(runResults),
  };
}

function calculateRatingPoints({tradeReturn, indexReturn, stockReturn}: RunResults): number {
  let criteria = 0;
  if (tradeReturn > stockReturn) criteria++;
  if (tradeReturn > indexReturn) criteria++;
  if (tradeReturn > 0) criteria++;

  if (criteria === 3) return PLAYLIST_RATING_SCORE.HIGH.POINTS;
  if (criteria === 2) return PLAYLIST_RATING_SCORE.MEDIUM.POINTS;
  if (criteria === 1) return PLAYLIST_RATING_SCORE.LOW.POINTS;
  return PLAYLIST_RATING_SCORE.NO_POINTS.POINTS;
}
