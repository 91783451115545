import {useCallback, useEffect, useRef, useState} from 'react';

// if we set large than current FPS is dropping
export const MAX_FPS_COUNTER = 1000 / 50;

const useAnimation = () => {
  const startTime = useRef<number>(Date.now());
  const distance = useRef<number>(1);
  const currentSpeed = useRef<number>(2);
  const [count, setCount] = useState<number>(0);
  const pauseDate = useRef<number | null>();
  const [isRunRunning, setIsRunRunning] = useState<boolean>(false);
  const newCallback = useRef<(dist: number) => void>();

  const startAnimate = (callback: (dist: number) => void) => {
    newCallback.current = callback;
    startTime.current = Date.now();
    setIsRunRunning(true);
  };

  const updateAnimation = (speed: number) => {
    if (speed !== currentSpeed.current) {
      const newStartTime = distance.current / speed;

      currentSpeed.current = speed;
      startTime.current = Date.now() - newStartTime;

      if (pauseDate.current) {
        pauseDate.current = Date.now();
      }
    }
  };

  const runAnimation = useCallback(() => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (newCallback.current && !pauseDate.current) {
          const duration = Date.now() - startTime.current;
          const dist = duration * currentSpeed.current;

          setCount((c) => c + 1);
          distance.current = dist;
          newCallback.current(dist);
        }
      });
    }, MAX_FPS_COUNTER);
  }, []);

  const pause = () => {
    if (!pauseDate.current) {
      pauseDate.current = Date.now();
      setIsRunRunning(false);
    }
  };

  const play = () => {
    if (pauseDate.current) {
      const pauseDelay = Date.now() - pauseDate.current;
      startTime.current = startTime.current + pauseDelay;
    }
    pauseDate.current = null;
    setIsRunRunning(true);
  };

  const reset = () => {
    startTime.current = Date.now();
    pauseDate.current = null;
    distance.current = 1;
    setCount(0);
    setIsRunRunning(false);
  };

  useEffect(() => {
    if (newCallback.current && isRunRunning && !pauseDate.current) {
      runAnimation();
    }
  }, [isRunRunning, count, runAnimation, pauseDate]);

  return {startAnimate, updateAnimation, pause, play, reset, isRunRunning, distance: distance.current};
};

export default useAnimation;
