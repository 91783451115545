enum ButtonsNames {
  // BOARD SCREEN
  BOARD_SCREEN_SPEED_SELECTOR_FASTEST = 'board_screen_speed_selector_fastest',
  BOARD_SCREEN_SPEED_SELECTOR_FAST = 'board_screen_speed_selector_fast',
  BOARD_SCREEN_SPEED_SELECTOR_MEDIUM = 'board_screen_speed_selector_medium',
  BOARD_SCREEN_SPEED_SELECTOR_SLOW = 'board_screen_speed_selector_slow',

  BOARD_SCREEN_SMA_SWITCHER_OFF = 'board_screen_sma_switcher_off',
  BOARD_SCREEN_SMA_SWITCHER_10 = 'board_screen_sma_switcher_10',
  BOARD_SCREEN_SMA_SWITCHER_50 = 'board_screen_sma_switcher_50',
  BOARD_SCREEN_SMA_SWITCHER_200 = 'board_screen_sma_switcher_200',

  BOARD_SCREEN_NAVBAR_EXIT = 'board_screen_navbar_exit',
  BOARD_SCREEN_NAVBAR_NEXT = 'board_screen_navbar_next',
  BOARD_SCREEN_NAVBAR_HOME = 'board_screen_navbar_home',
  BOARD_SCREEN_NAVBAR_PAUSE = 'board_screen_navbar_pause',

  PAUSE_DIALOG_RESUME = 'pause_dialog_resume',
  PAUSE_DIALOG_EXIT = 'pause_dialog_exit',

  BOARD_SCREEN_EXIT_DIALOG_CONFIRM = 'board_screen_exit_dialog_confirm',
  BOARD_SCREEN_EXIT_DIALOG_CANCEL = 'board_screen_exit_dialog_cancel',

  BOARD_SCREEN_NEWS_DIALOG_BUY_SELL = 'board_screen_news_dialog_buy_sell',
  BOARD_SCREEN_NEWS_DIALOG_CONTINUE = 'board_screen_news_dialog_continue',
  BOARD_SCREEN_NEWS_DIALOG_READ_MORE = 'board_screen_news_dialog_read_more',
  BOARD_SCREEN_NEWS_DIALOG_OPEN_LINK = 'board_screen_news_dialog_open_link',

  BOARD_SCREEN_RUN_COMPLETED_DIALOG_SHARE = 'board_screen_run_completed_dialog_share',
  BOARD_SCREEN_RUN_COMPLETED_DIALOG_NEXT = 'board_screen_run_completed_dialog_next',
  BOARD_SCREEN_RUN_COMPLETED_DIALOG_PLAYLIST = 'board_screen_run_completed_dialog_playlist',
  BOARD_SCREEN_RUN_COMPLETED_DIALOG_EXIT = 'board_screen_run_completed_dialog_exit',
  BOARD_SCREEN_RUN_COMPLETED_DIALOG_BACKGROUND = 'board_screen_run_completed_dialog_background',
  BOARD_SCREEN_RUN_COMPLETED_DIALOG_REPLAY = 'board_screen_run_completed_dialog_replay',
  BOARD_SCREEN_VIEW_RUN_SCORES = 'board_screen_view_run_scores',

  BOARD_SCREEN_TRADE_BUY = 'board_screen_trade_buy',
  BOARD_SCREEN_TRADE_SELL = 'board_screen_trade_sell',

  BOARD_SCREEN_ENTIRE_CHART_OPEN_NEWS_ALERT = 'board_screen_entire_chart_open_news_alert',
  BOARD_SCREEN_ENTIRE_CHART_CLOSE_NEWS_ALERT = 'board_screen_entire_chart_close_news_alert',
}

export default ButtonsNames;
