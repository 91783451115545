/**
 |--------------------------------------------------
 | APPLICATION DIFFERENT CONSTANT
 |--------------------------------------------------
 */
import {RATING_HIGH_ICON, RATING_LOW_ICON, RATING_MEDIUM_ICON} from '../assets/icons';

export const IS_DEV_MODE = process.env.NODE_ENV === 'development';

export const PLAYLIST_RATING_SCORE = {
  HIGH: {
    ICON: RATING_HIGH_ICON,
    POINTS: 6,
  },
  MEDIUM: {
    ICON: RATING_MEDIUM_ICON,
    POINTS: 3,
  },
  LOW: {
    ICON: RATING_LOW_ICON,
    POINTS: 1,
  },
  NO_POINTS: {
    ICON: null,
    POINTS: 0,
  },
};

export const DURING_OF_TRADE_RESULT_DIALOG_IN_MS = 1000;

export const NEWS_FLASHES_STORAGE_KEY = 'NEWS_ALERT_FLASHES_STORAGE_KEY';
export const NEWS_FLASHES_DEFAULT_VALUE = true;

export const MINIMAP_SCALE = 4;

export const APP_SCALE_FACTOR = 0.3;
