import * as d3 from 'd3';
import {CurveFactory} from 'd3';
import {TickerDataPoint} from '../components/board/Utils/ticker-utils';
import {useCallback, useEffect, useRef, useState} from 'react';
import useStateRef from '../hooks/useStateRef';
import useViewport, {ViewByDistanceOptions} from './useViewport';
import {VISIBLE_HISTORY_WINDOW} from '../components/board/chartConfig';
import {Y_AXIS_CONTAINER_WIDTH} from '../components/board/constants';
import calculateSMAOfTickerDataPoints from '../components/board/Utils/calculateSMAOfTickerDataPoints';
import {
  IUseChart,
  IXTicks,
  SMAState,
  TickerDataPointSMA,
  UpdateConfig,
  UseChartState,
  UseChartType,
} from '../types/UseChartTypes';
import {createXTicks, getDefaultSMA} from '../util/useChartHelper';

const xScaleTicksCount = 12;

const useChart = (chartType: UseChartType): IUseChart => {
  // TODO: resolve TS bug with undefined for xScale and yScale
  const xScale = useRef<d3.ScaleLinear<number, number>>(d3.scaleLinear());
  const yScale = useRef<d3.ScaleLinear<number, number>>(d3.scaleLinear());
  const lineScale = useRef<d3.Line<TickerDataPoint>>(d3.line<TickerDataPoint>());
  const colorScale = useRef<number[] & d3.ScaleLinear<number, number>>(
    d3
      .scaleLinear()
      .domain([-0.001, 0.001]) // TODO: Why?
      // @ts-ignore
      .range(['#ff0000', '#00ff00']) // TODO: Why?
      .clamp(true),
  );
  const stepArea = useRef<d3.Area<TickerDataPoint>>(d3.area<TickerDataPoint>());
  const stepLine = useRef<d3.Line<TickerDataPoint>>(d3.line<TickerDataPoint>());
  const stepSMA = useRef<d3.Line<TickerDataPointSMA>>(d3.line<TickerDataPointSMA>());

  const xTicks = useRef<IXTicks[]>([]);
  const yTicks = useRef<number[]>([]);
  const chartUnderPath = useRef<string | null>();
  const chartPath = useRef<string | null>();
  const chartSMAPath = useRef<string | null>();

  const [SMA, setSMA, SMARef] = useStateRef<SMAState>(getDefaultSMA());
  const {chartHistory, calculateViewByDistance, view, setRange, setDomain, setChartHistory} = useViewport();
  // Need this to perform scrolling/zooming, I believe it will be deleted after splitting useChart
  const externalHistoryRef = useRef<TickerDataPoint[]>([]);

  const [state, setState] = useState<UseChartState>({
    history: [],
    speed: 0,
    gameDurationMs: 0,
  });

  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const updateSMAWindow = (smaWindow: number) => {
    if (smaWindow === SMARef.current.window) return;
    const list = calculateSMAOfTickerDataPoints(
      externalHistoryRef.current.length ? externalHistoryRef.current : state.history,
      smaWindow,
    );

    setSMA({window: smaWindow, list});
    drawSMA(state.history, SMARef.current);
  };

  const drawSMA = (boundData: TickerDataPoint[], SMA: SMAState) => {
    const availableListOfSMA = boundData.map((item) => SMA.list[item.index]).filter(Boolean);
    chartSMAPath.current = stepSMA.current(availableListOfSMA);
  };

  const reset = () => {
    setState((prevState) => ({
      ...prevState,
      history: [],
      speed: 0,
      gameDurationMs: 0,
    }));
    xTicks.current = [];
    yTicks.current = [];
    chartPath.current = null;
    chartUnderPath.current = null;
    chartSMAPath.current = null;
    setChartHistory([]);
    externalHistoryRef.current = [];
    setSMA((prevSMA) => ({...prevSMA, list: []}));
  };

  const resize = (width: number, height: number) => {
    setChartWidth(width - Y_AXIS_CONTAINER_WIDTH);
    setChartHeight(height);
  };

  const setup = (newHistory: TickerDataPoint[], newSpeed: number, newDuration: number) => {
    setState((prevState) => ({
      ...prevState,
      history: newHistory,
      speed: newSpeed,
      gameDurationMs: newDuration,
    }));

    setSMA((prevSMA) => ({
      ...prevSMA,
      list: calculateSMAOfTickerDataPoints(newHistory, prevSMA.window),
    }));
  };

  // TODO: remove viewForWholeDistance, viewForWholeDistanceWithExternalHistory and viewForMinimap and move logic to viewForDistance using 'chartType'
  const viewForDistance = (distance: number, wholeHistory?: TickerDataPoint[]) => {
    let {history} = state;

    const options: ViewByDistanceOptions = {};

    if (chartType === UseChartType.MINIMAP) {
      options.isMinimap = true;
    }

    if (chartType === UseChartType.ENTIRE) {
      options.isWholeDistance = true;
      setSMA((prevSMA) => ({
        ...prevSMA,
        list: calculateSMAOfTickerDataPoints(wholeHistory || [], SMARef.current.window),
      }));

      externalHistoryRef.current = wholeHistory || [];
      update({SMA: SMARef.current});
    }

    calculateViewByDistance(distance, history, options);
  };

  const setWorldCoordinates = useCallback((xSpan: [number, number], ySpan: [number, number]) => {
    xScale.current.range(xSpan);
    yScale.current.range(ySpan);
  }, []);

  const setLocalCoordinates = (xSpan: [number, number], ySpan: [number, number]) => {
    xScale.current.domain(xSpan);
    yScale.current.domain(ySpan);
  };

  const getHistoryForUpdate = useCallback(() => {
    let history = state?.history || [];

    switch (chartType) {
      case UseChartType.SIMPLE:
      case UseChartType.MINIMAP:
        history = state?.history || [];
        break;
      case UseChartType.ENTIRE:
        history = externalHistoryRef?.current?.length ? externalHistoryRef?.current : state?.history;
        break;
    }

    return history || [];
  }, [state.history, chartType]);

  const update = useCallback(
    ({SMA: SMAValue}: UpdateConfig) => {
      if (chartHistory?.length && state?.history?.length) {
        setLocalCoordinates(view.xRange, view.yRange);
        const ticks = xScale.current.ticks(xScaleTicksCount);
        xTicks.current = createXTicks(ticks, getHistoryForUpdate());
      }

      yTicks.current = yScale.current.ticks();
      chartPath.current = stepLine.current(chartHistory);
      chartUnderPath.current = stepArea.current(chartHistory);
      drawSMA(chartHistory, SMAValue);
    },
    [chartHistory, getHistoryForUpdate, state?.history?.length, view.xRange, view.yRange],
  );

  useEffect(() => {
    if (state.speed && state.gameDurationMs && state.history?.length) {
      setRange(state.gameDurationMs);

      const curveType: CurveFactory = d3.curveStepAfter;

      stepLine.current
        .x((d) => xScale.current(d.index) || 0)
        .y((d) => yScale.current(d.price) || 0)
        .curve(curveType);

      stepSMA.current
        .x((d) => xScale.current(d.index) || 0)
        .y((d) => yScale.current(d.price) || 0)
        .curve(d3.curveNatural);

      stepArea.current
        .x((d) => xScale.current(d.index) || 0)
        .y0(yScale.current.range()[0])
        .y1((d) => yScale.current(d.price) || 0)
        .curve(curveType);

      lineScale.current.x((d) => xScale.current(d.index) || 0).y((d) => yScale.current(d.price) || 0);
    }
  }, [state.history, state.speed, state.gameDurationMs, yScale, xScale, setRange, chartType]);

  useEffect(() => {
    // TODO: figure out how to rewrite it, maybe after refactoring/rewriting charts, we will fix it somehow
    // I have no idea why but it helps to solve this bug - https://gitlab.com/fantasy-mesa/trading-saga/bugs/-/issues/127
    // It looks like it's missing one more step to take all available space
    const additionalStepWidth = Math.floor(chartWidth / VISIBLE_HISTORY_WINDOW.NUMBER_ITEMS / 1.5);
    setWorldCoordinates([0, chartWidth + additionalStepWidth], [chartHeight, 0]);
  }, [chartHeight, chartWidth, setWorldCoordinates]);

  useEffect(() => {
    update({SMA: SMARef.current});
  }, [SMARef, chartHistory, update]);

  useEffect(() => {
    setDomain([0, state.history.length - 1]);
  }, [setDomain, state.history.length, state.speed]);

  return {
    setup,
    viewForDistance,
    resize,
    reset,
    chartHistory,
    updateSMAWindow,
    chart: {
      chartWidth,
      chartHeight,
      chartPath: chartPath.current,
      chartUnderPath: chartUnderPath.current,
      chartSMAPath: chartSMAPath.current,
      xScale: xScale.current,
      yScale: yScale.current,
      xTicks: xTicks.current,
      yTicks: yTicks.current,
      lineScale: lineScale.current,
      colorScale: colorScale.current,
      stepArea: stepArea.current,
      stepLine: stepLine.current,
      stepSMA: stepSMA.current,
      SMA,
      ...state,
    },
  };
};

export default useChart;
