import {colors} from '../StockChart/constants';
import React, {useMemo} from 'react';
import {SMA_OPTIONS} from '../../../../config/sma.config';
import appScale from '../../../../util/appScale';
import {IChartProps} from '../../../../types/UseChartTypes';

interface ITimelineChart {
  chart: IChartProps;
  lineColor?: string;
  fillColor?: string;
}

const STEP_WIDTH = appScale(0.45);

export const TimelineChart: React.FC<ITimelineChart> = ({
  chart,
  lineColor = colors.CHART,
  fillColor = colors.CHART_FILL,
}) => {
  const smaStockColor = useMemo(
    () => SMA_OPTIONS.find((option) => option.value === chart.SMA.window)?.strokeColor,
    [chart.SMA.window],
  );

  return (
    <g>
      {/* Line Path */}
      {chart.chartPath && (
        <path
          d={chart.chartPath}
          clipPath="url(#r-clip-path)"
          stroke={lineColor}
          fill={'none'}
          strokeWidth={STEP_WIDTH}
        />
      )}
      {/* Area Path */}
      {chart.chartUnderPath && (
        <path
          d={chart.chartUnderPath}
          clipPath="url(#r-clip-path)"
          fill={fillColor}
          stroke={'none'}
          strokeWidth={STEP_WIDTH}
        />
      )}
      {/* SMA Path */}
      {smaStockColor && chart.chartSMAPath && (
        <path
          d={chart.chartSMAPath}
          clipPath="url(#r-clip-path)"
          stroke={smaStockColor}
          fill={'none'}
          strokeWidth={STEP_WIDTH}
        />
      )}
    </g>
  );
};
