import React from 'react';

import BoardSwitcher, {BoardSwitcherOption} from './BoardSwitcher';
import {TutorialSteps, useTutorial} from '../../../contexts/TutorialContext';
import ButtonsNames from '../../../config/buttonsNames';
import {
  SPEED_SHIFTER_X1_HIGHLIGHT_ICON,
  SPEED_SHIFTER_X1_ICON,
  SPEED_SHIFTER_X2_HIGHLIGHT_ICON,
  SPEED_SHIFTER_X2_ICON,
  SPEED_SHIFTER_X3_HIGHLIGHT_ICON,
  SPEED_SHIFTER_X3_ICON,
  SPEED_SHIFTER_X4_HIGHLIGHT_ICON,
  SPEED_SHIFTER_X4_ICON,
} from '../../../assets/icons';

export interface SpeedSwitcherProps {
  setSpeedRate: React.Dispatch<React.SetStateAction<number>>;
  speedRate: number;
}

const SPEED_OPTIONS: BoardSwitcherOption[] = [
  {
    value: 1,
    defaultIcon: SPEED_SHIFTER_X1_ICON,
    activeIcon: SPEED_SHIFTER_X1_HIGHLIGHT_ICON,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SPEED_SELECTOR_SLOW,
  },
  {
    value: 2,
    defaultIcon: SPEED_SHIFTER_X2_ICON,
    activeIcon: SPEED_SHIFTER_X2_HIGHLIGHT_ICON,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SPEED_SELECTOR_MEDIUM,
  },
  {
    value: 3,
    defaultIcon: SPEED_SHIFTER_X3_ICON,
    activeIcon: SPEED_SHIFTER_X3_HIGHLIGHT_ICON,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SPEED_SELECTOR_FAST,
  },
  {
    value: 4,
    defaultIcon: SPEED_SHIFTER_X4_ICON,
    activeIcon: SPEED_SHIFTER_X4_HIGHLIGHT_ICON,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SPEED_SELECTOR_FASTEST,
  },
];

const SpeedSwitcher: React.FC<SpeedSwitcherProps> = (props) => {
  const {speedRate, setSpeedRate} = props;
  const tutorial = useTutorial();

  const disabled =
    tutorial.shouldBeDisabled(TutorialSteps.BOARD_SCREEN_FREE_RUN_AFTER_NEWS) &&
    tutorial.shouldBeDisabled(TutorialSteps.BOARD_SCREEN_FREE_RUN_BEFORE_NEWS);

  return <BoardSwitcher selected={speedRate} onSelect={setSpeedRate} disabled={disabled} options={SPEED_OPTIONS} />;
};

export default SpeedSwitcher;
