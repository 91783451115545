import * as React from 'react';
import {CSSProperties, useCallback, useEffect, useRef, useState} from 'react';

import StockChart from './Charts/StockChart';
import NavigationHeader from './NavigationHeader';

import {IBoardState} from './i-board-state';
import {Trades} from './trades';
import {BOARD_NAV_HEIGHT, STARTUP_BALANCE, TRADE_COMMISSION, Y_AXIS_CONTAINER_WIDTH} from './constants';
import {CashManager} from './cash-manager';

import {calcTimeToScrollMs} from './chartConfig';
import {Canvas} from './Canvas';
import {Countdown} from './Countdown/Countdown';
import {TickerDataPoint} from './Utils/ticker-utils';
import useChart from '../../hooks/useChart';
import useAnimation from '../../hooks/useAnimation';
import EntireStockChart from './Charts/EntireStockChart';
import EndRunDialog from './Dialogs/EndRunDialog';
import usePlaylistItemToPlay from '../../hooks/usePlaylistItemToPlay';
import {TutorialSteps, useStartTutorial, useTutorial} from '../../contexts/TutorialContext';
import useBoardTutorialTooltips from '../../hooks/useBoardTutorialTooltips';
import EndRunSoundEffects from './components/EndRunSoundEffects';
import TradeResultDialog from './Dialogs/TradeResultDialog';
import useBoardStateRef from '../../hooks/useBoardStateRef';

import useAppWindowResize from '../../hooks/useAppWindowResize';
import sendWebViewMessage from './Utils/sendWebViewMessage';
import {
  WebViewMobileMessages,
  WebViewMobileSounds,
  WebViewWebMessage,
  WebViewWebMessageInitializeRun,
  WebViewWebMessages,
} from './boardTypes';
import useWebViewMessage from '../../hooks/useWebViewMessage';
import separateRender from '../../util/separate-render';
import DIALOGS_IDS from '../../config/dialogsIds';
import {calculateRunRating} from '../../util/playlistRating';
import makeSound from '../../util/makeSound';
import appScale from '../../util/appScale';
import BoardTutorialAboutStockDialog from './Dialogs/BoardTutorialAboutStock';
import BoardTutorialBeforeRunDialog from './Dialogs/BoardTutorialBeforeRun';
import IconButton from '../common/IconButton';
import {PLAY_NEXT_ICON} from '../../assets/icons';
import useThrottle from '../../hooks/useThrottle';
import {UseChartType} from '../../types/UseChartTypes';
import {TelemetryEvents, sendEvent} from '../../util/telemetry';
import ButtonsNames from '../../config/buttonsNames';
import {getNewsCount} from '../../util/useChartHelper';
import PauseDialog from './Dialogs/PauseDialog';
import {MINIMAP_SCALE} from '../../config/constants';

interface BoardProps {
  countdownMs: number;
  defaultSpeed: number;
}

const INITIAL_RUN_DURATION_SEC = 240;

const Board: React.FC<BoardProps> = ({countdownMs = 3000, defaultSpeed = 5}) => {
  const mutateState = useCallback((newState: Partial<IBoardState>) => {
    setState((oldState) => ({...oldState, ...newState}));
  }, []);

  const tutorial = useTutorial();
  const startTutorial = useStartTutorial();
  const [state, setState] = useState<IBoardState>({
    initialCash: STARTUP_BALANCE,
    cash: STARTUP_BALANCE,
    isEndRunDialogVisible: false,
    height: 0,
    width: 0,
    isRunEnded: false,
    isCountdownVisible: false,
    isCountdownStarted: false,
    isChartVisible: false,
    runRating: null,
  });
  const boardStateRef = useBoardStateRef();
  const isRunEnded = state.isRunEnded;
  const [speed, setSpeed] = useState(defaultSpeed);
  const [speedRate, setSpeedRate] = useState(1);

  const canvas = useRef<Canvas | null>(null);

  const {
    setup,
    resize,
    viewForDistance,
    chartHistory,
    chart,
    reset: resetChart,
    updateSMAWindow: updateSMAWindowStockChart,
  } = useChart(UseChartType.SIMPLE);

  const entireStockChart = useChart(UseChartType.ENTIRE);

  const {
    setup: setupEntire,
    resize: resizeEntire,
    viewForDistance: viewForWholeDistance,
    reset: resetEntireChart,
    updateSMAWindow: updateSMAWindowEntireStockChart,
  } = entireStockChart;

  const {
    setup: setupMinimap,
    resize: resizeMinimap,
    viewForDistance: viewForMinimap,
    reset: resetMinimap,
    chart: chartMinimap,
  } = useChart(UseChartType.MINIMAP);

  const {startAnimate, updateAnimation, isRunRunning, pause, play, reset: resetAnimation, distance} = useAnimation();

  const {
    playlistItemToPlay: ticker,
    stock,
    ticks,
    isLoaded,
    loadNext,
    reset: resetPlaylistItem,
  } = usePlaylistItemToPlay();

  const startWeb = () => {
    loadNext(require('../../shib-usd-daily.json'));
  };

  const cash = useRef<CashManager>(new CashManager());

  useWebViewMessage((webViewMessage: WebViewWebMessage) => {
    if (webViewMessage.message === WebViewWebMessages.INITIALIZE_RUN) return handleInitializeRun(webViewMessage);
    if (webViewMessage.message === WebViewWebMessages.ON_APP_GOES_BACKGROUND) return handleAppGoesBackground();
  });

  const handleInitializeRun = (message: WebViewWebMessageInitializeRun) => {
    if (message.shouldShowTutorial) startTutorial();

    loadNext(message.playlistItem);
  };

  const handleAppGoesBackground = () => {
    // prevent showing exit dialog when the run is ended
    if (separateRender.isMounted(DIALOGS_IDS.TUTORIAL)) return;
    if (boardStateRef.newsAlertDialogIdRef !== null) return;

    boardStateRef.shouldBePaused = true;
  };

  useEffect(() => {
    if (isRunRunning && boardStateRef.shouldBePaused && cash.current.latest.index >= 1) {
      boardStateRef.shouldBePaused = false;
      pause();
    }
  });

  const onSell = useCallback(
    (tradeItem?: TickerDataPoint) => {
      cash.current.trades = cash.current.trades.map((tr) => {
        if (!tr.isKept) return tr;
        const mutatedTrade = {...tr, isKept: false};

        if (tradeItem) {
          mutatedTrade.trade = [mutatedTrade.trade[0], tradeItem];
        }
        const [buyTrade, sellTrade] = mutatedTrade.trade;
        const cashValue = sellTrade.price * cash.current.sharesHeld - TRADE_COMMISSION;

        if (sellTrade.date.getTime() - buyTrade.date.getTime()) {
          cash.current.changeValue = (sellTrade.price - buyTrade.price) * cash.current.sharesHeld - TRADE_COMMISSION;
        }
        mutateState({cash: cashValue});

        makeSound(cash.current.changeValue > 0 ? WebViewMobileSounds.PROFIT : WebViewMobileSounds.LOSS);

        separateRender.render(
          ({unmount}) => <TradeResultDialog unmount={unmount} profit={cash.current.changeValue} />,
          {containerStyle: {pointerEvents: 'none'}},
        );

        return mutatedTrade;
      });
      cash.current.isCashLiquid = true;
    },
    [mutateState],
  );

  const onBuy = (tradeItem?: TickerDataPoint) => {
    /*
      Somehow cash latest doesn't have enough time to be updated with real data between ending the countdown and starting the run.
      So after fast clicking on chart between those activities it gives the ability to divide the cash by 0 (default price in latest)
     */
    if (!cash.current.latest.date) return;

    makeSound(WebViewMobileSounds.BUY);

    cash.current.sharesHeld = state.cash / cash.current.latest.price;
    const trade = tradeItem || cash.current.latest;
    const tradable: Trades = {
      isKept: true,
      trade: [trade, trade], // second element is "magic", without it TradeLine throws ex in (d.trade[1].price - d.trade[0].price)
    };
    cash.current.trades.push(tradable);
    cash.current.isCashLiquid = false;
  };

  const handleExitRun = () => sendWebViewMessage({message: WebViewMobileMessages.EXIT_RUN});

  useEffect(() => {
    // initialize run
    sendWebViewMessage({message: WebViewMobileMessages.INITIALIZE_RUN});
  }, []);

  const reset = useCallback(() => {
    mutateState({
      runRating: null,
      isEndRunDialogVisible: false,
    });
    resetAnimation();
  }, [mutateState, resetAnimation]);

  const setCountdownVisibility = useCallback(
    (isVisible: boolean) => {
      mutateState({isCountdownVisible: isVisible, isCountdownStarted: isVisible});
    },
    [mutateState],
  );

  const setChartVisibility = useCallback(
    (isVisible: boolean) => {
      mutateState({isChartVisible: isVisible});
    },
    [mutateState],
  );

  const updateCash = useCallback(() => {
    if (chartHistory.length) {
      cash.current.latest = chartHistory[chartHistory.length - 1];
    }

    let pCash = 0;
    if (!cash.current.isCashLiquid) {
      pCash = cash.current.latest.price * cash.current.sharesHeld - TRADE_COMMISSION;
    }

    if (cash.current.trades && cash.current.trades.length) {
      cash.current.trades = cash.current.trades.map((tt) => {
        if (tt.isKept) {
          tt.trade[1] = cash.current.latest;
        }
        return tt;
      });
      cash.current.latestTrade = cash.current.trades.slice(-1).pop()!;
    }

    // TODO: Figure out why we cannot use condition and prevent unnecessary updating
    /**
     * If you try to add condition than prevents unnecessary updating state
     * you will create a bug that doesn't allow you see the end run dialog and the entire stock chart
     * More info: MR (!144) https://gitlab.com/fantasy-mesa/trading-saga/app/-/merge_requests/144
     * */
    mutateState({
      ...(pCash ? {cash: pCash} : {}),
    });
  }, [chartHistory, mutateState]);

  const draw = useCallback(
    (drawDistance: number) => {
      // if (AppState.currentState !== APP_STATES.active) return;
      viewForDistance(drawDistance);
      viewForMinimap(drawDistance);
    },
    [viewForMinimap, viewForDistance],
  );

  const updateLevelCash = useCallback(() => {
    cash.current.sessionCash.push({
      index: ticks[ticks.length - 1].index,
      cash: state.cash,
    });
  }, [state.cash, ticks]);

  const handleEndRun = useCallback(() => {
    if (ticker) {
      sendEvent(TelemetryEvents.RUN_ENDED, {
        ticker: ticker.name,
        newsCount: getNewsCount(ticks),
      });
    }
    onSell();
    updateLevelCash();

    cash.current.tradeReturn =
      (cash.current.sessionCash[1].cash - cash.current.sessionCash[0].cash) / cash.current.sessionCash[0].cash;

    // reset progress line when the session is ending
    if (chart.history.length) {
      cash.current.latest = chart.history[0];
    }

    updateSMAWindowEntireStockChart(chart.SMA.window);

    mutateState({
      isEndRunDialogVisible: true,
      isRunEnded: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.history, mutateState, onSell, ticker, updateLevelCash]);

  // TODO: move it out
  const timePromise = (fn: () => void, time: number) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        fn();
        resolve(time);
      }, time);
    });
  };
  const startRun = useCallback(async () => {
    if (state.isCountdownVisible) return;
    await timePromise(() => setChartVisibility(true), 0);
    await timePromise(() => setCountdownVisibility(true), 1000);
    await timePromise(() => setCountdownVisibility(false), countdownMs);
    await timePromise(() => startAnimate(draw), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isCountdownVisible, setChartVisibility, setCountdownVisibility, startAnimate, draw]);

  const stop = () => reset();

  // for handling resizing and setting up app sizes
  useAppWindowResize(() => {
    const svgWidth = window.innerWidth;
    const svgHeight = window.innerHeight - BOARD_NAV_HEIGHT;
    canvas.current = new Canvas(svgWidth, svgHeight);
    resize(svgWidth, svgHeight);
    resizeMinimap((svgWidth + Y_AXIS_CONTAINER_WIDTH) / MINIMAP_SCALE, (svgHeight - BOARD_NAV_HEIGHT) / MINIMAP_SCALE);
    resizeEntire(svgWidth + appScale(30), svgHeight);
    mutateState({
      height: svgHeight,
      width: svgWidth,
    });
  }, [mutateState, resize, resizeEntire, resizeMinimap]);

  const handleStartTutorialBeforeRun = useCallback(() => {
    setChartVisibility(true);
    const renderTutorialBeforeRun = () =>
      separateRender.render(
        ({unmount}) => (
          <BoardTutorialBeforeRunDialog
            onTapToContinue={() => {
              tutorial.setStep(TutorialSteps.BOARD_SCREEN_TAP_TO_BUY);
              unmount();
              startRun();
            }}
          />
        ),
        {customId: DIALOGS_IDS.TUTORIAL},
      );
    separateRender.render(
      ({unmount}) => (
        <BoardTutorialAboutStockDialog
          onTapToContinue={() => {
            tutorial.setStep(TutorialSteps.BOARD_SCREEN_BEFORE_RUN_BOARD_TUTORIAL);
            unmount();
            renderTutorialBeforeRun();
          }}
        />
      ),
      {customId: DIALOGS_IDS.TUTORIAL},
    );
  }, [setChartVisibility, startRun, tutorial]);

  const handleStartRun = useCallback(() => {
    if (ticker) {
      sendEvent(TelemetryEvents.RUN_STARTED, {
        ticker: ticker.name,
        newsCount: getNewsCount(ticks),
      });
    }
    cash.current.resetProps();
    const timeToRunMs = calcTimeToScrollMs(ticks.length, speed);

    updateLevelCash();
    reset();
    resetMinimap();
    setupEntire(ticks, speed, timeToRunMs);
    setup(ticks, speed, timeToRunMs);
    setupMinimap(ticks, speed, timeToRunMs);
    if (tutorial.isInProgress) return handleStartTutorialBeforeRun();
    startRun();
  }, [
    ticker,
    ticks,
    speed,
    updateLevelCash,
    reset,
    resetMinimap,
    setupEntire,
    setup,
    setupMinimap,
    tutorial.isInProgress,
    handleStartTutorialBeforeRun,
    startRun,
  ]);

  const resetBeforeNextRun = () => {
    resetPlaylistItem();
    boardStateRef.shouldRunAnimationInEndRunDialog = true;
    reset();
    resetChart();
    resetEntireChart();
    cash.current = new CashManager();
    mutateState({
      cash: STARTUP_BALANCE,
    });
  };

  const nextRun = async () => {
    updateSMAWindowStockChart(entireStockChart.chart.SMA.window);
    resetBeforeNextRun();
  };

  const handleNextRunNavbar = () => {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {button: ButtonsNames.BOARD_SCREEN_RUN_COMPLETED_DIALOG_NEXT});
    return nextRun();
  };

  const handleTrade = useThrottle(
    (tradeItem?: TickerDataPoint) => {
      const isCashLiquid = cash.current.isCashLiquid;
      const button = isCashLiquid ? ButtonsNames.BOARD_SCREEN_TRADE_BUY : ButtonsNames.BOARD_SCREEN_TRADE_SELL;
      sendEvent(TelemetryEvents.BUTTON_PRESSED, {button});
      const handler = isCashLiquid ? onBuy : onSell;
      requestAnimationFrame(() => handler(tradeItem));
    },
    250,
    [],
  );

  const handlePressEvent = () => {
    // prevents the user to handlePressEvent when there is a news alert dialog
    if (boardStateRef.newsAlertDialogIdRef) return;

    if (
      tutorial.shouldBeDisabled(TutorialSteps.BOARD_SCREEN_FREE_RUN_BEFORE_NEWS) &&
      tutorial.shouldBeDisabled(TutorialSteps.BOARD_SCREEN_FREE_RUN_AFTER_NEWS)
    )
      return;

    if (isRunEnded && separateRender.isMounted(DIALOGS_IDS.ENTIRE_STOCK_CHART_NEWS_ALERT)) {
      sendEvent(TelemetryEvents.BUTTON_PRESSED, {button: ButtonsNames.BOARD_SCREEN_ENTIRE_CHART_CLOSE_NEWS_ALERT});
      return separateRender.unmount(DIALOGS_IDS.ENTIRE_STOCK_CHART_NEWS_ALERT);
    }

    // Handle screen tap and proceed to buy/sell step
    // the argument may be null/undefined, check code for more details
    if (!isRunEnded) {
      handleTrade(undefined);
    }
  };

  const handleOutsideTouch = () => {
    mutateState({isEndRunDialogVisible: false});
  };

  const calculateSpeed = useCallback(() => {
    const newSpeed = (ticks.length / INITIAL_RUN_DURATION_SEC) * speedRate;
    setSpeed(newSpeed);
  }, [speedRate, ticks.length]);

  useEffect(() => {
    nextRun();

    return () => {
      stop();
      mutateState({isEndRunDialogVisible: false});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mutateState({
      isEndRunDialogVisible: !isLoaded,
      isRunEnded: false,
    });
    if (isLoaded && chart.history?.length && ticker) {
      handleStartRun();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, ticker, chart.history]);

  useEffect(() => {
    if (isLoaded) {
      // handling speed change
      const timeToRunMs = calcTimeToScrollMs(ticks.length, speed);

      setup(ticks, speed, timeToRunMs);
      setupMinimap(ticks, speed, timeToRunMs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, speed, ticks]);

  useEffect(() => {
    if (speed !== chart.speed) updateAnimation(speed || 2);
  }, [draw, chart.speed, speed, updateAnimation]);

  useEffect(() => {
    if (ticks[ticks.length - 1]?.index <= cash.current.latest.index + 1) {
      stop();
      handleEndRun();
      viewForWholeDistance(distance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cash.current.latest, handleEndRun, ticks]);

  useEffect(() => {
    if (state.isRunEnded && stock?.return && stock?.benchmarkReturn) {
      const runRating = calculateRunRating({
        tradeReturn: cash.current.tradeReturn,
        stockReturn: stock?.return,
        indexReturn: stock?.benchmarkReturn,
      });

      makeSound(WebViewMobileSounds.END_RUN);

      sendWebViewMessage({
        message: WebViewMobileMessages.FINISH_RUN,
        playlistItemId: ticker.playlistItemId,
        runRating,
        tradeReturn: cash.current.tradeReturn,
        benchmarkReturn: stock.benchmarkReturn,
      });
      mutateState({runRating});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isRunEnded, ticker, stock, mutateState]);

  useEffect(() => {
    calculateSpeed();
  }, [calculateSpeed, speedRate, ticks.length]);

  useEffect(() => {
    updateCash();
  }, [chartHistory, updateCash]);

  // Hook for Tutorial tooltips (onBuy, onSell, profit, progress)
  useBoardTutorialTooltips({cash, sell: onSell, buy: onBuy, play, pause});

  if (!stock)
    return (
      <div style={styles.startContainer}>
        {!(window as any)?.ReactNativeWebView ? (
          <IconButton size={100} src={PLAY_NEXT_ICON} onClick={startWeb} />
        ) : (
          <span>LOADING....</span>
        )}
      </div>
    );

  const {name, label} = stock;
  const tickerName = `${name} | ${label}`;
  const {width} = state;
  const progressLine = {width, history: ticks, latestIndex: cash.current.latest.index};
  const isRunStarted = cash.current.latest.index !== 0;

  return (
    <div style={styles.board}>
      <NavigationHeader
        state={state}
        mutateState={mutateState}
        onNext={handleNextRunNavbar}
        tickerName={tickerName}
        ticker={ticker}
        progressLine={progressLine}
        pause={pause}
        play={play}
        isRunRunning={isRunRunning}
        isRunStarted={isRunStarted}
        boardStateRef={boardStateRef}
      />
      <div onClick={handlePressEvent} style={styles.container}>
        {state.isCountdownVisible && !state.isRunEnded && (
          <Countdown countdownMs={countdownMs} isCounting={state.isCountdownStarted} />
        )}
        {state.isChartVisible && (
          <div style={{width: canvas.current?.getWidth(), height: canvas.current?.getHeight()}}>
            {isRunEnded && (
              <EntireStockChart
                updateSMAWindow={updateSMAWindowEntireStockChart}
                ticks={ticks}
                canvas={canvas!.current!}
                chart={entireStockChart}
                trades={cash.current.trades}
              />
            )}
            {!state.isRunEnded && (
              <StockChart
                isRunStarted={isRunStarted}
                setSpeedRate={setSpeedRate}
                speedRate={speedRate}
                updateSMAWindow={updateSMAWindowStockChart}
                boardStateRef={boardStateRef}
                handleBuyOrSellTrade={handleTrade}
                play={play}
                pause={pause}
                canvas={canvas!.current!}
                chart={chart}
                trades={cash.current.trades}
                latestTrade={cash.current.latestTrade}
                latest={cash.current.latest}
                isRunEnded={state.isRunEnded}
                chartMinimap={chartMinimap}
              />
            )}
          </div>
        )}
      </div>
      {state.isEndRunDialogVisible && state.runRating && ticker && (
        <EndRunDialog
          playlistItemId={ticker.playlistItemId}
          boardStateRef={boardStateRef}
          onClose={handleOutsideTouch}
          initialCash={state.initialCash}
          gameCash={state.cash}
          tradeReturn={cash.current.tradeReturn}
          stockReturn={stock.return as number}
          indexReturn={stock.benchmarkReturn as number}
          tickerRating={ticker.rating}
          runRating={state.runRating}
          tickerName={ticker.name}
        />
      )}
      {!tutorial.isInProgress &&
        isRunStarted &&
        !boardStateRef?.newsAlertDialogIdRef &&
        !state.isCountdownVisible &&
        !state.isRunEnded &&
        !isRunRunning && <PauseDialog onResume={play} onExit={handleExitRun} />}
      <EndRunSoundEffects
        speedRate={speedRate}
        currentTickIndex={cash.current.latest.index}
        tickerName={tickerName}
        maxTickIndex={ticks.length - 1}
      />
    </div>
  );
};

const styles: Record<'container' | 'board' | 'startContainer', CSSProperties> = {
  container: {
    backgroundColor: '#000000',
    width: '100%',
    height: '100%',
    position: 'relative',
    background: 'radial-gradient(circle at top, rgba(102,125,96,0.9) 0%, rgba(0,0,0,1) 60%, rgba(0,0,0,1) 100%)',
  },
  board: {
    backgroundColor: '#000000',
    position: 'relative',
  },
  startContainer: {
    background: 'radial-gradient(circle at top, rgba(102,125,96,0.9) 0%, rgba(0,0,0,1) 60%, rgba(0,0,0,1) 100%)',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default Board;
