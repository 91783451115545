/**
|--------------------------------------------------
| APPLICATION HELPERS UTIL
|--------------------------------------------------
*/

import * as d3 from 'd3';
import moment from 'moment';
import {IXTicks} from '../../../types/UseChartTypes';

const formatMillisecond = d3.timeFormat('.%L');
const formatSecond = d3.timeFormat(':%S');
const formatMinute = d3.timeFormat('%I:%M');
const formatHour = d3.timeFormat('%I %p');
const formatDay = d3.timeFormat('%a %d');
const formatWeek = d3.timeFormat('%b %d');
const formatMonth = d3.timeFormat('%b');
const formatYear = d3.timeFormat('%Y');

export interface ITicksWithDate extends Omit<IXTicks, 'date'> {
  period: string;
  date: string;
}

export const getTicksWithDates = (ticks: IXTicks[]): ITicksWithDate[] => {
  return ticks.reduce((acc, tick, index) => {
    const currentDate = tick.date;
    const modifiedTick: ITicksWithDate = {...tick, date: moment(tick.date).format('DD'), period: 'day'};

    if (index) {
      const prevDate = ticks[index - 1].date;

      if (!moment(prevDate).isSame(currentDate, 'month')) {
        modifiedTick.period = 'month';
        modifiedTick.date = moment(currentDate).format('MMM');
        if (!moment(prevDate).isSame(currentDate, 'year')) {
          modifiedTick.period = 'year';
          modifiedTick.date = moment(currentDate).format('YYYY');
        }
      }
      acc.push(modifiedTick);
    }

    return acc;
  }, [] as ITicksWithDate[]);
};

export const getYear = (date: Date) => moment(date).format('YYYY') as string;

export const mFormat = (date: Date) => {
  return (
    d3.timeSecond(date) < date
      ? formatMillisecond
      : d3.timeMinute(date) < date
      ? formatSecond
      : d3.timeHour(date) < date
      ? formatMinute
      : d3.timeDay(date) < date
      ? formatHour
      : d3.timeMonth(date) < date
      ? d3.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
};

export const format = (amount: number, fmtText: string) => {
  return d3.format(fmtText)(amount);
};

export const dollarFormat = (amount: number) => {
  return d3.format('$,.0f')(amount);
};

export const percentFormat = (amount: number) => {
  return d3.format('.1%')(amount);
};

export const percentFormat1 = (amount: number) => {
  return d3.format('+,.1%')(amount);
};

export const timerFormat = (t: number) => {
  t += 900;
  const duration = moment.duration(t);
  const minAmountOfComponentsToShow = 2;
  const timeComponents = [Math.floor(duration.asHours()), duration.minutes(), duration.seconds()];
  let strComponents: string[] = [];
  for (let i = 0; i < timeComponents.length; i++) {
    const val = timeComponents[i];
    const mustShow = i >= timeComponents.length - minAmountOfComponentsToShow;
    if (!strComponents.length && !val && !mustShow) continue;
    strComponents.push(val.toString().padStart(2, '0'));
  }

  return strComponents.join(':');
};

export const countFormat = (t: number) => {
  const format = d3.format('2');
  return `${format(Math.abs(Math.ceil(t / 1000) % 60))}`;
};
