import amplitude, {AmplitudeClient} from 'amplitude-js';
import {initializeApp} from 'firebase/app';
import {Analytics, getAnalytics, logEvent} from 'firebase/analytics';
import {IS_DEV_MODE} from '../config/constants';

const firebaseConfig = {
  apiKey: 'AIzaSyD9Lk_OyojVGORMJT9sB03jqEow6TWrHKI',
  authDomain: 'tradingsaga-app.firebaseapp.com',
  databaseURL: 'https://tradingsaga-app.firebaseio.com',
  projectId: 'tradingsaga-app',
  storageBucket: 'tradingsaga-app.appspot.com',
  messagingSenderId: '55556061635',
  appId: '1:55556061635:web:f422da55316a02282da576',
  measurementId: 'G-YDJDMDYQLZ',
};

const AMPLITUDE_API_KEY = '6bac7583412f74e1caf1e402c22e223f';

let analyticsInst: Analytics | null = null;
let amplitudeInst: AmplitudeClient | null = null;

export const initTelemetry = () => {
  if (IS_DEV_MODE) {
    return;
  }
  const app = initializeApp(firebaseConfig);
  analyticsInst = getAnalytics(app);
  amplitudeInst = amplitude.getInstance();
  amplitudeInst.init(AMPLITUDE_API_KEY);
};

export const sendEvent = <T>(eventName: string, data?: T) => {
  const unified_event_name = `ts_${eventName.toLowerCase()}`;

  if (amplitudeInst) {
    amplitudeInst.logEvent(unified_event_name, data);
  }

  if (analyticsInst) {
    logEvent(analyticsInst!, unified_event_name, data);
  }
};

export const TelemetryEvents = {
  RUN_STARTED: 'RUN_STARTED',
  RUN_ENDED: 'RUN_ENDED',
  BUTTON_PRESSED: 'BUTTON_PRESSED',
};
