import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import DIALOGS_IDS from '../config/dialogsIds';
import separateRender from '../util/separate-render';

export enum TutorialSteps {
  BEFORE_TUTORIAL = 'BEFORE_TUTORIAL',
  BOARD_SCREEN_BEFORE_RUN_MESSAGE_ABOUT_STOCK = 'BOARD_SCREEN_BEFORE_RUN_MESSAGE_ABOUT_STOCK',
  BOARD_SCREEN_BEFORE_RUN_BOARD_TUTORIAL = 'BOARD_SCREEN_BEFORE_RUN_BOARD_TUTORIAL',
  BOARD_SCREEN_TAP_TO_BUY = 'BOARD_SCREEN_TAP_TO_BUY',
  BOARD_SCREEN_TAP_TO_SELL = 'BOARD_SCREEN_TAP_TO_SELL',
  BOARD_SCREEN_PROFIT = 'BOARD_SCREEN_PROFIT',
  BOARD_SCREEN_PROGRESS = 'BOARD_SCREEN_PROGRESS',
  BOARD_SCREEN_FREE_RUN_BEFORE_NEWS = 'BOARD_SCREEN_FREE_RUN_BEFORE_NEWS',
  BOARD_SCREEN_FREE_RUN_AFTER_NEWS = 'BOARD_SCREEN_FREE_RUN_AFTER_NEWS',
  BOARD_SCREEN_END_RUN_DIALOG = 'BOARD_SCREEN_END_RUN_DIALOG',
  BOARD_SCREEN_ENTIRE_STOCK_CHART_OVERVIEW = 'BOARD_SCREEN_ENTIRE_STOCK_CHART_OVERVIEW',
  BOARD_SCREEN_ENTIRE_STOCK_CHART_BUTTONS_OVERVIEW = 'BOARD_SCREEN_ENTIRE_STOCK_CHART_BUTTONS_OVERVIEW',
}

const TUTORIAL_STEP_PAUSE_MS = 1000;

export interface TutorialContextProps {
  isDone: boolean;
  setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
  step: TutorialSteps;
  setStep: (newStep: TutorialSteps) => void;
  isInProgress: boolean;
  shouldBeDisabled: (providedStep: TutorialSteps) => boolean;
  shouldHideTooltip: (providedSte: TutorialSteps) => boolean;
  shouldShowTooltip: (providedSte: TutorialSteps) => boolean;
}

const TutorialContext = createContext<TutorialContextProps>(null!);

export const TutorialProvider: React.FC = ({children}) => {
  const [isDone, setIsDone] = useState(true);
  const [step, setStep] = useState<TutorialSteps>(TutorialSteps.BEFORE_TUTORIAL);

  const shouldBeDisabled: TutorialContextProps['shouldBeDisabled'] = useCallback(
    (providedStep) => !isDone && step !== providedStep && step !== TutorialSteps.BEFORE_TUTORIAL,
    [isDone, step],
  );
  const shouldHideTooltip: TutorialContextProps['shouldHideTooltip'] = useCallback(
    (providedStep) => isDone || step !== providedStep,
    [isDone, step],
  );
  const shouldShowTooltip: TutorialContextProps['shouldShowTooltip'] = useCallback(
    (providedStep) => !isDone && step === providedStep,
    [isDone, step],
  );

  const value: TutorialContextProps = useMemo(
    () => ({
      isDone: isDone,
      isInProgress: !isDone && step !== TutorialSteps.BEFORE_TUTORIAL,
      setIsDone,
      step,
      setStep,
      shouldBeDisabled,
      shouldHideTooltip,
      shouldShowTooltip,
    }),
    [shouldBeDisabled, shouldHideTooltip, shouldShowTooltip, isDone, setIsDone, step, setStep],
  );

  return <TutorialContext.Provider value={value}>{children}</TutorialContext.Provider>;
};

export const useTutorial = () => {
  const value = useContext(TutorialContext);
  if (!value) throw 'useTutorial must be used inside TutorialProvider';
  return value;
};

export const useTutorialNextStepDisabled = () => {
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(true);

  useEffect(() => {
    next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    setIsNextStepDisabled(true);
    setTimeout(() => setIsNextStepDisabled(false), TUTORIAL_STEP_PAUSE_MS);
  };

  return {
    disabled: isNextStepDisabled,
    next,
  };
};

export const useFinishTutorial = () => {
  const tutorial = useTutorial();

  return () => {
    if (separateRender.isMounted(DIALOGS_IDS.TUTORIAL)) separateRender.unmount(DIALOGS_IDS.TUTORIAL);
    tutorial.setStep(TutorialSteps.BEFORE_TUTORIAL);
    tutorial.setIsDone(true);
  };
};

export const useStartTutorial = () => {
  const tutorial = useTutorial();

  return () => {
    separateRender.unmount(DIALOGS_IDS.TUTORIAL);
    tutorial.setStep(TutorialSteps.BOARD_SCREEN_BEFORE_RUN_MESSAGE_ABOUT_STOCK);
    tutorial.setIsDone(false);

    // TODO: Send to RN that tutorial for board is finished!
  };
};
