import {useCallback, useEffect, useRef} from 'react';
import throttle from 'lodash.throttle';
// lodash options
const OPTIONS = {leading: true, trailing: false};

const useThrottle = <T>(cb: (data?: T) => void, delay: number, deps: T[] = []) => {
  const cbRef = useRef(cb);

  useEffect(() => {
    cbRef.current = cb;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((data) => cbRef.current(data), delay, OPTIONS),
    [...deps, delay],
  );
};

export default useThrottle;
