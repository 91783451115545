import React, {CSSProperties} from 'react';

import {TutorialSteps, useTutorial} from '../../../../contexts/TutorialContext';
import TutorialContent from '../../../../components/Tutorial/TutorialContent';
import TutorialTriangle from '../../../../components/Tutorial/TutorialTriangle';
import TutorialText from '../../../../components/Tutorial/TutorialText';
import COLORS from '../../../../config/colors';
import appScale from '../../../../util/appScale';
import {RobotoRegular} from '../../../../config/fonts';

interface NavigationHeaderNextButtonProps {
  onClick: () => void;
  disabled?: boolean;
}
const NavigationHeaderNextButton: React.FC<NavigationHeaderNextButtonProps> = ({disabled, onClick}) => {
  const tutorial = useTutorial();

  return (
    <div style={containerStyles}>
      {tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_ENTIRE_STOCK_CHART_BUTTONS_OVERVIEW) && (
        <TutorialContent style={tutorialContainerStyles}>
          <TutorialTriangle style={tutorialTriangleStyles} />
          <TutorialText>To play next ticker.</TutorialText>
        </TutorialContent>
      )}
      <button disabled={disabled} style={nextButtonStyles} onClick={onClick}>
        Next
      </button>
    </div>
  );
};

export default React.memo(NavigationHeaderNextButton);

const containerStyles: CSSProperties = {
  position: 'relative',
  height: '100%',
};

const tutorialContainerStyles: CSSProperties = {
  position: 'absolute',
  zIndex: 1,
  top: appScale(1),
  width: appScale(66.5),
  left: appScale(-73),
};

const tutorialTriangleStyles: CSSProperties = {
  position: 'absolute',
  right: appScale(-6.35),
  top: appScale(0.68),
  transform: `rotate(90deg)`,
};

const nextButtonStyles: CSSProperties = {
  display: 'block',
  width: appScale(32),
  height: '100%',
  maxHeight: '100%',
  justifyContent: 'center',
  backgroundColor: COLORS.CAMARONE.default,
  border: 'none',
  alignItems: 'center',
  fontWeight: 'normal',
  letterSpacing: 0,
  textDecorationLine: 'none',
  color: COLORS.WHITE.default,
  fontFamily: RobotoRegular,
  fontSize: appScale(4),
  outline: 'none',
};
