import React, {useEffect, useState} from 'react';

import {TickerDataPointNews} from '../../Utils/ticker-utils';
import NewsAlertDialogActions from './NewsAlertDialogComponents/NewsAlertDialogActions';
import NewsAlertDialogNews from './NewsAlertDialogComponents/NewsAlertDialogNews';
import makeSound from '../../../../util/makeSound';
import {WebViewMobileSounds} from '../../boardTypes';
import appScale from '../../../../util/appScale';
import NewsAlertDialogTutorialTooltip from './NewsAlertDialogComponents/NewsAlertDialogTutorialTooltip';
import {TutorialContextProps, TutorialSteps} from '../../../../contexts/TutorialContext';

export interface NewsAlertProps {
  onContinuePlay?: () => void;
  onBuySell?: () => void;
  news: TickerDataPointNews;
  isRunEnded: boolean;
  tutorial?: TutorialContextProps;
}

const NewsAlertDialog: React.FC<NewsAlertProps> = ({news, onContinuePlay, onBuySell, isRunEnded, tutorial}) => {
  const [shouldShowTutorial, setShouldShowTutorial] = useState(
    tutorial?.step === TutorialSteps.BOARD_SCREEN_FREE_RUN_BEFORE_NEWS || false,
  );
  const handleFinishTutorial = () => {
    tutorial?.setStep(TutorialSteps.BOARD_SCREEN_FREE_RUN_AFTER_NEWS);
    setShouldShowTutorial(false);
  };
  useEffect(() => {
    if (isRunEnded) return;
    makeSound(WebViewMobileSounds.NEWS_ALERT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialogHeight = {height: isRunEnded ? 'auto' : `calc(100% - ${appScale(13)}px)`};

  return (
    <div style={styles.mainWrapper}>
      {tutorial?.isInProgress && shouldShowTutorial && (
        <NewsAlertDialogTutorialTooltip onTapToContinue={handleFinishTutorial} />
      )}
      <div style={{...styles.dialogContainer, ...dialogHeight}}>
        <NewsAlertDialogNews news={news} />

        <NewsAlertDialogActions
          isTutorialMode={shouldShowTutorial}
          isHidden={isRunEnded}
          onContinuePlay={onContinuePlay}
          onBuySell={onBuySell}
        />
      </div>
    </div>
  );
};

export default NewsAlertDialog;

const styles = {
  mainWrapper: {
    zIndex: 200,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'stretch',
    pointerEvents: 'none',
    maxHeight: '100%',
  } as React.CSSProperties,
  dialogContainer: {
    paddingTop: appScale(13),
    paddingLeft: appScale(12),
    paddingRight: appScale(21.5),
    paddingBottom: appScale(6.5),
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  } as React.CSSProperties,
};
