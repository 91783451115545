import React, {useEffect} from 'react';
import {useTutorialNextStepDisabled} from '../../../../../contexts/TutorialContext';
import TutorialTapToContinueDialog from '../../../../Tutorial/TutorialTapToContinueDialog';
import {TUTORIAL_COMMON_STYLES} from '../../../../Tutorial/TUTORIAL_CONFIG';

export interface NewsAlertDialogTutorialTooltipProps {
  onTapToContinue: () => void;
}

const NewsAlertDialogTutorialTooltip: React.FC<NewsAlertDialogTutorialTooltipProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();

  useEffect(() => {
    tutorialNextStepDisabled.next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TutorialTapToContinueDialog
      containerStyle={{...TUTORIAL_COMMON_STYLES.boardDialogContainer, pointerEvents: 'auto'}}
      contentStyle={TUTORIAL_COMMON_STYLES.boardDialogContent}
      disabled={tutorialNextStepDisabled.disabled}
      onTapToContinue={onTapToContinue}
      text={
        <div>
          News alert may appear to provide {'\n'}
          information, thus helping you to make more {'\n'}
          informed decision
        </div>
      }
    />
  );
};

export default NewsAlertDialogTutorialTooltip;
