import React, {CSSProperties} from 'react';
import {RobotoRegular} from '../../config/fonts';
import COLORS from '../../config/colors';
import appScale from '../../util/appScale';

interface DialogTitleProps {
  title: string | HTMLElement;
}

const DialogTitle: React.FC<DialogTitleProps> = ({title}) => <div style={textStyles}>{title}</div>;

export default DialogTitle;

const textStyles: CSSProperties = {
  fontSize: appScale(8),
  textAlign: 'center',
  verticalAlign: 'center',
  color: COLORS.WHITE.default,
  fontFamily: RobotoRegular,
  padding: appScale(2),
};
