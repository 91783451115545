import React, {CSSProperties} from 'react';
import IconButton from '../common/IconButton';
import {X_ICON} from '../../assets/icons';
import appScale from '../../util/appScale';

interface DialogCloseIconButtonProps {
  onPress?: () => void;
  containerStyle?: CSSProperties;
}
const ICON_SIZE = appScale(8);

const DialogCloseIconButton: React.FC<DialogCloseIconButtonProps> = ({onPress = () => {}, containerStyle}) => (
  <div style={{...styles.container, ...containerStyle}}>
    <IconButton src={X_ICON} onClick={onPress} size={ICON_SIZE} />
  </div>
);

export default DialogCloseIconButton;

const styles = {
  container: {
    position: 'absolute',
    right: 6,
    top: 6,
    zIndex: 10,
  } as CSSProperties,
};
