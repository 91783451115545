export class Canvas {
  private readonly scale: number;
  private readonly width: number;
  private readonly height: number;
  private readonly viewHeight: number;
  private readonly viewWidth: number;

  constructor(width: number, height: number, scale: number = 1) {
    this.width = width;
    this.height = height;
    this.scale = scale;

    this.viewWidth = width * this.scale;
    this.viewHeight = height * this.scale;
  }

  public getHeight(): number {
    return this.viewHeight;
  }

  public getWidth(): number {
    return this.viewWidth;
  }
}
