import React from 'react';

import {Option} from './BoardSwitcher';
import {MINIMAP_OFF_ICON, MINIMAP_ON_ICON} from '../../../assets/icons';

export interface MinimapSwitcherProps {
  onClick: () => void;
  isMinimapShown: boolean;
}

const MinimapSwitcher: React.FC<MinimapSwitcherProps> = ({onClick, isMinimapShown}) => {
  return (
    <div>
      <Option src={isMinimapShown ? MINIMAP_ON_ICON : MINIMAP_OFF_ICON} onClick={onClick} />
    </div>
  );
};

export default MinimapSwitcher;
