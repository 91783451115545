import {WebViewWebMessage} from '../components/board/boardTypes';
import useEventListener from '@use-it/event-listener';

const useWebViewMessage = (onMessage: (data: WebViewWebMessage) => void) => {
  const handleOnMessage = (event: MessageEvent) => {
    if (!event) return;
    if (!event.data) return;
    if (typeof event.data !== 'string') return;
    const data = JSON.parse(event.data);
    if (data && typeof data.message !== 'string') return;
    onMessage(data);
  };

  // for iOS
  useEventListener('message', handleOnMessage, window);
  // for Android
  useEventListener('message', handleOnMessage, document);
};

export default useWebViewMessage;
