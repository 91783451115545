import {TickerDataPoint} from '../components/board/Utils/ticker-utils';
import {SimpleTickerPoint} from '../types/UseChartTypes';
import simplify from 'simplify-js';

export const createXTicks = (ticks: number[], history: TickerDataPoint[]) =>
  ticks.map((i) => {
    if (i >= 0) return {date: history[i]?.date, i};

    const diffMs: number = +history[1]?.date - +history[0]?.date;
    const shiftedDate = new Date(+history[0].date + diffMs * i);
    return {date: shiftedDate, i};
  });

export const makeChartSimple = (history: TickerDataPoint[]): TickerDataPoint[] => {
  const historyToPoints: SimpleTickerPoint[] = history.map((item) => ({x: item.index, y: item.price, ...item}));
  return simplify(historyToPoints, 0.6, false) as SimpleTickerPoint[];
};

export const getDefaultSMA = () => ({
  window: 0,
  list: [],
});

export const getNewsCount = (history: TickerDataPoint[]): number =>
  history.reduce((prev, current) => (current.news ? prev + 1 : prev), 0);
