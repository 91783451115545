import React from 'react';

import EndRunDialogActions from './components/EndRunDialogActions';
import EndRunDialogHeader from './components/EndRunDialogHeader';
import EndRunDialogResultsList from './components/EndRunDialogResultsList';
import EndRunDialogNewBest from './components/EndRunDialogNewBest';
import EndRunDialogCloseButton from './components/EndRunDialogCloseButton';
import EndRunDialogShare from './components/EndRunDialogShare';

import {dollarFormat} from '../../Utils/utils';

import EndRunDialogMedal from './components/EndRunDialogMedal';
import {getRatingIconSource} from '../../../../util/playlistRating';
import {BoardStateRef} from '../../../../hooks/useBoardStateRef';
import {BOARD_NAV_HEIGHT} from '../../constants';
import DialogBackground from '../../../dialog/DialogBackground';
import {WebViewMobileMessages} from '../../boardTypes';
import sendWebViewMessage from '../../Utils/sendWebViewMessage';
import {Routes} from '../../../../config/routes';
import appScale from '../../../../util/appScale';
import EndRunDialogTutorial from './components/EndRunDialogTutorial';
import {TutorialSteps, useTutorial} from '../../../../contexts/TutorialContext';
import {TelemetryEvents, sendEvent} from '../../../../util/telemetry';
import ButtonsNames from '../../../../config/buttonsNames';

interface EndRunDialogProps {
  tickerName: string;
  playlistItemId: string;
  initialCash: number;
  tradeReturn: number;
  stockReturn: number;
  indexReturn: number;
  gameCash: number;
  onClose: () => void;
  tickerRating?: any;
  runRating: any;
  boardStateRef: BoardStateRef;
}

const EndRunDialog: React.FC<EndRunDialogProps> = (props) => {
  const {
    onClose,
    indexReturn,
    initialCash,
    stockReturn,
    gameCash,
    tradeReturn,
    tickerName,

    runRating,
    tickerRating,
    playlistItemId,
    boardStateRef,
  } = props;
  const tutorial = useTutorial();
  const medalSrc = getRatingIconSource(runRating.points);

  const handleCloseDialog = () => {
    if (tutorial.shouldBeDisabled(TutorialSteps.BOARD_SCREEN_END_RUN_DIALOG)) return;
    if (tutorial.isInProgress) tutorial.setStep(TutorialSteps.BOARD_SCREEN_ENTIRE_STOCK_CHART_OVERVIEW);
    onClose();
  };
  const handleShareRunResults = async () => {
    const profit = gameCash - initialCash;
    let shareMessage: string;
    if (profit > 0) {
      shareMessage = `I just made ${dollarFormat(
        profit,
      )} trading ${tickerName} in Trading Saga, beating the stock market.\nTrade for yourself.`;
    } else {
      shareMessage = `I just lost ${dollarFormat(
        Math.abs(profit),
      )} trading ${tickerName} in Trading Saga.\nTrade for yourself.`;
    }

    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.BOARD_SCREEN_RUN_COMPLETED_DIALOG_SHARE,
      profit: dollarFormat(profit),
      tickerName,
    });
    sendWebViewMessage({message: WebViewMobileMessages.SHARE_MESSAGE, shareMessage});
  };

  const handleNavigateToPlaylist = () =>
    sendWebViewMessage({message: WebViewMobileMessages.NAVIGATE_TO, route: Routes.PLAYLIST});

  const handleReplay = () => sendWebViewMessage({message: WebViewMobileMessages.REPLAY_RUN, playlistItemId});

  const handleNextRun = () => sendWebViewMessage({message: WebViewMobileMessages.NEXT_RUN});
  return (
    <div style={styles.container} className="animation-fade-in">
      <DialogBackground backgroundColor="rgba(0, 0, 0, 0.4)" onClick={handleCloseDialog} />
      <div style={styles.contentContainer}>
        <EndRunDialogCloseButton onClick={handleCloseDialog} />
        <EndRunDialogShare onClick={handleShareRunResults} />
        <EndRunDialogHeader />
        <EndRunDialogNewBest tickerName={tickerName} runRating={runRating} tickerRating={tickerRating} />
        <EndRunDialogMedal medalSrc={medalSrc} boardStateRef={boardStateRef} />
        <EndRunDialogResultsList
          runRating={runRating}
          indexReturn={indexReturn}
          initialCash={initialCash}
          stockReturn={stockReturn}
          gameCash={gameCash}
          tradeReturn={tradeReturn}
        />
      </div>

      <EndRunDialogActions onPlay={handleNextRun} onPlaylist={handleNavigateToPlaylist} onReplay={handleReplay} />

      <EndRunDialogTutorial />
    </div>
  );
};

export default EndRunDialog;

const styles = {
  container: {
    zIndex: 1300,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    marginTop: BOARD_NAV_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
  },
  contentContainer: {
    position: 'relative',
    marginTop: appScale(2),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: appScale(3),
    backgroundColor: 'rgba(13, 20, 11, 0.6)',
    width: appScale(148),
    padding: appScale(3.25),
    zIndex: 1301,
    boxShadow: 'inset 0px 0px 0px 1px #3d8535',
  },
} as const;
