import React, {CSSProperties} from 'react';
import IconButton from '../../../../../components/common/IconButton';
import {X_ICON} from '../../../../../assets/icons';
import appScale from '../../../../../util/appScale';
import {TutorialSteps, useTutorial} from '../../../../../contexts/TutorialContext';
import TutorialContent from '../../../../Tutorial/TutorialContent';
import TutorialTriangle from '../../../../Tutorial/TutorialTriangle';
import TutorialText from '../../../../Tutorial/TutorialText';

const ICON_SIZE = appScale(8.6);

export interface EndRunDialogCloseButtonProps {
  onClick: () => void;
}

const EndRunDialogCloseButton: React.FC<EndRunDialogCloseButtonProps> = ({onClick}) => {
  const tutorial = useTutorial();
  return (
    <div style={styles.container}>
      {tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_END_RUN_DIALOG) && (
        <TutorialContent isAction style={styles.tutorialContainer}>
          <TutorialTriangle isAction style={styles.tutorialTriangle} />
          <TutorialText>
            Press to close the dialog{'\n'}
            (You can press its backdrop to close too)
          </TutorialText>
        </TutorialContent>
      )}
      <IconButton size={ICON_SIZE} src={X_ICON} onClick={onClick} />
    </div>
  );
};

export default EndRunDialogCloseButton;

const styles: Record<any, CSSProperties> = {
  container: {
    position: 'absolute',
    right: appScale(3),
    top: appScale(3),
    zIndex: 10,
  },
  tutorialContainer: {
    position: 'absolute',
    zIndex: 1,
    width: appScale(125),
    left: appScale(-130),
    top: appScale(0),
  },
  tutorialTriangle: {
    position: 'absolute',
    right: appScale(-6.5),
    top: appScale(1.5),
    transform: `rotate(90deg)`,
  },
};
