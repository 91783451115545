import {APP_SCALE_FACTOR} from '../config/constants';
import {getPlatform, PLATFORMS} from './platform';

const width = window.screen.availWidth;
const height = window.screen.availHeight;

const [shortDimension, longDimension] = width < height ? [width, height] : [height, width];

//Default guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

export const scale = (size: number) => (shortDimension / guidelineBaseWidth) * size;
export const verticalScale = (size: number) => (longDimension / guidelineBaseHeight) * size;
export const moderateScale = (size: number, factor = 0.5) => size + (scale(size) - size) * factor;
export const moderateVerticalScale = (size: number, factor = 0.5) => size + (verticalScale(size) - size) * factor;

export const s = scale;
export const vs = verticalScale;
export const ms = moderateScale;
export const mvs = moderateVerticalScale;

const DEVICE_DEFAULT_RATIO = 2.9;
const platform = getPlatform();
const pixelRatio = platform === PLATFORMS.IOS ? devicePixelRatio : DEVICE_DEFAULT_RATIO;
const appScale = (size: number) => ms(size, APP_SCALE_FACTOR) * (pixelRatio ?? DEVICE_DEFAULT_RATIO);

export default appScale;
