import React, {CSSProperties} from 'react';

import TutorialDialog from './TutorialDialog';
import TutorialText, {TutorialTextProps} from './TutorialText';
import COLORS from '../../config/colors';
import makeSound from '../../util/makeSound';
import {WebViewMobileSounds} from '../board/boardTypes';
import appScale from '../../util/appScale';

export interface TutorialTapToContinueDialogProps {
  onTapToContinue: () => void;
  text?: TutorialTextProps['children'];
  contentStyle?: CSSProperties;
  disabled?: boolean;
  containerStyle?: CSSProperties;
}

const TutorialTapToContinueDialog: React.FC<TutorialTapToContinueDialogProps> = (props) => {
  const {containerStyle, contentStyle, text, onTapToContinue, disabled} = props;
  const contentStyles = {...styles.content, ...(!text && styles.contentWithoutText), ...contentStyle};

  const handleTapToContinue = () => {
    if (disabled) return;
    makeSound(WebViewMobileSounds.TAP);
    onTapToContinue();
  };

  return (
    <TutorialDialog
      containerStyle={containerStyle}
      contentStyle={contentStyles}
      onBackgroundPress={handleTapToContinue}
    >
      <div style={styles.container}>
        {text && (
          <div style={styles.textContainer}>
            <TutorialText>{text}</TutorialText>
          </div>
        )}
        <div style={styles.buttonContainer}>
          <TutorialText isAction onClick={handleTapToContinue}>
            Tap to Continue
          </TutorialText>
        </div>
      </div>
    </TutorialDialog>
  );
};

export default TutorialTapToContinueDialog;

const styles: Record<any, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    marginBottom: appScale(5.25),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    borderColor: COLORS.CAMARONE.default,
    borderWidth: appScale(0.25),
  },
  contentWithoutText: {
    minWidth: appScale(46),
  },
};
