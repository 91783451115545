import React, {useState} from 'react';
import {BOARD_NAV_HEIGHT} from '../constants';
import DialogBackground from '../../dialog/DialogBackground';
import appScale from '../../../util/appScale';
import {RobotoRegular} from '../../../config/fonts';
import DialogButton from './components/DialogButton';
import IconButton from '../../common/IconButton';
import {X_ICON} from '../../../assets/icons';
import {TelemetryEvents, sendEvent} from '../../../util/telemetry';
import ButtonsNames from '../../../config/buttonsNames';
import COLORS from '../../../config/colors';

const ICON_SIZE = appScale(15);

interface EndRunDialogProps {
  onResume: () => void;
  onExit: () => void;
}

const PauseDialog: React.FC<EndRunDialogProps> = ({onResume, onExit}) => {
  const [isDialogShowed, setIsDialogShowed] = useState(true);

  const handleResume = () => {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.PAUSE_DIALOG_RESUME,
    });
    onResume();
  };

  const handleExit = () => {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.PAUSE_DIALOG_EXIT,
    });
    onExit();
  };

  const handleExitRun = () => {
    handleExit();
  };

  return (
    <div style={styles.container} className="animation-fade-in">
      <DialogBackground backgroundColor="rgba(0, 0, 0, 0.4)" />
      {isDialogShowed && (
        <div style={styles.contentContainer}>
          <h2 style={styles.title}>Game paused</h2>
          <p style={styles.text}>
            {'Tip: To earn a gold medal, you need to beat \n both "S&P" and "Buy&Hold" benchmarks!'}
          </p>
          <div style={styles.buttonContainer}>
            <DialogButton onClick={handleResume} label="Resume" />
            <DialogButton
              onClick={handleExitRun}
              label="Quit run"
              background={'rgba(22,248,0,0.4)'}
              customStyles={styles.exitButton}
            />
          </div>
          <IconButton size={ICON_SIZE} src={X_ICON} onClick={handleResume} style={styles.closeButton} />
        </div>
      )}
    </div>
  );
};

export default PauseDialog;

const styles = {
  container: {
    zIndex: 1300,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    marginTop: BOARD_NAV_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
  },
  contentContainer: {
    position: 'relative',
    marginTop: appScale(2),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: appScale(3),
    backgroundColor: 'rgba(13, 20, 11, 0.6)',
    width: appScale(148),
    padding: `${appScale(3)}px ${appScale(10)}px`,
    zIndex: 1301,
    boxShadow: 'inset 0px 0px 0px 1px #3d8535',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    fontFamily: RobotoRegular,
    color: COLORS.WHITE.default,
    fontSize: appScale(8),
  },
  text: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    fontFamily: RobotoRegular,
    color: COLORS.WHITE.default,
    fontSize: appScale(6),
    letterSpacing: 1,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  buttonContainer: {
    marginTop: appScale(20),
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: appScale(5.5),
  },
  exitButton: {
    boxShadow: 'inset 0px 0px 12px 1px rgba(255,255,255,0.4)',
    borderColor: 'rgba(255,255,255,0.5)',
  },
} as const;
