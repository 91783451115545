import {WebViewMobileMessage, WebViewMobileMessages} from '../boardTypes';
import {sendEvent} from '../../../util/telemetry';

const sendWebViewMessage = (webViewMessage: WebViewMobileMessage) => {
  if (webViewMessage.message !== WebViewMobileMessages.MAKE_SOUND) {
    sendEvent(webViewMessage.message.toLowerCase(), webViewMessage);
  }
  try {
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(webViewMessage));
  } catch {}
};

export default sendWebViewMessage;
