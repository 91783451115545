import React from 'react';
import {BOTTOM_OFFSET} from '../Charts/Widgets/XAxis/XAxisStyles';
import appScale from '../../../util/appScale';

const BoardSwitchersContainer: React.FC = ({children}) => (
  <div
    style={{
      zIndex: 99999,
      top: 0,
      bottom: BOTTOM_OFFSET * 2,
      padding: appScale(1.25),
      position: 'absolute',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      display: 'flex',
    }}
  >
    {children}
  </div>
);

export default BoardSwitchersContainer;
