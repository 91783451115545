import React, {useMemo} from 'react';
import {Trades} from '../../trades';
import {TickerDataPoint} from '../../Utils/ticker-utils';
import {VISIBLE_HISTORY_WINDOW} from '../../chartConfig';
import appScale from '../../../../util/appScale';
import {IChartProps} from '../../../../types/UseChartTypes';

const TRADE_LINE = appScale(0.8);
const TRADE_LINE_OUTSTANDING = appScale(1.5);
const COLOR_PEACH_PUFF = '#FFDAB9';

interface TradeLineProps {
  xScale: IChartProps['xScale'];
  yScale: IChartProps['yScale'];
  lineScale: IChartProps['lineScale'];
  colorScale: IChartProps['colorScale'];
  d: Trades;
}

const TradeLine: React.FC<TradeLineProps> = ({d, xScale, yScale, lineScale, colorScale}) => {
  const innerD = lineScale(d.trade);
  const strokeWidth = (d as any).outstanding ? TRADE_LINE_OUTSTANDING : TRADE_LINE;
  const color = `${colorScale(d.trade[1].price - d.trade[0].price)}`;

  return (
    <g>
      <path
        clipPath="url(#r-clip-path)"
        d={innerD ? innerD : ''}
        stroke={color}
        strokeOpacity={0.53}
        strokeWidth={strokeWidth}
        fill={color}
      />
      <circle cx={xScale(d.trade[0].index)} cy={yScale(d.trade[0].price)} r={1} fill={COLOR_PEACH_PUFF} />
      <circle cx={xScale(d.trade[1].index)} cy={yScale(d.trade[1].price)} r={1} fill={COLOR_PEACH_PUFF} />
    </g>
  );
};

interface TradeLinesProps {
  chart: IChartProps;
  trades: Trades[];
  latest?: TickerDataPoint;
}

interface TradesWithKey extends Trades {
  key: string;
}

export const TradeLines: React.FC<TradeLinesProps> = ({trades, chart, latest}) => {
  const tradesGenerated = useMemo(() => {
    if (!trades?.length) return [];
    return trades.reduce((acc, d) => {
      if (latest?.index && d.trade[1].index < latest?.index - VISIBLE_HISTORY_WINDOW.NUMBER_ITEMS) {
        return acc;
      }
      const key = d.trade.reduce((keyValue, trade) => `${keyValue}_${trade.price}_${trade.index}`, '');
      return [...acc, {...d, key}];
    }, [] as TradesWithKey[]);
  }, [latest, trades]);

  if (!tradesGenerated?.length) return null;
  return (
    <g>
      {tradesGenerated.map((d) => {
        return (
          <TradeLine
            key={`live-trade-${d.key}`}
            d={d}
            xScale={chart.xScale}
            yScale={chart.yScale}
            lineScale={chart.lineScale}
            colorScale={chart.colorScale}
          />
        );
      })}
    </g>
  );
};
