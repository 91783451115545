import React, {useEffect, useState} from 'react';
import {countFormat} from '../Utils/utils';
import {FontAzoSansBold} from '../../../config/fonts';
import sendWebViewMessage from '../Utils/sendWebViewMessage';
import {WebViewMobileMessages, WebViewMobileSounds} from '../boardTypes';
import makeSound from '../../../util/makeSound';
import appScale from '../../../util/appScale';

const COUNTDOWN_OPACITY = 0.6;

const colors = {
  TEXT1: 'rgba(0, 191, 165, 0.65)',
  TEXT2_PRE: 'rgba(0, 230, 80, 0.7)',
  TEXT2_POST: 'rgba(230, 0, 80, 0.7)',
};

const styles = {
  container: {
    flex: 1,
    opacity: COUNTDOWN_OPACITY,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999,
  } as React.CSSProperties,
  text1: {
    fontSize: appScale(12),
    color: colors.TEXT1,
    fontFamily: FontAzoSansBold,
    textAlign: 'center',
    display: 'flex',
  } as React.CSSProperties,
  text2_pre: {
    fontSize: appScale(12),
    color: colors.TEXT2_PRE,
    textAlign: 'center',
    fontFamily: FontAzoSansBold,
    display: 'flex',
  } as React.CSSProperties,
  text2_post: {
    fontSize: appScale(14),
    color: colors.TEXT2_POST,
    textAlign: 'center',
    fontFamily: FontAzoSansBold,
  } as React.CSSProperties,
};

const SECOND_IN_MS = 1000;

export const Countdown = ({countdownMs, isCounting}: {countdownMs: number; isCounting: boolean}) => {
  const [time, setTime] = useState(countdownMs);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCounting && time > 0) {
      timer = setTimeout(() => {
        setTime(time - SECOND_IN_MS);
      }, SECOND_IN_MS);
    }
    return () => clearTimeout(timer);
  }, [isCounting, time, countdownMs]);

  useEffect(() => {
    makeSound(WebViewMobileSounds.COUNTDOWN);
  }, [time]);

  useEffect(
    () => () => {
      makeSound(WebViewMobileSounds.COUNTDOWN_COMPLETE);
    },
    [],
  );

  if (time <= 0) {
    return <></>;
  }

  let style = styles.text2_pre;
  if (time <= 3000) {
    style = styles.text2_post;
  }

  const font = (style.fontSize as number) + ((countdownMs - time) / 1000) * 10;

  return (
    <div style={styles.container}>
      <span style={styles.text1}>STARTING IN &nbsp;</span>
      <span style={{...style, ...{fontSize: font}}}> {countFormat(time)}</span>
    </div>
  );
};
