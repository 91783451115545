import React, {CSSProperties} from 'react';

import COLORS from '../../../../../config/colors';
import {RobotoRegular} from '../../../../../config/fonts';
import {dollarFormat, percentFormat} from '../../../Utils/utils';
import {PlaylistItemRating} from '../../../../../util/playlistRating';
import {GAIN_ICON, LOSS_ICON, NEGATIVE_ICON, POSITIVE_ICON} from '../../../../../assets/icons';
import appScale from '../../../../../util/appScale';

const ICON_SIZE = appScale(8);

export interface EndRunDialogResultsProps {
  initialCash: number;
  tradeReturn: number;
  stockReturn: number;
  indexReturn: number;
  gameCash: number;
  runRating: PlaylistItemRating;
}

const EndRunDialogResults: React.FC<EndRunDialogResultsProps> = ({
  initialCash,
  tradeReturn,
  stockReturn,
  indexReturn,
  gameCash,
}) => {
  const profit = gameCash - initialCash;

  const isProfitPositive = profit > 0;
  const isIndexReturnPositive = tradeReturn > indexReturn;
  const isStockReturnPositive = tradeReturn > stockReturn;

  const profitResult = dollarFormat(profit);
  const tradeReturnResult = percentFormat(tradeReturn);
  const indexReturnResult = percentFormat(indexReturn);
  const stockReturnResult = percentFormat(stockReturn);

  const ProfitCustomIcon = <ResultIcon src={isProfitPositive ? GAIN_ICON : LOSS_ICON} />;
  const ProfitCustomResult = (
    <span style={{color: isProfitPositive ? COLORS.LIME_GREEN.default : COLORS.TANGERINE.default}}>{profitResult}</span>
  );
  const profitLabel = isProfitPositive ? 'earned' : 'lost';

  return (
    <div style={mainContainerStyles}>
      <ListItem label={profitLabel} text="You" CustomResult={ProfitCustomResult} CustomIcon={ProfitCustomIcon} />
      <ListItem label="returns" isPositive={isProfitPositive} result={tradeReturnResult} text="Positive" />
      <ListItem label="S&P" isPositive={isIndexReturnPositive} result={indexReturnResult} />
      <ListItem label="Buy&Hold" isPositive={isStockReturnPositive} result={stockReturnResult} />
    </div>
  );
};

export default EndRunDialogResults;

const mainContainerStyles: CSSProperties = {
  marginTop: appScale(1),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'nowrap column',
};

const itemContainerStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: appScale(0.25),
  marginBottom: appScale(0.25),
  color: COLORS.WHITE.default,
  fontFamily: RobotoRegular,
  fontSize: appScale(6),
  paddingRight: appScale(6),
  letterSpacing: appScale(0.1),
};

const textLabel: CSSProperties = {
  fontWeight: 'bold',
};

const ListItem: React.FC<{
  label?: string;
  result?: string;
  isPositive?: boolean;
  text?: string;
  CustomIcon?: React.ReactNode;
  CustomResult?: React.ReactNode;
}> = ({text = 'Outperformed', label = '', result = '', isPositive = false, CustomIcon, CustomResult}) => {
  return (
    <div style={itemContainerStyles}>
      <div>
        {text}
        <span style={textLabel}>{` ${label} `}</span>
        {CustomResult ?? (
          <>
            (<span style={{color: isPositive ? COLORS.LIME_GREEN.default : COLORS.TANGERINE.default}}>{result}</span>)
          </>
        )}
      </div>

      {CustomIcon ?? <ResultIcon src={isPositive ? POSITIVE_ICON : NEGATIVE_ICON} />}
    </div>
  );
};

const ResultIcon: React.FC<{src: string}> = ({src}) => <img src={src} alt="" style={resultIconStyles} />;

const resultIconStyles: CSSProperties = {
  marginLeft: appScale(2),
  objectFit: 'contain',
  width: ICON_SIZE,
  height: ICON_SIZE,
};
