import React, {CSSProperties} from 'react';
import DIALOGS_IDS from '../../../config/dialogsIds';
import separateRender from '../../../util/separate-render';
import Dialog from '../../dialog/Dialog';
import Button from '../../common/Button';
import DialogTitle from '../../dialog/DialogTitle';
import appScale from '../../../util/appScale';

const ICON_SIZE = appScale(15.25);

interface SMATutorialDialogProps {
  title: string;
  icon: any;
  text: React.ReactElement<Text>;
  onContinue?: () => void;
}

const SMATutorialDialog: React.FC<SMATutorialDialogProps> = ({title, icon, text, onContinue}) => {
  const handleClose = () => separateRender.unmount(DIALOGS_IDS.SMA_TUTORIAL);
  const handleContinue = () => {
    handleClose();
    if (onContinue) onContinue();
  };

  return (
    <Dialog containerStyle={containerStyles} onCloseIconButtonPress={handleContinue} onBackgroundPress={handleContinue}>
      <div style={iconContainerStyles}>
        <img src={icon} style={iconStyles} alt="" />
      </div>
      <DialogTitle title={title} />
      <div style={textStyle}>{text}</div>
      <div style={dialogActionsStyles}>
        <Button label="Continue" onClick={handleContinue} />
      </div>
    </Dialog>
  );
};

export default SMATutorialDialog;

const containerStyles: CSSProperties = {
  width: '100%',
  maxWidth: 700,
  height: '100%',
  maxHeight: 370,
  paddingLeft: appScale(5),
  paddingRight: appScale(5),
  paddingBottom: appScale(2),
};

const textStyle: CSSProperties = {
  textAlign: 'justify',
  fontSize: appScale(6),
  overflowY: 'scroll',
  color: 'white',
};

const iconContainerStyles: CSSProperties = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: appScale(2),
};

const iconStyles: CSSProperties = {
  width: ICON_SIZE,
  height: ICON_SIZE,
};

const dialogActionsStyles: CSSProperties = {
  marginTop: appScale(2),
  padding: appScale(1),
};
