import React, {CSSProperties} from 'react';

export interface DialogBackgroundProps {
  onClick?: () => void;
  backgroundColor?: string;
}

const DEFAULT_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.8)';

const DialogBackground: React.FC<DialogBackgroundProps> = ({onClick, backgroundColor = DEFAULT_BACKGROUND_COLOR}) => (
  <div style={{...styles.background, backgroundColor}} onClick={onClick} />
);

export default DialogBackground;

const styles = {
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1300,
  } as CSSProperties,
};
