import React from 'react';
import IconButton from '../../../../../components/common/IconButton';
import {SHARE_ICON} from '../../../../../assets/icons';
import appScale from '../../../../../util/appScale';

const ICON_SIZE = appScale(8.5);

export interface EndRunDialogShareProps {
  onClick: () => void;
}

export const EndRunDialogShare: React.FC<EndRunDialogShareProps> = ({onClick}) => {
  return (
    <div style={styles.container}>
      <IconButton size={ICON_SIZE} src={SHARE_ICON} onClick={onClick} />
    </div>
  );
};

export default EndRunDialogShare;

const styles = {
  container: {
    position: 'absolute',
    left: appScale(3),
    top: appScale(3),
    zIndex: 3,
  },
} as const;
