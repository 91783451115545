import React, {CSSProperties} from 'react';

import COLORS from '../../config/colors';
import TUTORIAL_CONFIG from './TUTORIAL_CONFIG';
import appScale from '../../util/appScale';
import Color from 'color';

export interface TutorialTriangleProps {
  style?: CSSProperties;
  isAction?: boolean;
}

const TutorialTriangle: React.FC<TutorialTriangleProps> = ({style, isAction = false}) => (
  <div style={{...styles.container, ...style}}>
    <div style={{...styles.triangle, ...(isAction && styles.action)}} />
    <div style={{...styles.triangle, ...styles.secondary}} />
  </div>
);

export default TutorialTriangle;

const styles: Record<any, CSSProperties> = {
  container: {
    position: 'relative',
  },
  triangle: {
    position: 'absolute',
    borderTop: `solid ${appScale(3.4)}px transparent`,
    borderBottom: `solid ${appScale(3.4)}px ${Color(COLORS.WHITE.default).alpha(0.5).toString()}`,
    borderLeft: `solid ${appScale(3.4)}px transparent`,
    borderRight: `solid ${appScale(3.4)}px transparent`,
  },
  secondary: {
    top: appScale(0.4),
    borderBottom: `solid ${appScale(3.4)}px ${COLORS.BLACK_PEARL.default}`,
  },
  action: {
    borderBottom: `solid ${appScale(3.4)}px ${TUTORIAL_CONFIG.ACTION_CONTAINER_BORDER_COLOR}`,
  },
};
