import appScale from '../../util/appScale';

export const TRADE_COMMISSION = 5;
export const STARTUP_BALANCE = 100000;

export const BOARD_NAV_HEIGHT = appScale(10.6);
export const BOARD_PROGRESS_HEIGHT = appScale(1.9);

export const Y_AXIS_CONTAINER_WIDTH = appScale(26.5);
export const PRICE_CONTAINER_WIDTH = Y_AXIS_CONTAINER_WIDTH;
export const PRICE_CONTAINER_HEIGHT = appScale(8);
