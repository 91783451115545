import React from 'react';

import Price from './Price';
import {Trades} from '../../../trades';
import COLORS from '../../../../../config/colors';

export interface BoughtStockProps {
  trade: Trades;
}

const BoughtStock: React.FC<BoughtStockProps> = ({trade}) => (
  <Price backgroundColor={COLORS.GRAY.default} color={COLORS.WHITE.default} price={trade.trade[0].price} />
);

export default BoughtStock;
