import React, {useState} from 'react';
import {TickerDataPointNews} from '../../../Utils/ticker-utils';
import sendWebViewMessage from '../../../Utils/sendWebViewMessage';
import {WebViewMobileMessages} from '../../../boardTypes';
import {NEWS_ALERT_ICON} from '../../../../../assets/icons';
import COLORS from '../../../../../config/colors';
import appScale from '../../../../../util/appScale';
import {RobotoRegular} from '../../../../../config/fonts';
import {TelemetryEvents, sendEvent} from '../../../../../util/telemetry';
import ButtonsNames from '../../../../../config/buttonsNames';

export interface NewsAlertDialogNewsProps {
  news: TickerDataPointNews;
}

const ALERT_ICON_SIZE = appScale(8.26);

const NewsAlertDialogNews: React.FC<NewsAlertDialogNewsProps> = ({news}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.BOARD_SCREEN_NEWS_DIALOG_READ_MORE,
    });
    setIsExpanded((previousValue) => !previousValue);
  };

  function onSeeReadArticleClick() {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.BOARD_SCREEN_NEWS_DIALOG_OPEN_LINK,
    });
    return sendWebViewMessage({message: WebViewMobileMessages.OPEN_LINK, link: news.url as string});
  }

  const SeeMoreLink = () =>
    news.url ? (
      <div style={styles.openLink} onClick={onSeeReadArticleClick}>
        Read full article
      </div>
    ) : null;

  const NewsDescription: React.FC = () => {
    if (!isExpanded) return null;

    return (
      <div style={styles.descriptionWrapper}>
        <DescriptionContainer>
          <div style={styles.description}>{news.description}</div>
          {!!news.url && <SeeMoreLink />}
        </DescriptionContainer>
      </div>
    );
  };

  const ReadMoreText: React.FC = () => (
    <div style={styles.readMoreText} onClick={toggleReadMore}>
      {isExpanded ? 'Read less' : 'Read more'}
    </div>
  );

  return (
    <div style={styles.container}>
      <div style={styles.headlineContainer}>
        <AlertIcon />
        <Headline headline={news.headline} />
        <ReadMoreText />
      </div>

      <NewsDescription />
    </div>
  );
};

export default NewsAlertDialogNews;

const styles = {
  container: {
    paddingTop: appScale(10),
    pointerEvents: 'auto',
    maxWidth: 700,
    width: '90%',
  },
  headlineContainer: {
    padding: `0 ${appScale(3.25)}px`,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: appScale(12.5),
  },
  headlineText: {
    flex: 1,
    marginLeft: appScale(4),
    marginRight: appScale(4),
    color: '#e9f5a4',
    fontFamily: RobotoRegular,
    fontWeight: 'bold',
    fontSize: appScale(5),
    letterSpacing: appScale(0.1),
  },
  readMoreText: {
    marginRight: appScale(1),
    width: appScale(30),
    textDecorationLine: 'underline',
    color: 'white',
    fontWeight: 500,
    fontSize: appScale(5.5),
    flexFlow: 'no-wrap row',
  },
  descriptionWrapper: {
    flex: 1,
  },
  descriptionContainer: {
    padding: `0 ${appScale(3.2)}px`,
    overflowY: 'auto',
    overflowX: 'auto',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  description: {
    textAlign: 'justify',
    color: 'white',
    fontSize: appScale(5.5),
    letterSpacing: appScale(0.1),
  },
  alertIcon: {
    width: ALERT_ICON_SIZE,
    height: ALERT_ICON_SIZE,
    resizeMode: 'contain',
    top: appScale(1.25),
  },
  openLink: {
    margin: `${appScale(4.2)}px 0`,
    fontSize: appScale(5.5),
    color: COLORS.ROYAL_BLUE.default,
    textDecoration: 'underline',
  },
} as const;

const DescriptionContainer: React.FC = ({children}) => <div style={styles.descriptionContainer}>{children}</div>;

const AlertIcon: React.FC = () => <img src={NEWS_ALERT_ICON} style={styles.alertIcon} alt="" />;

const Headline: React.FC<{headline: string}> = ({headline}) => <p style={styles.headlineText}>{headline}</p>;
