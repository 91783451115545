import {ISessionCash} from './i-session-cash';
import {TickerDataPoint} from './Utils/ticker-utils';
import {Trades} from './trades';

export class CashManager {
  public sessionCash: ISessionCash[] = [];
  boundData: TickerDataPoint[] = [];
  changeValue: number = 0;
  sharesHeld: number = 0;
  isCashLiquid: boolean = true;
  shareMessage: string = '';
  latest: TickerDataPoint = {price: 0, index: 0, date: undefined as any};
  tradeReturn: number = 0;
  trades: Trades[] = [];
  latestTrade: Trades = {isKept: false, trade: []};

  public resetProps = () => {
    this.sessionCash = [];
    this.trades = [];
    this.sharesHeld = 0;
    this.isCashLiquid = true;
  };
}
