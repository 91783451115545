import {TutorialSteps, useTutorial, useTutorialNextStepDisabled} from '../../../../../contexts/TutorialContext';
import React, {useEffect} from 'react';
import TutorialTapToContinueDialog from '../../../../../components/Tutorial/TutorialTapToContinueDialog';

const EndRunDialogTutorial: React.FC = () => {
  const tutorial = useTutorial();
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();

  useEffect(() => {
    tutorialNextStepDisabled.next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (tutorial.shouldHideTooltip(TutorialSteps.BOARD_SCREEN_FREE_RUN_AFTER_NEWS)) return null;

  const handleEndTutorial = () => {
    tutorial.setStep(TutorialSteps.BOARD_SCREEN_END_RUN_DIALOG);
  };

  return (
    <TutorialTapToContinueDialog
      disabled={tutorialNextStepDisabled.disabled}
      containerStyle={{zIndex: 1301}}
      text={
        <div>
          You'll earn a medal based on how many objectives {'\n'}
          you outperform. Go for Gold in every run.
        </div>
      }
      onTapToContinue={handleEndTutorial}
    />
  );
};

export default EndRunDialogTutorial;
