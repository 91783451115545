import React from 'react';

import BoardSwitcher, {BoardSwitcherProps} from './BoardSwitcher';
import SMATutorialDialog from './SMATutorialDialog';

import {BoardStateRef} from '../../../hooks/useBoardStateRef';
import {TutorialSteps, useTutorial} from '../../../contexts/TutorialContext';

import {SMA_OPTIONS} from '../../../config/sma.config';
import DIALOGS_IDS from '../../../config/dialogsIds';
import useSMAPressedButtons from '../../../hooks/useSMAPressedButtons';
import separateRender from '../../../util/separate-render';

export type SMASwitcherProps = Omit<BoardSwitcherProps, 'options'> & {
  canShowSMATutorialDialogs: boolean;
  boardStateRef?: BoardStateRef;
  pause?: () => void;
  play?: () => void;
};

const SMASwitcher: React.FC<SMASwitcherProps> = (props) => {
  const {onSelect, pause, play, canShowSMATutorialDialogs, boardStateRef, ...otherProps} = props;

  const tutorial = useTutorial();

  const SMAPressedButtons = useSMAPressedButtons();

  const showSMATutorialDialog = (selected: number) => {
    const foundSMAOption = SMA_OPTIONS.find((s) => s.value === selected);

    // Just for Typescript and just to be sure that nothing crashes, it's almost impossible to get no foundSMAOption
    if (!foundSMAOption) return;

    // SMA - 0 has no tutorialDialog field
    if (!foundSMAOption.tutorialDialog) return;
    const {tutorialDialog} = foundSMAOption;

    // We don't have pause and play in EntireStockChart, so we use it only during the run
    if (pause) pause();

    const handleContinue = () => {
      // prevent unpausing if there is news alert dialog rendered
      if (boardStateRef?.newsAlertDialogIdRef === null && play) play();
    };

    separateRender.render(
      () => (
        <SMATutorialDialog
          title={tutorialDialog.title}
          text={tutorialDialog.text}
          onContinue={handleContinue}
          icon={foundSMAOption.activeIcon}
        />
      ),
      {customId: DIALOGS_IDS.SMA_TUTORIAL},
    );
  };

  const handleSelect = (selected: number) => {
    onSelect(selected);

    // if the run is not started yet or that sma button has been pressed already, then we prevent showing any sma tutorial dialog
    if (SMAPressedButtons.isPressed(selected) || !canShowSMATutorialDialogs) return;

    SMAPressedButtons.logPressed(selected);
    showSMATutorialDialog(selected);
  };

  const disabled =
    tutorial.shouldBeDisabled(TutorialSteps.BOARD_SCREEN_FREE_RUN_AFTER_NEWS) &&
    tutorial.shouldBeDisabled(TutorialSteps.BOARD_SCREEN_FREE_RUN_BEFORE_NEWS);

  return (
    <div>
      <BoardSwitcher onSelect={handleSelect} {...otherProps} options={SMA_OPTIONS} disabled={disabled} />
    </div>
  );
};

export default SMASwitcher;
