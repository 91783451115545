import {useEffect, useRef} from 'react';
import {TutorialSteps, useTutorial, useTutorialNextStepDisabled} from '../contexts/TutorialContext';
import TutorialDialog from '../components/Tutorial/TutorialDialog';
import TutorialText from '../components/Tutorial/TutorialText';
import TutorialTapToContinueDialog from '../components/Tutorial/TutorialTapToContinueDialog';
import * as React from 'react';
import {CashManager} from '../components/board/cash-manager';
import DIALOGS_IDS from '../config/dialogsIds';
import separateRender from '../util/separate-render';
import {TUTORIAL_COMMON_STYLES} from '../components/Tutorial/TUTORIAL_CONFIG';

export interface UseBoardTutorialTooltipsProps {
  cash: React.MutableRefObject<CashManager>;
  pause: () => void;
  play: () => void;
  buy: () => void;
  sell: () => void;
}

interface DialogProps {
  onTapToContinue: () => void;
}

const FONT_SIZE_SELL_BUY = 20;

const useBoardTutorialTooltips = ({cash, pause, play, buy, sell}: UseBoardTutorialTooltipsProps) => {
  const tutorial = useTutorial();
  const isTwentiethTickPassed = cash.current.latest.index > 20;
  const isFortiethTickPassed = cash.current.latest.index > 40;

  const tutorialRef = useRef({
    isTapToBuyTooltipShown: false,
    isTapToSellTooltipShown: false,
    isProfitTooltipShown: false,
    isProgressDialogShown: false,
  });

  useEffect(() => {
    // TAP_TO_BUY_TOOLTIP
    if (
      isTwentiethTickPassed &&
      !tutorialRef.current.isTapToBuyTooltipShown &&
      tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_TAP_TO_BUY)
    ) {
      tutorialRef.current.isTapToBuyTooltipShown = true;
      pause();
      separateRender.render(
        ({unmount}) => (
          <TapToBuy
            onTapToContinue={() => {
              unmount();
              buy();
              play();
              tutorial.setStep(TutorialSteps.BOARD_SCREEN_TAP_TO_SELL);
            }}
          />
        ),
        {customId: DIALOGS_IDS.TUTORIAL},
      );
    }

    // TAP_TO_SETL_TOOLTIP
    if (
      isFortiethTickPassed &&
      !tutorialRef.current.isTapToSellTooltipShown &&
      tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_TAP_TO_SELL)
    ) {
      pause();
      tutorialRef.current.isTapToSellTooltipShown = true;
      separateRender.render(
        ({unmount}) => (
          <TapToSell
            onTapToContinue={() => {
              unmount();
              sell();
              tutorial.setStep(TutorialSteps.BOARD_SCREEN_PROFIT);
            }}
          />
        ),
        {customId: DIALOGS_IDS.TUTORIAL},
      );
    }

    // PROFIT_TOOLTIP
    if (
      isFortiethTickPassed &&
      !tutorialRef.current.isProfitTooltipShown &&
      tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_PROFIT)
    ) {
      tutorialRef.current.isProfitTooltipShown = true;
      separateRender.render(
        ({unmount}) => (
          <YouMadeProfit
            onTapToContinue={() => {
              unmount();
              tutorial.setStep(TutorialSteps.BOARD_SCREEN_PROGRESS);
            }}
          />
        ),
        {customId: DIALOGS_IDS.TUTORIAL},
      );
    }
    // PROGRESS_TOOLTIP
    if (
      isFortiethTickPassed &&
      !tutorialRef.current.isProgressDialogShown &&
      tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_PROGRESS)
    ) {
      tutorialRef.current.isProgressDialogShown = true;
      separateRender.render(({unmount}) => (
        <ContinueBuyAndSell
          onTapToContinue={() => {
            unmount();
            play();
            tutorial.setStep(TutorialSteps.BOARD_SCREEN_FREE_RUN_BEFORE_NEWS);
          }}
        />
      ));
    }
  });
  return null;
};

export default useBoardTutorialTooltips;

const TapToBuy: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();
  return (
    <TutorialDialog
      containerStyle={TUTORIAL_COMMON_STYLES.boardDialogContainer}
      contentStyle={TUTORIAL_COMMON_STYLES.boardDialogContent}
      disabled={tutorialNextStepDisabled.disabled}
      isAction
      onBackgroundPress={onTapToContinue}
    >
      <TutorialText
        disabled={tutorialNextStepDisabled.disabled}
        fontSize={FONT_SIZE_SELL_BUY}
        onClick={onTapToContinue}
      >
        Tap to Buy
      </TutorialText>
    </TutorialDialog>
  );
};

const TapToSell: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();

  return (
    <TutorialDialog
      containerStyle={TUTORIAL_COMMON_STYLES.boardDialogContainer}
      contentStyle={TUTORIAL_COMMON_STYLES.boardDialogContent}
      disabled={tutorialNextStepDisabled.disabled}
      isAction
      onBackgroundPress={onTapToContinue}
    >
      <TutorialText
        disabled={tutorialNextStepDisabled.disabled}
        fontSize={FONT_SIZE_SELL_BUY}
        onClick={onTapToContinue}
      >
        Tap to Sell
      </TutorialText>
    </TutorialDialog>
  );
};

const ContinueBuyAndSell: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();
  return (
    <TutorialTapToContinueDialog
      containerStyle={TUTORIAL_COMMON_STYLES.boardDialogContainer}
      contentStyle={TUTORIAL_COMMON_STYLES.boardDialogContent}
      disabled={tutorialNextStepDisabled.disabled}
      onTapToContinue={onTapToContinue}
      text={<TutorialText>Continue buying and selling shares {'\n'} until time runs out</TutorialText>}
    />
  );
};

const YouMadeProfit: React.FC<DialogProps> = ({onTapToContinue}) => {
  const tutorialNextStepDisabled = useTutorialNextStepDisabled();
  return (
    <TutorialTapToContinueDialog
      containerStyle={TUTORIAL_COMMON_STYLES.boardDialogContainer}
      contentStyle={TUTORIAL_COMMON_STYLES.boardDialogContent}
      disabled={tutorialNextStepDisabled.disabled}
      onTapToContinue={onTapToContinue}
      text={<TutorialText>You've made a profit</TutorialText>}
    />
  );
};
