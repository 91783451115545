import {useCallback, useState} from 'react';
import {normalizeData, TickerDataPoint, TickerDataStats} from '../components/board/Utils/ticker-utils';

export interface UsePlaylistItemToPlay {
  playlistItemToPlay: any;
  stock: TickerDataStats | null;
  ticks: TickerDataPoint[];
  isLoaded: boolean;
  loadNext: (nextPlaylistItem: any) => void;
  reset: () => void;
}

const usePlaylistItemToPlay = (): UsePlaylistItemToPlay => {
  const [playlistItemToPlay, setPlaylistItemToPlay] = useState<UsePlaylistItemToPlay['playlistItemToPlay']>(null);
  const [stock, setStock] = useState<UsePlaylistItemToPlay['stock']>(null);
  const [ticks, setTicks] = useState<UsePlaylistItemToPlay['ticks']>([]);
  const [isLoaded, setIsLoaded] = useState<UsePlaylistItemToPlay['isLoaded']>(false);

  const reset = () => {
    setStock(null);
    setTicks([]);
    setPlaylistItemToPlay(null);
    setIsLoaded(false);
  };

  const loadNext: UsePlaylistItemToPlay['loadNext'] = useCallback(async (nextPlaylistItem) => {
    await reset();
    if (!nextPlaylistItem) {
      setPlaylistItemToPlay(nextPlaylistItem);
      setIsLoaded(true);
      return;
    }

    const {stock: newStock, history} = normalizeData(nextPlaylistItem);

    setPlaylistItemToPlay(nextPlaylistItem);
    setTicks(history);
    setStock(newStock);
    setIsLoaded(true);
  }, []);
  return {
    playlistItemToPlay,
    stock,
    ticks,
    isLoaded,
    loadNext,
    reset,
  };
};

export default usePlaylistItemToPlay;
