import React, {CSSProperties} from 'react';

import TUTORIAL_CONFIG from './TUTORIAL_CONFIG';
import makeSound from '../../util/makeSound';
import {WebViewMobileSounds} from '../board/boardTypes';

type TextType = React.ReactElement<Text>;

export interface TutorialTextProps {
  children: TextType | TextType[] | string | string[];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  fontSize?: number;
  isAction?: boolean;
  disabled?: boolean;
}

const TutorialText: React.FC<TutorialTextProps> = ({
  isAction,
  children,
  onClick,
  style,
  fontSize = TUTORIAL_CONFIG.FONT_SIZE,
  disabled,
}) => {
  const handlePress = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled || !onClick) return;

    makeSound(WebViewMobileSounds.TAP);
    onClick(event);
  };
  return (
    <div style={{...styles.text, ...(isAction && styles.isAction), ...style, ...{fontSize}}} onClick={handlePress}>
      {children}
    </div>
  );
};

export default TutorialText;

const styles: Record<any, CSSProperties> = {
  text: {
    fontFamily: TUTORIAL_CONFIG.FONT_FAMILY,
    color: 'white',
    textAlign: 'center',
    zIndex: 11,
  },
  isAction: {
    color: TUTORIAL_CONFIG.ACTION_TEXT_COLOR,
  },
};
