import {
  SMA_10_HIGHLIGHT_ICON,
  SMA_10_ICON,
  SMA_200_HIGHLIGHT_ICON,
  SMA_200_ICON,
  SMA_50_HIGHLIGHT_ICON,
  SMA_50_ICON,
  SMA_OFF_HIGHLIGHT_ICON,
  SMA_OFF_ICON,
} from '../assets/icons';
import COLORS from './colors';
import ButtonsNames from './buttonsNames';
import React, {ReactElement} from 'react';

interface SMAOption {
  value: number;
  defaultIcon: any;
  activeIcon: any;
  strokeColor?: string;
  telemetryButtonName: ButtonsNames;
  tutorialDialog?: {
    title: string;
    text: ReactElement<Text>;
  };
}

export const SMA_OPTIONS: SMAOption[] = [
  {
    // SMA - 0
    value: 0,
    defaultIcon: SMA_OFF_ICON,
    activeIcon: SMA_OFF_HIGHLIGHT_ICON,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SMA_SWITCHER_OFF,
  },
  {
    // SMA - 10
    value: 10,
    defaultIcon: SMA_10_ICON,
    activeIcon: SMA_10_HIGHLIGHT_ICON,
    strokeColor: COLORS.AMETHYST.default,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SMA_SWITCHER_10,
    tutorialDialog: {
      title: 'The 10-day simple moving average (SMA)',
      text: (
        <span>
          The 10-day moving average (10 SMA) is a stock or crypto indicator. This indicator simplifies a way to notice a
          stock price trend. This means the indicator is not going to tell you where price is headed to but rather gives
          you a visual of how strong the price direction is. To this point, it’s best to use the 10-period moving average
          to gauge the health of a stock on the move.
          <br/>
          When a short term moving average crosses above a longer-term moving average (like say 100-day or 200-day moving average),
          it is a sign that the stock is bullish and prices are on the rise. When the short term MA crosses below the longer-term
          MAs, it signals a downtrend.
          <br/>
          Despite its reactivity to price changes, a 10-day moving average is a powerful tool to know if prices are moving
          in an uptrend or a downtrend. So traders can determine the odds of whether the prices will continue to stay bullish
          or if the buyers’ steam will run out and the prices are likely to go south.
        </span>
      ),
    },
  },
  {
    // SMA - 50
    value: 50,
    defaultIcon: SMA_50_ICON,
    activeIcon: SMA_50_HIGHLIGHT_ICON,
    strokeColor: COLORS.FESTIVAL.default,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SMA_SWITCHER_50,
    tutorialDialog: {
      title: 'The 50-day SMA',
      text: (
        <span>
          If the price moves significantly below the 50-period moving average, it's commonly interpreted as a trend
          change to the downside. As noted, the 50-day moving average is widely used because it works well. The more
          accurate a moving average is as a trend indicator, the more useful it is for traders.
          <br/>
          <br/>
          The ideal moving average shows a level that price will not likely violate on a mere temporary retracement, thus possibly
          giving a false market reversal signal. It can also be used to place a trailing stop order on an existing market
          position.
        </span>
      ),
    },
  },
  {
    // SMA - 200
    value: 200,
    defaultIcon: SMA_200_ICON,
    activeIcon: SMA_200_HIGHLIGHT_ICON,
    strokeColor: COLORS.ROYAL_BLUE.default,
    telemetryButtonName: ButtonsNames.BOARD_SCREEN_SMA_SWITCHER_200,
    tutorialDialog: {
      title: 'The 200-day SMA',
      text: (
        <span>
          The 200-day simple moving average is one of the most important tools when trading. The simple reason, all
          traders and I mean all are aware of the number of periods and actively watch this average on the price chart.
          Since there are so many eyes on the 200-day SMA, many traders will place their orders around
          this key level.
          <br/>
          Some traders will look for the 200-day to act as resistance, while others will use the average
          as a buying opportunity with the assumption major support will keep the stock up. For this reason, the price
          action tends to conform to the SMA 200 moving average quite nicely.
          <br/>
          What happens when a stock crosses its 200 day moving average? A stock that is trading above its 200 SMA is
          considered to be in a long term uptrend. If the short term 50 SMA breaks above the 200 SMA,
          this is known as a Golden Cross, whereas the inverse is known as a Death Cross.
        </span>
      ),
    },
  },
];
