const DIALOGS_IDS = Object.freeze({
  ENJOY_THIS_APP: 'ENJOY_THIS_APP',
  BOARD_FULL_SCREEN_ADS_FALLBACKS: 'BOARD_FULL_SCREEN_ADS_FALLBACKS',

  TUTORIAL: 'TUTORIAL',
  TUTORIAL_BOARD_PAUSE: 'TUTORIAL_BOARD_PAUSE',
  TUTORIAL_START: 'TUTORIAL_START',

  REMOVE_ADS: 'REMOVE_ADS',
  SMA_TUTORIAL: 'SMA_TUTORIAL',
  EXIT_RUN: 'EXIT_RUN',

  ENTIRE_STOCK_CHART_NEWS_ALERT: 'ENTIRE_STOCK_CHART_NEWS_ALERT',
});

export default DIALOGS_IDS;
