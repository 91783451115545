import React, {CSSProperties} from 'react';

import TutorialContent from './TutorialContent';
import appScale from '../../util/appScale';

interface TutorialDialogProps {
  onBackgroundPress?: () => void;
  contentStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  isAction?: boolean;
  disabled?: boolean;
}

const TutorialDialog: React.FC<TutorialDialogProps> = (props) => {
  const {children, onBackgroundPress, contentStyle, isAction = false, disabled, containerStyle} = props;
  const tutorialContentStyles = {...styles.contentContainer, ...contentStyle};

  const handleBackgroundPress = () => {
    if (disabled) return;
    if (onBackgroundPress) onBackgroundPress();
  };

  return (
    <div style={{...styles.container, ...containerStyle}}>
      {onBackgroundPress && <div onClick={handleBackgroundPress} style={styles.background} />}
      <TutorialContent isAction={isAction} style={tutorialContentStyles}>
        {children}
      </TutorialContent>
    </div>
  );
};

export default TutorialDialog;

const styles: Record<any, CSSProperties> = {
  container: {
    position: 'absolute',
    zIndex: 1300,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    zIndex: 1301,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    maxWidth: appScale(135),
    display: 'flex',
    flexDirection: 'column',
    padding: `${appScale(3.2)}px ${appScale(11.5)}px`,
  },
};
