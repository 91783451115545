import {useRef} from 'react';

export interface BoardStateRef {
  shouldRunAnimationInEndRunDialog: boolean;
  newsAlertDialogIdRef: string | null;
  shouldShowEnjoyThisAppDialog: boolean;
  shouldBePaused: boolean;
}

const useBoardStateRef = () =>
  useRef<BoardStateRef>({
    shouldRunAnimationInEndRunDialog: true,
    newsAlertDialogIdRef: null,
    shouldShowEnjoyThisAppDialog: false,
    shouldBePaused: false,
  }).current;

export default useBoardStateRef;
