import React, {CSSProperties} from 'react';
import IconButton from '../../../common/IconButton';
import {PAUSE_ICON} from '../../../../assets/icons';
import {TutorialSteps, useTutorial} from '../../../../contexts/TutorialContext';
import TutorialContent from '../../../Tutorial/TutorialContent';
import TutorialText from '../../../Tutorial/TutorialText';
import appScale from '../../../../util/appScale';
import TutorialTriangle from '../../../Tutorial/TutorialTriangle';

interface NavigationHeaderExitButtonProps {
  onClick: () => void;
}

const NavigationHeaderExitButton: React.FC<NavigationHeaderExitButtonProps> = ({onClick}) => {
  const tutorial = useTutorial();
  return (
    <div style={containerStyle}>
      {tutorial.shouldShowTooltip(TutorialSteps.BOARD_SCREEN_BEFORE_RUN_BOARD_TUTORIAL) && (
        <TutorialContent style={tutorialContentStyles}>
          <TutorialText style={tutorialTextStyles}>Pause or exit run</TutorialText>
          <TutorialTriangle style={tutorialTriangleStyles} />
        </TutorialContent>
      )}
      <IconButton size={appScale(8)} src={PAUSE_ICON} onClick={onClick} />
    </div>
  );
};

export default NavigationHeaderExitButton;

const containerStyle: CSSProperties = {
  position: 'relative',
};

const tutorialContentStyles: CSSProperties = {
  position: 'absolute',
  top: appScale(12),
  right: appScale(-1),
  width: appScale(56),
};

const tutorialTriangleStyles: CSSProperties = {
  position: 'absolute',
  top: appScale(-7),
  right: appScale(9),
};

const tutorialTextStyles: CSSProperties = {
  fontSize: appScale(4),
};
