import React, {CSSProperties} from 'react';
import IconButton from '../../../../common/IconButton';
import {NEWS_CONTINUE_ICON, NEWS_TRADE_ICON} from '../../../../../assets/icons';
import appScale from '../../../../../util/appScale';
import TutorialContent from '../../../../Tutorial/TutorialContent';
import TutorialTriangle from '../../../../Tutorial/TutorialTriangle';
import TutorialText from '../../../../Tutorial/TutorialText';
import {BOTTOM_OFFSET} from '../../../Charts/Widgets/XAxis/XAxisStyles';
import {TelemetryEvents, sendEvent} from '../../../../../util/telemetry';
import ButtonsNames from '../../../../../config/buttonsNames';

const ICON_SIZE = appScale(20);

export interface NewsAlertDialogActionsProps {
  isHidden: boolean;
  onContinuePlay?: () => void;
  onBuySell?: () => void;
  isTutorialMode: boolean;
}

const NewsAlertDialogActions: React.FC<NewsAlertDialogActionsProps> = (props) => {
  const {isHidden, onContinuePlay, onBuySell, isTutorialMode} = props;

  if (isHidden) return null;

  const handleTrade = () => {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.BOARD_SCREEN_NEWS_DIALOG_BUY_SELL,
    });
    onBuySell && onBuySell();
  };

  const handleContinue = () => {
    sendEvent(TelemetryEvents.BUTTON_PRESSED, {
      button: ButtonsNames.BOARD_SCREEN_NEWS_DIALOG_CONTINUE,
    });
    onContinuePlay && onContinuePlay();
  };

  return (
    <div style={styles.container}>
      <div style={styles.actionContainer}>
        {isTutorialMode && (
          <TutorialContent style={styles.buySellTutorialContent}>
            <TutorialTriangle style={styles.buySellTutorialTriangle} />
            <TutorialText>Buy/Sell</TutorialText>
          </TutorialContent>
        )}
        <IconButton disabled={isTutorialMode} src={NEWS_TRADE_ICON} onClick={handleTrade} size={ICON_SIZE} />
      </div>

      <div style={styles.actionContainer}>
        {isTutorialMode && (
          <TutorialContent style={styles.continueToPlayTutorialContent}>
            <TutorialTriangle style={styles.continueToPlayTutorialTriangle} />
            <TutorialText>Continue to play</TutorialText>
          </TutorialContent>
        )}
        <IconButton disabled={isTutorialMode} src={NEWS_CONTINUE_ICON} onClick={handleContinue} size={ICON_SIZE} />
      </div>
    </div>
  );
};

export default NewsAlertDialogActions;

const styles: Record<any, CSSProperties> = {
  container: {
    marginTop: 'auto',
    marginBottom: BOTTOM_OFFSET,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'no-wrap row',
  },
  actionContainer: {
    marginLeft: appScale(16.5),
    marginRight: appScale(16.5),
    pointerEvents: 'auto',
    position: 'relative',
  },
  buySellTutorialContent: {
    position: 'absolute',
    width: appScale(33.5),
    right: appScale(24),
    top: appScale(6),
  },
  buySellTutorialTriangle: {
    position: 'absolute',
    transform: `rotate(90deg)`,
    right: appScale(-6),
    top: appScale(0.75),
  },

  continueToPlayTutorialContent: {
    position: 'absolute',
    width: appScale(60),
    left: appScale(24),
    top: appScale(6),
  },
  continueToPlayTutorialTriangle: {
    position: 'absolute',
    transform: `rotate(-90deg)`,
    left: appScale(-6),
    top: appScale(7.25),
  },
};
