import React, {CSSProperties} from 'react';
import COLORS from '../../config/colors';
import Color from 'color';
import TUTORIAL_CONFIG from './TUTORIAL_CONFIG';
import appScale from '../../util/appScale';

export interface TutorialContentProps {
  style?: CSSProperties;
  isAction?: boolean;
}

const TutorialContent: React.FC<TutorialContentProps> = ({children, style, isAction = false}) => (
  <div style={{...styles.container, ...(isAction && styles.action), ...style}}>{children}</div>
);

export default TutorialContent;

const styles = {
  container: {
    padding: appScale(0.5),
    borderRadius: appScale(3.2),
    border: `${appScale(0.5)}px solid ${Color(COLORS.WHITE.default).alpha(0.5).toString()}`,
    backgroundColor: COLORS.BLACK_PEARL.default,
  },
  action: {
    borderColor: TUTORIAL_CONFIG.ACTION_CONTAINER_BORDER_COLOR,
  },
};
