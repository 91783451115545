const STORAGE_KEYS = {
  IS_NO_ADS_PURCHASED: 'IS_NO_ADS_PURCHASED',
  IS_FULL_SCREEN_ADD_SHOWN: 'IS_FULL_SCREEN_ADD_SHOWN',

  // RATING
  LAST_ASK_TO_RATE_US_DATE: 'LAST_ASK_TO_RATE_US_DATE',
  IS_APP_RATED: 'IS_APP_RATED',
  IS_NOT_NOW_PRESSED_ALREADY: 'IS_NOT_NOW_PRESSED_ALREADY',

  PRESSED_SMA_BUTTONS: 'PRESSED_SMA_BUTTONS',
};

export default STORAGE_KEYS;
