import React, {CSSProperties} from 'react';
import DialogBackground from './DialogBackground';

export interface DialogProps {
  onBackgroundClick?: () => void;
  isBackgroundVisible?: boolean;
  style?: CSSProperties;
}

const DialogWrapper: React.FC<DialogProps> = ({children, isBackgroundVisible = true, onBackgroundClick, style}) => {
  return (
    <div style={{...styles.wrapper, ...styles.container, ...style}}>
      {isBackgroundVisible && <DialogBackground onClick={onBackgroundClick} />}
      <div style={styles.container}>{children}</div>
    </div>
  );
};

export default DialogWrapper;

const styles = {
  wrapper: {
    position: 'absolute',
    zIndex: 1300,
  } as CSSProperties,
  container: {
    position: 'relative',
    zIndex: 1331,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'nowrap column',
  } as CSSProperties,
};
